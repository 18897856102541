<template>
  <div @click="focus()">
    <v-combobox
      ref="combobox"
      :value="chips"
      multiple
      solo
      :hide-details="!shouldShowErrorPopup"
      :disabled="disabled"
      class="chips__combobox"
      :class="{
        'no-chips': !(chips && chips.length),
        'small-input': smallInput,
        'small-height': height === 'small',
        'negative-border': error,
        'disabled-input': disabled,
        'has-input-value': hasInputValue,
      }"
      :rules="rules"
      @blur="blur"
      @focus="inputBoxOnFocus"
      @update:error="onError"
      @input="handleInputChange"
    >
      <template #selection="{ attrs, item, select, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          close-icon="$icon_close"
          class="chips__input"
          color="$primary"
          :class="{ 'chips__input--disabled': disabled }"
          :disabled="disabled"
          @click="select"
          @click:close="remove(item)"
        >
          <tooltipped-text :text="item" />
        </v-chip>
      </template>

      <template #label>
        <div class="chips__input--label">
          {{ placeholder }}
        </div>
      </template>
      <template #append>
        <div
          v-if="shouldShowErrorPopup && error"
          class="error-icon"
        >
          <v-icon
            color="negative"
            size="24"
          >
            $icon_attention
          </v-icon>
        </div>
      </template>
      <template #message>
        <div class="error-container">
          <div class="d-flex align-center secondary--text">
            <v-icon
              color="negative"
              size="24"
              @click="onPopupCloseClick"
            >
              $icon_close
            </v-icon>
            <p class="ma-0">
              {{ errorMessage }}
            </p>
          </div>
        </div>
        <div class="pointer-arrow" />
      </template>
    </v-combobox>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue';
import TooltippedText from '../tooltip/TooltippedText/TooltippedText.vue';

type ValueType = string;

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    disabled?: boolean;
    value?: Array<ValueType>;
    smallInput?: boolean;
    height?: 'small' | 'large';
    rules?: Array<string | boolean | ((value: object) => boolean | string)>;
    errorMessage?: string;
  }>(),
  {
    placeholder: '',
    height: 'large',
    rules: () => [],
    errorMessage: '',
  }
);

const emit = defineEmits<{
  (e: 'input', value?: Array<ValueType>): void;
  (e: 'input-change', value: string): void;
  (e: 'input-focus'): void;
  (e: 'removed', value: string): void;
  (e: 'blur'): void;
  (e: 'focus'): void;
}>();

const combobox = ref();
const error = ref(false);
const shouldShowErrorPopup = ref(false);

const chips = computed({
  get: () => props.value,
  set: (newValue) => emit('input', newValue),
});

function onError(value: boolean) {
  error.value = value;
  shouldShowErrorPopup.value = true;
}

function onPopupCloseClick() {
  shouldShowErrorPopup.value = false;
  error.value = false;
}

onMounted(async () => {
  await nextTick(() => {
    if (!combobox.value) {
      return;
    }
    const input = combobox.value.$refs.input;
    const appendedText = combobox.value.$refs['append-inner'];
    input.parentNode.appendChild(appendedText);

    input.addEventListener('input', change);
  });
});

onUnmounted(() => {
  if (!combobox.value) {
    return;
  }
  const input = combobox.value.$refs.input;
  input.removeEventListener('input', change);
});

const hasInputValue = ref<boolean>(false);

function change(e: InputEvent) {
  emit('input-change', (e.target as HTMLInputElement).value);

  hasInputValue.value = true;
}

function focus() {
  if (!props.disabled) {
    emit('input-focus');
  }
}

function blur() {
  emit('blur');
}

const handleInputChange = (value: string[]) => {
  chips.value = value;

  hasInputValue.value = false;
};

function remove(item: string) {
  chips.value = chips.value && chips.value.filter((c) => c !== item);
  emit('removed', item);
}

function inputBoxOnFocus() {
  emit('focus');
}

defineExpose({ focus });
</script>

<style lang="scss">
@import '../../assets/styles/main';

.chips__combobox {
  position: relative;

  .v-select__selections {
    margin-bottom: -4px;
  }

  .v-input__control {
    min-height: 104px !important;
    max-height: 104px !important;

    &:hover {
      box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
    }
  }

  &.small-height .v-input__control {
    min-height: 36px !important;
    max-height: 36px !important;
  }

  .chips__input {
    border-radius: 40px !important;
    height: 24px !important;
    color: white !important;
    background-color: $primary !important;

    &:not(:last-child) {
      margin: unset;
      margin-right: 4px;
      margin-bottom: 4px;
    }

    .v-chip__content {
      @include body-2;
    }
  }

  .v-chip__close {
    height: 20px !important;
    width: 20px !important;
    max-height: 20px !important;
    max-width: 20px !important;

    .v-icon__component {
      height: 20px !important;
      width: 20px !important;
    }
  }

  &.small-height .v-input__slot {
    align-items: unset;
  }

  .v-input__slot {
    border: 1px solid $elements;
    color: $elements !important;
    box-shadow: none !important;
    background: $backgrounds !important;
    align-items: start;
    display: grid;
    overflow: auto;
    overflow: overlay;
    padding: 4px 8px 4px 12px !important;
    height: 100%;

    &::-webkit-scrollbar {
      width: 12px;
    }

    & {
      scrollbar-width: thin;
      scrollbar-color: $secondaryMedium $backgrounds;
    }

    &::-webkit-scrollbar-track {
      background: $backgrounds;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $secondaryMedium;
      border-radius: 8px;
      border: 4px solid $backgrounds;
      position: absolute;
      right: 10px;
    }

    .v-input__icon--append {
      display: none;
    }

    .v-input__append-inner {
      color: $secondaryMedium;
      font-size: 14px;
      margin-left: unset;
      line-height: 24px;
      margin-top: -4px;
    }

    .v-select__slot {
      flex-direction: column;
      align-items: unset;

      input {
        margin-top: -2px !important;
        margin-right: -4px !important;
        flex: unset;
        width: 3px !important;
        min-width: 3px !important;
        font-size: 14px !important;
        height: 24px;
        max-height: unset !important;
      }
    }
  }

  &.no-chips {
    .v-select__slot {
      input {
        margin-right: -8px !important;
      }
    }
  }

  &.has-input-value {
    .v-select__slot {
      input {
        min-width: 55px !important;
        flex: 1 1;
        color: $primary;
      }
    }
  }

  &.no-placeholder {
    .v-input__slot {
      border: 1px solid $accent;
    }

    .v-select__slot {
      input {
        width: 55px !important;
        min-width: 55px !important;
        flex: 1 1;
        color: $primary;
      }
    }
  }

  &.v-input--is-disabled {
    .v-input__slot {
      background: $elements !important;
    }
  }

  .chips__input--disabled {
    background-color: $secondary !important;
  }

  .chips__input--label {
    @include body-2;
  }

  .error-icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  &.negative-border {
    .v-input__slot {
      border: 1px solid $negative;
    }
  }

  &.disabled-input {
    .v-input__slot {
      border: 1px solid $elements;
      cursor: not-allowed !important;
    }

    .v-input__control:hover {
      box-shadow: none !important;
    }

    .v-select__slot {
      input {
        cursor: not-allowed;
      }
    }
  }

  .v-text-field__details {
    position: absolute !important;
    top: -60px !important;
    padding: 0 !important;
    z-index: 2;

    .error-container {
      padding: 8px;
      background: $primaryWhite;
      border-radius: 4px;
      border: 1px solid $elements;
    }

    .pointer-arrow {
      position: relative;
      width: 10px;
      height: 10px;
      left: 42%;
    }

    .pointer-arrow:before {
      border-left: 1px solid $elements;
      border-bottom: 1px solid $elements;
      background: $primaryWhite;
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      top: -50%;
      rotate: -45deg;
    }
  }
}
</style>
