<template>
  <div>
    <v-menu
      v-model="isMenuActive"
      content-class="dropdown-menu"
      :close-on-content-click="false"
      offset-y
      :disabled="disabled"
      attach=".dropdown-menu-placeholder"
    >
      <template #activator="{ on, attrs }">
        <button-common
          v-bind="attrs"
          :disabled="disabled"
          append-icon="$icon_arrow_down"
          icon-color="secondaryMedium"
          :icon-size="24"
          color="dropdown"
          type="depressed"
          block
          :class="{
            'dropdown-menu__active': isMenuActive,
          }"
          v-on="on"
        >
          {{ valueLabel }}
        </button-common>
      </template>
      <div @keydown.enter="isMenuActive = false">
        <range-slider
          v-model="range"
          :disabled="disabled"
          :step="step"
          :min="min"
          :max="max"
          :has-text-box="hasTextBox"
          :thumb-label="thumbLabel"
          :translations="translations"
        />
      </div>
    </v-menu>
    <div class="dropdown-menu-placeholder" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import RangeSlider from '../../../components/sliders/RangeSlider/RangeSlider.vue';
import ButtonCommon from '../../../components/buttons/ButtonCommon.vue';

const isMenuActive = ref(false);
const props = withDefaults(
  defineProps<{
    value: number[];
    disabled?: boolean;
    step?: number;
    min?: number;
    max?: number;
    hasTextBox?: boolean;
    thumbLabel?: boolean | string;
    translations: {
      from: string;
      to: string;
      name: string;
    };
    completeRangeText?: string;
  }>(),
  {
    disabled: false,
    step: 1,
    min: 0,
    max: 10,
  }
);
const range = computed({
  get: () => props.value,
  set: (newValue) => {
    emit('input', newValue);
  },
});

const valueLabel = computed(() => {
  const minValue = range.value[0];
  const maxValue = range.value[1];

  if (props.completeRangeText && minValue === props.min && maxValue === props.max) {
    return props.completeRangeText;
  }

  return minValue === maxValue
    ? `${props.translations.name} ${minValue}`
    : `${props.translations.name} ${props.translations.from} ${minValue} ${props.translations.to} ${maxValue}`;
});

const emit = defineEmits<{
  (e: 'input', value?: number[]): void;
}>();
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/main';
@import '../styles/DropdownMenu';
</style>
