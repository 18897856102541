<template>
  <v-list-item class="nav-items__list-item">
    <div
      :class="{
        'nav-items__item-content': true,
        'nav-items__item-content--minimized': minimized,
      }"
    >
      <v-list-item-title
        :class="{
          'nav-items__subtitle--blue': active,
          'nav-items__subtitle--minimized': minimized,
        }"
        class="text-subtitle-2 nav-items__subtitle"
        @click="emit('click')"
      >
        <tooltipped-text :text="name" />
      </v-list-item-title>
    </div>
  </v-list-item>
</template>

<script setup lang="ts">
import TooltippedText from '../../tooltip/TooltippedText/TooltippedText.vue';
import vuetify from '../../../service/useVuetify';
import { computed } from 'vue';

const props = defineProps<{
  name: string;
  active: boolean;
  minimized?: boolean;
  whiteLabel: string;
}>();

const emit = defineEmits<{
  (e: 'click'): void;
}>();

const activeColorText = computed(() => {
  const colors = {
    CAPTURE: vuetify.value.theme.themes.light.accentLight,
    WISENET: vuetify.value.theme.themes.light.accentLight,
    MOBOTIX: vuetify.value.theme.themes.light.secondary,
    EEN: vuetify.value.theme.themes.light.accentLight,
  };
  return colors[props.whiteLabel ?? 'EEN'];
});

const subTitleText = computed(() => {
  const colors = {
    CAPTURE: vuetify.value.theme.themes.light.primaryWhite,
    WISENET: vuetify.value.theme.themes.light.primaryWhite,
    MOBOTIX: vuetify.value.theme.themes.light.secondary,
    EEN: vuetify.value.theme.themes.light.primaryWhite,
  };
  return colors[props.whiteLabel ?? 'EEN'];
});
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/main.scss';
.nav-items {
  overflow: auto;
  overflow: overlay;
  @include flex-center;
  @include zero-margin-padding;
  scrollbar-width: thin;
  scrollbar-color: $secondaryMedium transparent;

  &__list-item {
    &:hover {
      background: unset !important;
    }
  }

  &__item-content {
    padding: 0px 12px 0 44px;
    width: 100%;
    &--minimized {
      padding: 0;
    }
  }

  &__subtitle {
    color: v-bind(subTitleText) !important;
    display: flex;
    padding: 4px 0px 4px 4px;
    border-radius: 4px;
    cursor: pointer;

    align-self: center;
    flex: 1;

    &:hover {
      color: v-bind(activeColorText) !important;
    }

    &--blue {
      color: $accent !important;
    }

    &--minimized {
      padding-left: 36px;
    }
  }
}
</style>
