<template>
  <v-menu
    v-model="appSwitcherMenu"
    offset-y
    :disabled="disabled"
    content-class="app_switcher"
    :nudge-left="nudgeLeft"
  >
    <template #activator="{ on, attrs }">
      <button-common
        v-bind="attrs"
        icon
        type="clear"
        :class="['app-switcher__button', { 'app-switcher__button--active': appSwitcherMenu }]"
        v-on="on"
      >
        <v-avatar>
          <v-icon size="24">
            {{ icon }}
          </v-icon>
        </v-avatar>
      </button-common>
    </template>

    <v-list dense>
      <div class="app-switcher__list-container">
        <div class="app-switcher__list">
          <v-list-item
            v-for="(listItem, i) in menuItems"
            :key="`${listItem.name}-${i}`"
            class="app-switcher__list-item"
            :ripple="false"
            @click="handleClick(listItem)"
          >
            <div class="app-switcher__list-item__icon-container">
              <!-- to accomodate the icon from VMS as api currently does not give a valid icon url. This can be removed once api gives valid icon url-->
              <template>
                <v-img
                  :src="listItem.iconUrl"
                  contain
                  alt="icon"
                  class="app-switcher__list-item__icon"
                  width="24"
                  height="24"
                >
                  <template #placeholder>
                    <v-icon
                      size="24"
                      class="app-switcher__list-item__icon"
                    >
                      {{ listItem.icon }}
                    </v-icon>
                  </template>
                </v-img>
              </template>
            </div>
            <div>
              <v-list-item-title class="app-switcher__title">
                {{ listItem.category }}
              </v-list-item-title>
              <v-list-item-title class="app-switcher__sub-title">
                {{ listItem.name }}
              </v-list-item-title>
            </div>
          </v-list-item>
        </div>
        <div v-intersect="handleIntersection">
          <loading-spinner
            v-if="!paginationRef.loaded && !paginationRef.completed"
            :size="20"
            is-loading
            :absolute="false"
            class="infinite-loader"
          />
        </div>
      </div>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { IntersectionPaginationOption, IntersectionStateChanger } from '@/utils/types';
import LoadingSpinner from '../loaders/LoadingSpinner.vue';
import { ApiApplicationLink } from '@/service/api-types';
import ButtonCommon from '../buttons/ButtonCommon.vue';
import { MenuItems } from './types';

withDefaults(
  defineProps<{
    icon?: string;
    menuItems: MenuItems[];
    disabled?: boolean;
    nudgeLeft?: number;
  }>(),
  {
    nudgeLeft: 160,
  }
);

const emit = defineEmits<{
  (e: 'click', value: ApiApplicationLink): void;
  (e: 'infinite', state: IntersectionStateChanger): void;
}>();

const appSwitcherMenu = ref(false);
const paginationRef = ref<IntersectionPaginationOption>({
  loaded: false,
  completed: false,
});

const state: IntersectionStateChanger = {
  loaded: () => {
    paginationRef.value.loaded = true;
  },
  completed: () => {
    paginationRef.value.completed = true;
  },
  reset: () => {
    paginationRef.value.loaded = false;
    paginationRef.value.completed = false;
  },
};

function handleClick(item: ApiApplicationLink) {
  emit('click', item);
}

function handleIntersection(
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver,
  isIntersecting: boolean
) {
  if (isIntersecting) {
    if (paginationRef.value.completed) return;
    paginationRef.value.loaded = false;
    emit('infinite', state);
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main';

.app-switcher {
  &__list-container {
    max-height: 198px !important;
    overflow-y: auto;
    padding-inline: 4px !important;
  }

  &__list-item {
    padding: 8px !important;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    &__icon-container {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $elements !important;
      padding: 4px;
    }

    &:hover {
      background: $accentClear !important;

      .app-switcher__list-item__icon-container {
        background-color: transparent !important;
      }
    }
  }

  &__list-item:last-child {
    margin-bottom: 0px;
  }

  &__title {
    @include subtitle-2-important;
    color: $primary !important;

    &--highlighted {
      color: $accent !important;
    }
  }

  &__sub-title {
    @include subtitle-3;
    color: $secondaryMedium !important;
  }

  &__button {
    height: 36px !important;
    width: 36px !important;
    margin-right: 24px !important;
    border-radius: 50%;
    background-color: transparent;

    &::before {
      display: none; // remove the hover effect on profile icon button
    }

    &--active {
      background-color: $accentClear !important;
    }

    &:hover {
      background: $accentClear !important;
    }
  }
}

:deep(.v-image__placeholder) {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.infinite-loader {
  text-align: center;
  padding-bottom: 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.app-switcher__list-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.app-switcher__list-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
