<template>
  <v-treeview
    :value="value"
    :items="items"
    :search="search"
    :load-children="fetchChildren"
    :active="activeItems"
    :open="openItems"
    class="tree-view"
    :activatable="!isSelectionShown"
    item-key="id"
    return-object
    color="accentLight"
    transition
    :selectable="isSelectionShown"
    :dense="isSelectionShown"
    expand-icon="$icon_arrow_down"
    loading-icon="$icon_loading"
    indeterminate-icon="$icon_checkBoxindeterminate"
    on-icon="$icon_checkboxOn"
    off-icon="$icon_checkboxOff"
    @update:open="updateOpenItems"
    @update:active="updateActiveItems"
    @input="updateSelectionItems"
  >
    <template #label="{ item, active }">
      <div>
        <span v-if="!isSelectionShown">
          <v-icon
            size="20"
            :color="
              active ? 'accent' : item.isVirtualFolder ? 'secondaryLight' : item.disabled ? 'secondaryMedium' : ''
            "
          >
            {{ '$icon_folder' }}
          </v-icon>
        </span>
        <tooltip :text="item.disabled && getDisabledText ? getDisabledText(item.name) : item.name">
          <template #content="{ on, attrs }">
            <span
              :data-testid="'tree-view-' + item.name"
              v-bind="attrs"
              :class="[
                'tree-view__title',
                {
                  'tree-view__virtual-title': item.isVirtualFolder,
                  'tree-view__active-title': active,
                },
              ]"
              v-on="on"
            >
              {{ item.name }}
            </span>
          </template>
        </tooltip>
      </div>
    </template>
  </v-treeview>
</template>

<script setup lang="ts">
import tooltip from '../tooltip/Tooltip.vue';

withDefaults(
  defineProps<{
    items?: object[];
    getDisabledText?: CallableFunction;
    fetchChildren?: CallableFunction;
    search?: string;
    openItems?: object[];
    activeItems?: object[];
    isSelectionShown?: boolean;
    value: object[];
  }>(),
  {
    items: () => [],
    getDisabledText: () => {
      return;
    },
    fetchChildren: () => {
      return;
    },
    search: '',
    openItems: () => [],
    activeItems: () => [],
    isSelectionShown: false,
    value: () => [],
  }
);

const emit = defineEmits<{
  (e: 'updateActiveItems', items: object[]): void;
  (e: 'updateOpenItems', items: object[]): void;
  (e: 'input', items: object[]): void;
}>();

function updateActiveItems(active: object[]) {
  emit('updateActiveItems', active);
}

function updateOpenItems(openItems: object[]) {
  emit('updateOpenItems', openItems);
}

const updateSelectionItems = (selectionItems: object[]) => {
  emit('input', selectionItems);
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main';
.tree-view {
  &__title {
    @include body-2;
  }
  &__virtual-title {
    color: $secondaryLight !important;
  }
  &__active-title {
    color: $accent !important;
  }
}
</style>

<style lang="scss">
@import '../../assets/styles/main';

.tree-view .v-treeview-node__root::before {
  border-radius: 4px;
}

.tree-view .v-treeview-node__toggle,
.tree-view .v-treeview-node__toggle svg {
  color: $primary;
  width: 20px;
  height: 20px;
}

.v-treeview-node .v-treeview-node__toggle::after {
  background-color: transparent; // this removes greyish background on clicking the chevron arrow in this component
}

.v-treeview-node--active .v-treeview-node__toggle svg {
  color: $accent;
}

.v-treeview-node--disabled .v-treeview-node__toggle svg {
  color: $secondaryMedium;
}

.tree-view .v-treeview-node__root.v-treeview-node--active {
  pointer-events: none;
}

.tree-view .v-treeview-node__toggle {
  pointer-events: all;
}

.theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active::before {
  opacity: 1 !important;
}

.tree-view .v-treeview-node__content .v-treeview-node__label .tree-view__active-title {
  color: $primary !important;
  position: relative;
  z-index: 10;
}

.v-treeview-node__checkbox {
  width: 16px;
  height: 16px;
}
</style>
