<template>
  <v-textarea
    v-model="selectedItem"
    :disabled="disabled"
    :rules="[...rules]"
    :class="[{ 'eewc-text-area__disabled': disabled }, { 'eewc-text-area--error': error }]"
    class="text-area"
    solo
    dense
    :label="label"
    outlined
    flat
    single-line
    :no-resize="noResize"
    :loading="loading"
    :rows="rows"
    :auto-grow="autoGrow"
    :error-messages="errorMessages"
    @update:error="onError"
    @focus="$emit('focus')"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

type Value = string | number | boolean | object;

type Props = {
  disabled?: boolean;
  value?: Value;
  label?: string;
  rules?: [];
  noResize?: boolean;
  loading?: boolean;
  errorMessages?: string | string[];
  rows?: number;
  autoGrow?: boolean;
  maxHeight?: string;
};

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  value: '',
  rules: () => [],
  noResize: true,
  label: undefined,
  rows: undefined,
  autoGrow: false,
  maxHeight: 'auto',
});

const emit = defineEmits<{
  (e: 'input', value: Value): void;
}>();

const error = ref(false);

const onError = (value: boolean) => {
  error.value = value;
};

const selectedItem = computed({
  get: () => props.value,
  set: (newValue) => {
    emit('input', newValue);
  },
});
</script>

<style lang="scss">
@import '../../assets/styles/main';

.text-area .v-input__append-inner {
  margin-top: unset !important;
  align-self: center;
}

.text-area {
  label {
    animation: none !important;
  }

  position: relative;

  .v-text-field__details {
    position: absolute;
    bottom: -24px;
    left: -12px;

    .v-messages__message {
      @include body-2;
    }
  }

  .v-input__prepend-inner {
    z-index: 1;
    margin-top: 6px !important;
    color: $elements !important;
  }

  .v-icon {
    .v-icon__component,
    .v-icon__svg {
      height: 24px;
      width: 24px;
      color: $secondaryMedium !important;
    }
  }

  &.error--text {
    fieldset {
      border: 1px solid $negative !important;
    }
  }

  .v-input__slot {
    min-height: 36px !important;

    & fieldset {
      color: $elements !important;
      border: 1px solid;
      background: $backgrounds;
    }

    .v-text-field__slot {
      textarea {
        @include body-2;
      }
    }

    & label {
      top: 8px !important;
      color: $secondaryMedium;
      @include body-2;
    }

    &:hover {
      box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
      border-radius: 4px;

      & fieldset {
        color: $elements !important;
        border: 1px solid;
      }
    }

    &:focus-within {
      & fieldset {
        color: $accentFixed !important;
      }
    }

    textarea {
      color: $primary;
      overflow-y: auto !important;
      max-height: v-bind(maxHeight);

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: $secondaryMedium;
        border-radius: 8px;
        background-clip: padding-box;
        border: 0 solid transparent;
      }
    }

    z-index: 2;
  }
}

.text-area--disabled .v-input__slot {
  & fieldset {
    background: $elements;
  }
}

.eewc-text-area--error .v-input__slot {
  & fieldset {
    color: $negative;
  }
}
</style>
