<template>
  <v-menu
    v-model="menu"
    offset-y
    content-class="profile-menu"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        :ripple="false"
        :class="['profile-menu__button', { 'profile-menu__button--active': menu }]"
        v-on="on"
        @dblclick.shift.exact="handleShiftDoubleClick"
        @dblclick.ctrl.exact="handleCtrlDoubleClick"
        @dblclick.meta.exact="handleMetaDoubleClick"
      >
        <v-avatar>
          <span>{{ initials }}</span>
        </v-avatar>
      </v-btn>
    </template>

    <v-list dense>
      <!--User full name and email-->

      <v-list-item class="profile-menu__list-item">
        <v-list
          class="mt-0"
          dense
        >
          <div class="profile-menu__profile-name">
            <tooltipped-text :text="fullName" />
          </div>
          <div class="profile-menu__profile-mail">
            <tooltipped-text :text="userEmail" />
          </div>
        </v-list>
      </v-list-item>

      <!--Profile menu options-->

      <template v-for="(item, index) in menuItems">
        <profile-menu-option
          v-if="item.type === 'menu'"
          :key="index"
          :items="item.items"
          :section="item.section"
          :hovered-item="hoveredItem"
          @hover="hoveredItem = $event"
          @click="handleClick"
        />
        <v-divider
          v-else
          :key="'divider-' + index"
          class="profile-menu__divider"
        />
      </template>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import ProfileMenuOption from './ProfileMenuOptions.vue';
import TooltippedText from '../tooltip/TooltippedText/TooltippedText.vue';

type UserItem = {
  click: () => void;
  icon: string;
  name: string;
  active?: boolean;
  isLoading?: boolean;
};

type MenuItem = {
  type: 'menu';
  section: 'user' | 'account';
  items: UserItem[];
};

type DividerItem = {
  type: 'divider';
};

type MenuItems = (MenuItem | DividerItem)[];

defineProps<{
  userEmail?: string;
  fullName?: string;
  initials?: string;
  menuItems: MenuItems;
}>();

const emit = defineEmits<{
  (e: 'click', value: UserItem): void;
  (e: 'onshiftdblclick'): void;
  (e: 'onctrldblclick'): void;
  (e: 'onusermetadblclick'): void;
}>();

const menu = ref(false);
const hoveredItem = ref<{ section?: string; index?: number }>({});

function handleClick(item: UserItem) {
  emit('click', item);
}

function handleCtrlDoubleClick() {
  emit('onctrldblclick');
}

function handleShiftDoubleClick() {
  emit('onshiftdblclick');
}

function handleMetaDoubleClick() {
  emit('onusermetadblclick');
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main';
.v-list {
  padding-inline: 4px !important;
}

.profile-menu {
  &__button {
    height: 40px !important;
    width: 40px !important;
    margin-right: 0px !important;
    border-radius: 50%;
    background-color: $elements;

    &::before {
      display: none; // remove the hover effect on profile icon button
    }

    &--active {
      background-color: $accentClear !important;
    }
  }

  &__profile-name {
    @include subtitle-1-important;
    color: $primary !important;
  }

  &__profile-mail {
    // have used the style for subtitle-2-important to override the default style
    @include subtitle-2-important;
    color: $secondary !important;
  }

  &__list-item {
    &--options {
      padding-left: 8px !important;
    }

    &:hover {
      background: unset !important;
    }
  }

  &__divider {
    margin-block: 7px;
    margin-inline: 8px !important;
  }
}
</style>
