<template>
  <div
    class="radio-group"
    :class="{
      'radio-group--disabled': disabled,
      'radio-group--enabled': !disabled,
      'radio-group--horizontal': horizontal,
    }"
  >
    <div
      v-for="(option, index) in options"
      :key="option.text"
    >
      <div class="radio-group__gradient">
        <label
          class="radio-group__input"
          :data-testid="'radio-group-option-' + option.value"
        >
          <input
            :id="'button' + index"
            :name="name"
            type="radio"
            :value="option.value"
            :checked="option.value === value"
            :disabled="disabled"
            :required="required"
            :data-testid="'radiobutton-' + index"
            @change="updateValue(option.value)"
          >
          <span
            class="radio-group__control"
            :style="cssVars"
          />
        </label>
        <span
          v-if="option.text"
          :for="index"
          class="radio-group__label body-2"
        >{{ option.text }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

interface option {
  text: string;
  value: string | number;
}
interface Props {
  value: any;
  options: option[];
  required?: boolean;
  disabled?: boolean;
  horizontal?: boolean;
  name?: string;
  size?: number;
}

const emit = defineEmits<{ (e: 'input', value: boolean): void }>();

const props = withDefaults(defineProps<Props>(), {
  required: false,
  disabled: false,
  horizontal: false,
  name: 'radioGroup',
  size: 16,
});

const cssVars = computed(() => {
  return {
    ...{ '--radio-button--size': props.size + 'px' },
  };
});

function updateValue(value: any) {
  emit('input', value);
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main';
$border: 2px;

.radio-group--horizontal {
  display: flex;
}

.radio-group--enabled {
  color: $primary;
}

.radio-group--disabled {
  color: $secondary;
}

.radio-group {
  .radio-group__input {
    display: flex;
    padding-bottom: 2px;

    input {
      opacity: 0;
      width: 0px;
      height: 0px;

      &:checked:active + .radio-group__control {
        background: radial-gradient($accentDark 50%, rgba(255, 0, 0, 0) 52%);
        border: $border solid $accentDark;
      }
      //below class ensures the active state while dragging the pointer while pressed down
      &:checked:active:hover + .radio-group__control {
        background: radial-gradient($accentDark 50%, rgba(255, 0, 0, 0) 52%);
        border: $border solid $accentDark;
      }
      &:checked + .radio-group__control {
        background: radial-gradient($accent 50%, rgba(255, 0, 0, 0) 52%);
        border: $border solid $accent;
      }
      &:disabled + .radio-group__control {
        background: rgba(255, 0, 0, 0);
        border: $border solid $secondaryLight;
        box-shadow: none;
      }
      &:disabled:hover + .radio-group__control {
        background: rgba(255, 0, 0, 0);
        border: $border solid $secondaryLight;
        box-shadow: none;
      }
      &:disabled:checked + .radio-group__control {
        background: radial-gradient($secondaryLight 50%, rgba(255, 0, 0, 0) 52%);
        border: $border solid $secondaryLight;
        box-shadow: none;
      }
      &:disabled:checked:hover + .radio-group__control {
        background: radial-gradient($secondaryLight 50%, rgba(255, 0, 0, 0) 52%);
        border: $border solid $secondaryLight;
        box-shadow: none;
      }
      &:hover + .radio-group__control {
        background: radial-gradient(white 90%, rgba(255, 0, 0, 0) 92%);
        box-shadow: 1.5px 1.5px 0 0 $secondaryLight;
      }
      &:hover:checked + .radio-group__control {
        background: radial-gradient($accent 50%, white 52%);
        border: $border solid $accent;
        box-shadow: 1.5px 1.5px 0 0 $secondaryLight;
      }
      &:active + .radio-group__control {
        background: radial-gradient($elements 90%, rgba(255, 0, 0, 0) 92%);
        border: $border solid $secondary;
        box-shadow: none;
      }
      &:focus-visible + .radio-group__control {
        background: radial-gradient($accentDark 50%, rgba(255, 0, 0, 0) 52%);
        border: $border solid $accentDark;
        box-shadow: none;
      }
    }
  }

  .radio-group__gradient {
    display: flex;
    flex-direction: row;
    margin: 8px;
    align-items: center;

    .radio-group__label {
      padding: 2px 12px;
      color: $primary;
    }

    .radio-group__control {
      background: rgba(255, 0, 0, 0);
      display: block;
      width: var(--radio-button--size);
      height: var(--radio-button--size);
      border-radius: 50%;
      border: $border solid currentColor;
      transform: translateY(1px);
    }
  }
}
</style>
