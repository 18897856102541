<template>
  <v-expansion-panel
    ref="expansionPanelRef"
    class="expansion-panel"
    :disabled="isDisabled"
    @click="handleClick"
  >
    <v-expansion-panel-header
      v-if="!isPanelHeaderHidden"
      :class="[
        'expansion-panel-header',
        {
          small: headerHeight === HeaderHeight.small,
          big: headerHeight === HeaderHeight.big,
        },
      ]"
    >
      <template #actions>
        <v-icon
          v-if="!isLoading"
          color="primary"
          class="expansion-panel-header__chevron-icon"
          size="18"
        >
          $icon_arrow_down
        </v-icon>
      </template>
      <div
        class="expansion-panel-header__action"
        :style="{ order: actionOrder }"
      >
        <slot name="action" />
      </div>
      <span :class="['expansion-panel-header__title', { 'no-order': actionOrder === 0 }]">
        <slot
          v-if="!title"
          name="title"
        />
        {{ title }}
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content :eager="eager">
      <slot />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script setup lang="ts">
import { defineProps, withDefaults, defineEmits, ref, watch } from 'vue';
import { HeaderHeight } from './expansionPanels';

/**
 * Types
 */

type Props = {
  title?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  headerHeight?: string;
  isPanelHeaderHidden?: boolean;
  actionOrder?: number;
  eager?: boolean; // when true, the content will be rendered immediately
};

/**
 * Component
 */

const props = withDefaults(defineProps<Props>(), {
  isDisabled: false,
  isLoading: false,
  headerHeight: HeaderHeight.big,
  isPanelHeaderHidden: false,
  actionOrder: 3,
  title: undefined,
});

const expansionPanelRef = ref();

watch(
  () => props.isLoading,
  () => {
    // Make sure that expansion panel is not collapsed after loading
    expansionPanelRef.value.isActive = true;
  }
);

const emit = defineEmits<{
  (e: 'click', title: string): void;
  (e: 'info-click'): void;
}>();

const handleClick = () => {
  emit('click', props.title);
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main';

.expansion-panel {
  &-header {
    background: $elements;
    border-radius: 4px;
    padding: 10px 8px;
    display: flex;
    justify-content: flex-start;

    .no-order {
      order: unset;
    }

    &__title {
      @include subtitle-2;
      color: $primary;
      order: 2;
      margin-left: 4px;
      height: 100%;
    }

    &__action {
      flex-grow: 0;
    }

    .v-expansion-panel-header__icon {
      justify-self: flex-start;
      order: 1;
    }

    &.small {
      height: 32px;
      min-height: 32px !important;
      padding: 7px 8px;
    }

    &.big {
      height: 40px;
      min-height: 40px !important;
    }
  }

  :deep(.v-expansion-panel-content__wrap) {
    padding: 0;
  }

  :deep(.v-expansion-panel-header) {
    .v-icon.expansion-panel-header__chevron-icon {
      rotate: (-90deg);
    }
  }

  &:not(:first-child)::after {
    border: none;
  }
}

.v-expansion-panel--active
  > .v-expansion-panel-header--active
  .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate)
  .v-icon {
  rotate: (180deg) !important;
}
</style>
