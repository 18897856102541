<template>
  <v-carousel
    v-model="currentSlide"
    delimiter-icon="$icon_ellipse"
    hide-delimiter-background
    class="eewc-carousel"
    height="443px"
    :continuous="true"
    :interval="interval"
  >
    <template #prev="{ on, attrs }">
      <button-common
        v-if="currentSlide !== 0"
        type="clear"
        color="accent"
        v-bind="attrs"
        v-on="on"
      >
        {{ prevButton }}
      </button-common>
      <button-common
        v-else
        type="clear"
        color="accent"
        @click="emit('closeScreen')"
      >
        {{ skipButton }}
      </button-common>
    </template>
    <template #next="{ on, attrs }">
      <button-common
        v-if="currentSlide !== totalItems - 1"
        color="accent"
        v-bind="attrs"
        v-on="on"
      >
        {{ nextButton }}
      </button-common>
      <button-common
        v-else
        color="accent"
        @click="emit('closeScreen')"
      >
        {{ doneButton }}
      </button-common>
    </template>
    <slot name="carouselItems" />
  </v-carousel>
</template>

<script setup lang="ts">
import { defineProps, ref, defineEmits } from 'vue';
import ButtonCommon from '../buttons/ButtonCommon.vue';

const currentSlide = ref(0);
const emit = defineEmits(['closeScreen']);
type Props = {
  totalItems: number;
  nextButton: string;
  prevButton: string;
  doneButton: string;
  skipButton: string;
  interval?: number;
};
withDefaults(defineProps<Props>(), {
  interval: 7000,
});
</script>

<style lang="scss">
@import '../../assets/styles/main';
.eewc-carousel {
  .v-window__prev,
  .v-window__next {
    background: none;
    top: calc(100% - 52px);
    z-index: 2;
  }
  .v-carousel__controls {
    height: 67px;
    div button {
      height: 12px !important;
      width: 12px !important;
    }
    .theme--dark.v-btn--active::before {
      opacity: 1 !important;
      z-index: 2 !important;
      color: $accent !important;
    }
    span span {
      font-size: 12px !important;
      height: 12px !important;
      width: 12px !important;
    }
  }
}
</style>
