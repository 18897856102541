<template>
  <div :class="['eewc-switch-button', size && 'eewc-switch-button--' + size, dark && 'eewc-switch-button--dark']">
    <label class="eewc-switch-button__label">
      <input
        v-model="checked"
        :value="value"
        type="checkbox"
        class="eewc-switch-button__input"
        :disabled="disabled"
        :switchText="switchText"
      >
      <div
        v-if="isLoading"
        :class="[
          'eewc-switch-button__loading-spinner',
          !value && 'eewc-switch-button__loading-spinner--active',
          !value && size && 'eewc-switch-button__loading-spinner--active-' + size,
          value && size && 'eewc-switch-button__loading-spinner--inactive-' + size,
        ]"
      >
        <loading-spinner
          color="primary"
          :is-loading="true"
          :size="size === 'large' ? 16 : 13"
          :absolute="false"
        />
      </div>
      <div
        :class="[
          'eewc-switch-button__track',
          isLoading && 'eewc-switch-button__track-loading',
          isLoading && !value && 'eewc-switch-button__track-loading--inactive',
        ]"
      >
        <span
          :class="[
            'eewc-switch-button__thumb',
            isLoading && 'eewc-switch-button__thumb-loading',
            isLoading && !value && 'eewc-switch-button__thumb-loading--inactive',
            size && isLoading && !value && 'eewc-switch-button__thumb-loading--' + size,
          ]"
        />
      </div>

      <p class="eewc-switch-button__text">
        {{ switchText }}
      </p>
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import LoadingSpinner from '../loaders/LoadingSpinner.vue';

const emit = defineEmits<{ (e: 'input', value: boolean): void }>();

const props = defineProps<{
  value: boolean;
  disabled?: boolean;
  switchText?: string;
  size?: string;
  isLoading?: boolean;
  dark?: boolean;
}>();

const checked = computed({
  get() {
    return props.value;
  },
  set(val: boolean) {
    emit('input', val);
  },
});
</script>

<style lang="scss">
@import '../../assets/styles/main.scss';

.eewc-switch-button {
  $self: &;
  position: relative;

  &__input {
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    opacity: 0;
  }

  &__label {
    display: inline-block;
  }

  &__track {
    @include width-height(32px, 20px);
    display: flex;
    z-index: 1;
    top: 5%;
    background-color: $secondaryLight;
    opacity: 1;
    border-radius: 60px;
    transition: box-shadow 0.2s, background 0.2s, width 0.2s, height 0.2s;
    cursor: pointer;

    //track states
    &:hover {
      box-shadow: 1px 1px 1px rgba(33, 42, 52, 0.32);
    }
  }

  &__thumb {
    @include width-height(16px);
    background: white;
    border-radius: 50%;
    transform: translate(2px, 2px);
    transition: 0.2s;
    cursor: pointer;
  }

  &__text {
    position: absolute;
    left: 60px;
    top: 5%;
    @include subtitle-2;
  }

  &__loading-spinner {
    position: absolute;
    height: 20px;
    left: 3.25px;
    top: 0.5%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--active {
      left: 15.5px;

      &-large {
        top: 1px;
        left: 20px;
      }
    }

    &--inactive-large {
      top: 1px;
      left: 4px;
    }

    .v-progress-circular__underlay {
      stroke: $primaryWhite;
    }

    .eewc-loading-spinner__circle {
      z-index: 100;
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }
  }

  //enabled and checked
  &__input {
    &:active {
      + #{$self}__track {
        background-color: $secondaryMedium;
      }
    }

    &:checked {
      + #{$self}__track {
        background-position: top;
        background-color: $accent;

        &:hover {
          background-color: $accent;
        }

        #{$self}__thumb {
          transform: translate(14px, 2px);
          background-color: white;
        }
      }

      &:active {
        + #{$self}__track {
          background-color: $accentDark;
        }
      }
    }
    &:focus-visible {
      + #{$self}__track {
        box-shadow: none;
        outline-offset: 2px;
        outline: 1px solid black;
      }
    }

    //disable states

    //disabled and unchecked
    &:disabled {
      + #{$self}__track {
        box-shadow: none !important;
        background-color: $secondaryLight !important;
        cursor: default;
      }

      + #{$self}__track #{$self}__thumb {
        background-color: $elements !important;
        cursor: default;
      }
    }

    //disabled and checked
    &:disabled:checked {
      + #{$self}__track {
        background-color: $accent !important;
      }

      + #{$self}__track #{$self}__thumb {
        background-color: $secondaryLight !important;
      }
    }
  }

  // we need to check state for label
  &__label {
    &:active {
      #{$self}__input {
        + #{$self}__track {
          background-color: $secondaryMedium;
        }

        &:checked {
          + #{$self}__track {
            background-color: $accentDark;
          }
        }
      }
    }
  }

  //Large switcher
  &--large {
    #{$self}__track {
      @include width-height(40px, 24px);
    }
    #{$self}__thumb {
      @include width-height(20px);
    }

    #{$self}__input {
      &:checked {
        & + #{$self}__track #{$self}__thumb {
          transform: translate(18px, 2px);
        }
      }
    }
  }

  &__track-loading {
    cursor: default;
    &--inactive {
      background: $accent;
    }
  }

  &__thumb-loading {
    cursor: default;
    background: $elements;
    &--inactive {
      transform: translate(14px, 2px);
    }
    &--large {
      transform: translate(18px, 2px);
    }
  }
}

.eewc-switch-button--dark {
  .eewc-switch-button__track {
    background-color: $secondaryDark;
  }
}
</style>
