<template>
  <v-chip
    :class="chipClass"
    :disabled="isDisabled"
    label
    tabindex="1"
    @click="handleClick"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
  >
    <v-icon
      v-if="isChecked"
      :color="iconColor"
      left
      size="20"
    >
      $icon_check_zero
    </v-icon>
    <span class="chips-filter__text">
      {{ text }}
    </span>
    <v-icon
      v-if="isClosable"
      right
      :size="20"
      :color="iconColor"
      data-testid="close-icon"
      @click="handleClose"
    >
      $icon_close
    </v-icon>
  </v-chip>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, defineEmits, computed } from 'vue';

/**
 * Types
 */

type Props = {
  text: string;
  isDisabled?: boolean;
  isChecked?: boolean;
  isClosable?: boolean;
  variant?: 'dark' | 'light' | 'background';
};

/**
 * Component
 */

const props = withDefaults(defineProps<Props>(), {
  isChecked: false,
  isDisabled: false,
  isClosable: false,
  variant: 'light',
});

const emit = defineEmits<{
  (e: 'click'): void;
  (e: 'close'): void;
  (e: 'mouseover'): void;
  (e: 'mouseleave'): void;
}>();

const handleClick = () => {
  emit('click');
};

const handleMouseOver = () => {
  emit('mouseover');
};

const handleMouseLeave = () => {
  emit('mouseleave');
};

const handleClose = () => {
  emit('close');
};

const isLightVariant = computed(() => props.variant === 'light');
const isBackgroundVariant = computed(() => props.variant === 'background');

const iconColor = computed(() => {
  if (isLightVariant.value) {
    return 'secondary';
  }

  return 'primaryWhite';
});

const chipClass = computed(() => {
  const BASE_CLASS = 'chips-filter';

  if (isLightVariant.value) {
    return `${BASE_CLASS} chips-filter--light`;
  }

  if (isBackgroundVariant.value) {
    return `${BASE_CLASS} chips-filter--background`;
  }

  return `${BASE_CLASS} chips-filter--dark`;
});
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/main';

.chips-filter {
  @include body-2;
  border-radius: 40px !important;
  height: 20px !important;

  &:hover {
    box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32) !important;
  }

  &:active {
    background-color: $elements !important;
    border-color: transparent;
  }

  &:focus-visible {
    border: solid 1px $primary !important;
    box-shadow: inset 0 0 0 1px #eee;
  }

  &--light {
    background: $primaryWhite !important;
    border: 1px solid $elements !important;
    color: $secondary !important;
  }

  &--background {
    background: $backgrounds !important;
    border: 1px solid $elements !important;
    color: $secondary !important;
  }

  &--dark {
    background: $primary !important;
    border: 1px solid transparent !important;
    color: $primaryWhite !important;

    &:hover {
      border-color: transparent !important;
    }
  }

  &__text {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
