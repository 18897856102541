<template>
  <div
    v-click-outside="hideMultiSelectItems"
    class="multiselect-box"
  >
    <button
      class="multiselect-box__select"
      :disabled="disabled"
      :class="{
        'multiselect-box__select--disabled': disabled,
        'multiselect-box__select--active': selectActive && !showErrorMessage,
        'multiselect-box__select--error': showErrorMessage,
        'multiselect-box__select--none-label': isNoneOptionSelected && shouldChangeNoneLabelText,
      }"
      type="button"
      @click="selectActive = !selectActive"
    >
      <tooltipped-text :text="selectedItemsLabel" />
      <v-icon
        v-show="!showErrorMessage"
        :size="24"
        class="ml-auto"
        :color="disabled ? 'secondaryLight' : 'secondaryMedium'"
      >
        $icon_arrow_down
      </v-icon>
      <v-icon
        v-show="showErrorMessage"
        :size="24"
        color="negative"
        class="ml-auto"
      >
        $icon_attention
      </v-icon>
      <v-progress-linear
        v-if="shouldShowLoader"
        absolute
        bottom
        indeterminate
        rounded
        height="2"
        :color="loaderColor"
      />
    </button>
    <div
      v-show="selectActive"
      class="multiselect-box__content multiselect-box__items"
    >
      <div class="multiselect-box__item">
        <CheckBox
          v-if="labelStrings.allLabel"
          v-model="isSelectedAll"
          :label="labelStrings.allLabel"
          :indeterminate="isIndeterminate"
          class="multiselect-box__item__checkbox"
        />
      </div>
      <div
        v-for="item in items"
        :key="item.value"
        class="multiselect-box__item"
        @click="clickItem(item)"
      >
        <CheckBox
          :label="item.text"
          :value="selectedItems?.some((selectedItem) => selectedItem.value === item.value)"
          class="multiselect-box__item__checkbox"
          @change="clickItem(item)"
        />
      </div>
    </div>
    <div
      v-show="showErrorMessage"
      class="multiselect-box__error-message"
    >
      {{ errorMessage || errorBucket[0] }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import CheckBox from '../../selection-controls/CheckBox.vue';
import TooltippedText from '../../tooltip/TooltippedText/TooltippedText.vue';

import { SelectBoxItem, LabelStrings } from '../types';
import { clickItemHandler, selectedItemsLabelHandler } from '../multiSelectUtils';

const props = defineProps<{
  disabled?: boolean;
  value?: SelectBoxItem[];
  labelStrings: LabelStrings;
  items: SelectBoxItem[];
  errorMessage?: string;
  shouldChangeNoneLabelText?: boolean;
  shouldShowLoader?: boolean;
  rules?: Array<string | boolean | ((value: object) => boolean | string)>;
}>();

const emit = defineEmits<{
  (e: 'input', value?: SelectBoxItem[]): void;
  (e: 'click-outside'): void;
}>();

const selectActive = ref<boolean>(false);
const isDirty = ref<boolean>(false);
const valid = ref<boolean>(false);
const errorBucket = ref<string[]>([]);
const inRange = (num: number, a: number, b = 0) => Math.min(a, b) <= num && num < Math.max(a, b);

const isIndeterminate = computed(() => props?.value && inRange(props?.value?.length, 1, props.items.length));
const showErrorMessage = computed(() => {
  if (props.rules?.length) {
    return isDirty.value && !valid.value;
  } else {
    return !!props.errorMessage;
  }
});

const isSelectedAll = computed({
  get: () => props?.value?.length === props.items.length,
  set: (newValue) => {
    selectedItems.value = newValue ? props.items : [];
  },
});

const selectedItems = computed({
  get: () => (props.value ? props.value : []),
  set: (newValue) => {
    emit('input', newValue);
    validate(newValue);
  },
});

const selectedItemsLabel = computed(() =>
  selectedItemsLabelHandler(isSelectedAll.value, selectedItems.value, props.labelStrings)
);

const isNoneOptionSelected = computed(() => selectedItemsLabel.value === props.labelStrings.noneLabel);

const loaderColor = computed(() => (props.errorMessage ? 'negative' : 'secondaryMedium'));

const hideMultiSelectItems = () => {
  selectActive.value = false;
  emit('click-outside');
};

function validate(newValue: SelectBoxItem[]) {
  isDirty.value = true;

  if (!props.rules?.length) {
    return;
  }

  const value = newValue || props?.value;
  const errorBucketTemp = [];

  for (let index = 0; index < props.rules.length; index++) {
    const rule = props.rules[index];
    const valid = typeof rule === 'function' ? rule(value) : rule;

    if (valid === false || typeof valid === 'string') {
      errorBucketTemp.push(valid || '');
    }
  }

  errorBucket.value = errorBucketTemp;
  valid.value = errorBucketTemp.length === 0;

  return valid.value;
}

const clickItem = (item: SelectBoxItem) => (selectedItems.value = clickItemHandler(item, selectedItems.value));

defineExpose({ validate });
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/main';
@import '../multiSelectStyles';
.multiselect-box {
  &__item {
    padding: 2px 0;

    &:hover {
      background: $accentClear;
    }
  }

  &__select {
    color: $primary;
    position: relative;
  }

  &__content {
    z-index: 7;
  }
}

::v-deep .multiselect-box__items {
  .eewc-checkbox--normal svg {
    color: $primary;
  }
}
</style>
