<template>
  <button
    :class="[
      'een-button',
      {
        isIcon: icon,
        hasAppendIcon: appendIcon,
        small,
        'een-button__small-padding': smallPadding,
      },
      !loading && color && color + 'Btn',
      !loading && type && type + 'Btn',
      !loading
        ? {
          disabled: disabled,
          onImage: onImage,
          map: map,
        }
        : 'loading-button',
      { block: block },
      { overlay: overlay },
      { 'overlay-disabled': overlay && disabled },
      { 'een-button__rounded': rounded },
    ]"
    @click="(e) => buttonClick(e)"
  >
    <slot name="prependIcon" />
    <slot>
      {{ text }}
    </slot>
    <v-icon
      v-if="badgeCount === undefined && appendIcon && !loading"
      right
      :size="iconSize"
      :color="iconColor"
    >
      {{ appendIcon }}
    </v-icon>
    <v-badge
      v-if="badgeCount !== undefined && appendIcon && !loading"
      class="badge-wrapper"
      overlap
    >
      <template #badge>
        <span>{{ badgeCount }}</span>
      </template>
      <v-icon
        right
        :size="iconSize"
        :color="iconColor"
      >
        {{ appendIcon }}
      </v-icon>
    </v-badge>
  </button>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: 'click', event: Event): void;
}>();

withDefaults(
  defineProps<{
    color?: string;
    type?: string;
    icon?: boolean;
    onImage?: boolean;
    map?: boolean;
    text?: string;
    disabled?: boolean;
    loading?: boolean;
    appendIcon?: string;
    iconSize?: number;
    iconColor?: string;
    block?: boolean;
    small?: boolean;
    smallPadding?: boolean;
    overlay?: boolean;
    rounded?: boolean;
    badgeCount?: number;
  }>(),
  {
    color: '',
    type: '',
    icon: false,
    onImage: false,
    map: false,
    text: '',
    disabled: false,
    loading: false,
    appendIcon: '',
    iconSize: 24,
    iconColor: '',
    block: false,
    small: false,
    smallPadding: false,
    overlay: false,
    badgeCount: undefined,
    rounded: false,
  }
);

function buttonClick(event: Event) {
  emit('click', event);
}
</script>

<style lang="scss">
@import '../../assets/styles/main';

.badge-wrapper .v-badge__wrapper .v-badge__badge {
  background-color: $accent !important;
}

@mixin solid($bg-c, $active-bg-c) {
  background: $bg-c;

  &:active {
    // pressed
    background: $active-bg-c !important;
  }
}

@mixin depressed($icon-c, $bg-c, $active-bg-c) {
  &,
  .v-icon {
    color: $icon-c;
  }

  background: $bg-c !important;

  &:active {
    // pressed
    background: $active-bg-c !important;
  }
}

@mixin outlined-and-clear($icon-c, $hover-bg-c, $active-bg-c, $border-c) {
  &,
  .v-icon {
    color: $icon-c;
  }

  border: 1px solid $border-c !important;
  background: unset !important;

  &:hover {
    background: $hover-bg-c !important;
  }

  &:active {
    // pressed
    background: $active-bg-c !important;
  }
}

.een-button {
  height: 36px;
  padding: 0 16px;
  display: inline-block;
  text-align: center !important;
  align-items: center;
  border-radius: 4px;
  white-space: nowrap;
  @include subtitle-2;

  &__rounded {
    border-radius: 24px;
  }

  &:focus {
    // fix for vuetify outline on focus state
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  &,
  &.primaryBtn {
    &,
    .v-icon {
      color: $primaryWhite;
    }

    background: $primary;

    &:hover {
      box-shadow: 2px 2px 2px rgba(42, 52, 64, 0.32);
      color: $elements;
    }

    &:active {
      // pressed
      box-shadow: unset;
      color: $primaryWhite;
      background: $primaryLight !important;
    }
  }

  &.block {
    width: 100%;
  }

  &.overlay {
    color: $primaryWhite !important;
    background: rgba(37, 41, 50, 0.6) !important;

    &:hover {
      color: $elements !important;
      box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);

      &:active {
        // pressed
        box-shadow: none;
        background: $primaryLight !important;
      }
    }
  }

  &.accentBtn {
    @include solid($accent, $accentDark);
  }

  &.positiveBtn {
    @include solid($positive, $positiveDark);
  }

  &.warningBtn {
    @include solid($warning, $warningDark);
  }

  &.negativeBtn {
    @include solid($negative, $negativeDark);
  }

  &.depressedBtn {
    &,
    .v-icon {
      color: $primary;
    }

    &,
    &.primaryBtn {
      // to cancel vuetify's primary color
      background-color: $elements !important;
      border-color: unset !important;

      background: $elements;

      &:active {
        // pressed
        box-shadow: unset;
        background: $secondaryLight !important;
      }
    }

    &.primaryBtn {
      &:hover {
        color: $primary !important;
      }
    }

    &.accentBtn {
      @include depressed($accent, $accentClear, $accentLight);
    }

    &.positiveBtn {
      @include depressed($positive, $positiveClear, $positiveLight);
    }

    &.warningBtn {
      @include depressed($warning, $warningClear, $warningLight);
    }

    &.negativeBtn {
      @include depressed($negative, $negativeClear, $negativeLight);
    }

    &.dropdownBtn {
      display: flex;
      justify-content: space-between;
      font-weight: 400;

      background: $backgrounds !important;
      border: 1px solid $elements !important;
      padding: 0 12px !important;

      &:hover {
        box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32) !important;
        color: $primary;
      }

      &.dropdown-menu__active {
        box-shadow: unset;
        border: 1px solid $accent !important;

        .v-icon {
          transform: rotate(180deg);
        }
      }

      &:active {
        // pressed
        box-shadow: unset;
        border: 1px solid $accent !important;
      }
    }

    &.disabled {
      border: 1px solid $elements !important;

      &,
      .v-icon {
        color: $secondaryMedium !important;
      }

      background: $elements !important;
    }
  }

  &.shadowBtn {
    color: $primaryWhite !important;
    background: unset !important;

    &:hover {
      color: $secondaryLight !important;
      box-shadow: unset;
    }

    // pressed
    &:active {
      color: $secondaryLight !important;
    }

    &.disabled {
      color: $secondaryMedium !important;
      background: unset !important;
      background-color: unset !important;
    }

    &,
    .v-icon {
      color: $primaryWhite;

      &:hover {
        color: $secondaryLight !important;
      }
    }
  }

  &.outlinedBtn,
  &.clearBtn {
    &,
    .v-icon {
      color: $primary;
    }

    &,
    &.primaryBtn {
      // to cancel vuetify's primary color
      background-color: unset !important;
      border-color: $secondaryLight !important;

      border: 1px solid $secondaryLight;
      background: unset !important;
      box-shadow: unset !important;

      &:hover {
        background: $elements !important;
        color: $primary;
      }

      &:active {
        // pressed
        background: $secondaryLight !important;
        color: $primary;
      }
    }

    &.accentBtn {
      @include outlined-and-clear($accent, $accentClear, $accentLight, $accentLight);
    }

    &.positiveBtn {
      @include outlined-and-clear($positive, $positiveClear, $positiveLight, $positiveLight);
    }

    &.warningBtn {
      @include outlined-and-clear($warning, $warningClear, $warningLight, $warningLight);
    }

    &.negativeBtn {
      @include outlined-and-clear($negative, $negativeClear, $negativeLight, $negativeLight);
    }

    &.disabled {
      border: 1px solid $secondaryLight;

      &,
      .v-icon {
        color: $secondaryMedium;
      }

      background: unset !important;
    }
  }

  &.clearBtn {
    &,
    &.accentBtn,
    &.positiveBtn,
    &.warningBtn,
    &.negativeBtn,
    &.shadowBtn,
    &.disabled {
      border: unset !important;
    }
  }

  &.onImage {
    backdrop-filter: blur(4px);
    background: rgba(33, 42, 52, 0.72);

    &:active {
      // pressed
      background: rgba(68, 77, 86, 0.72) !important;
    }
  }

  &.hasAppendIcon {
    padding-right: 8px !important;
    grid-template-columns: auto auto;
    gap: 4px;

    .v-icon {
      margin: 0 !important;
    }
  }

  &.isIcon {
    width: 36px;
    height: 36px;
    padding: 0 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .v-icon {
      margin: 0 !important;
    }

    &:hover {
      background: $primaryMedium;
    }

    &.small {
      height: 24px;
      width: 24px;
    }
  }

  &.map {
    background: white !important;
    box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);

    &,
    .v-icon {
      color: $primary;
    }

    &:hover {
      &,
      .v-icon {
        color: $secondaryMedium !important;
      }
    }

    &.disabled {
      &,
      .v-icon {
        color: $secondaryLight !important;
      }
    }
  }

  &:focus-visible {
    position: relative;
    border: unset !important;
    outline: unset !important;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border: 2px solid $primaryWhite;
      box-shadow: 0px 0 0 1px $primary;
      border-radius: 4px;
    }
  }

  &.loading-button {
    position: relative;
    border: unset !important;

    &,
    .v-icon {
      color: transparent !important;
    }

    pointer-events: none;
    background: $elements !important;
    background-color: $elements !important;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -10px;
      margin-left: -10px;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border: 2px solid rgba(white, 0.32);
      border-top-color: $primary;
      animation: loading-button 1.1s linear infinite;

      @keyframes loading-button {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;
    color: $secondaryMedium;

    &:active {
      // pressed
      background: $elements !important;
      box-shadow: unset;
    }

    &,
    .v-icon {
      color: $secondaryMedium;
    }

    cursor: default;
    background: $elements !important;
    background-color: $elements !important;
  }

  &.overlay-disabled {
    background: rgba(37, 41, 50, 0.6) !important;
  }

  &__small-padding {
    padding: 0 8px;
  }
}

.een-button::first-letter {
  text-transform: uppercase;
}
</style>
