<template>
  <div class="profile-menu__list">
    <v-list-item
      v-for="(item, i) in items"
      :key="i"
      class="profile-menu__list-item"
      :ripple="false"
      @mouseenter="$emit('hover', { section, index: i })"
      @mouseleave="$emit('hover', { section, index: undefined })"
      @click="$emit('click', item)"
    >
      <v-icon
        v-if="item && typeof item === 'object' && 'icon' in item"
        :color="isHovered(i) || (item && 'active' in item && item.active) ? 'accent' : 'primary'"
        size="24"
        class="mr-3"
      >
        {{ item.icon }}
      </v-icon>
      <v-list-item-title
        :class="[
          'profile-menu__title',
          {
            'profile-menu__title--highlighted':
              isHovered(i) || (item && typeof item === 'object' && 'active' in item && item.active),
          },
        ]"
      >
        {{ item && typeof item === 'object' && 'name' in item ? item.name : '' }}
      </v-list-item-title>
    </v-list-item>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  section: {
    type: String,
    required: true,
  },
  hoveredItem: {
    type: Object,
    default: () => ({}),
  },
});

function isHovered(index: number) {
  return props.hoveredItem.section === props.section && props.hoveredItem.index === index;
}

defineEmits<{
  hover: [{ section: string; index: number | undefined }];
  click: [item: { icon?: string; name?: string; active?: boolean } | unknown];
}>();
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main';

.profile-menu {
  &__list {
    &-item {
      padding-block: 4px !important;

      &:hover {
        background: transparent !important;
      }
    }
  }

  &__title {
    // have used the style for subtitle-2 here with important to override the default style
    @include subtitle-2-important;
    color: $primary !important;

    &--highlighted {
      color: $accent !important;
    }
  }
}
</style>
