<template>
  <div
    class="page-title__wrapper"
    :class="{ 'page-title__wrapper--sticky': sticky }"
  >
    <template v-if="loading">
      <div class="page-title d-flex justify-space-between align-center">
        <skeleton
          type="title"
          class="page-title__skeleton"
        />
      </div>
    </template>
    <template v-else>
      <div
        class="page-title d-flex justify-space-between align-center"
        :class="{
          'page-title--sticky': sticky,
          'page-title--top': !!distanceTop,
          'my-0': noMargin,
          'pr-0': noRightPadding,
        }"
        :style="cssVars"
      >
        <navigation-breadcrumbs
          v-if="breadcrumbs.length"
          :value="breadcrumbs"
          divider-icon="$icon_arrow_right"
        />
        <div
          v-else
          class="page-title__name"
        >
          {{ title }}
          <div class="page-title__left-action">
            <slot name="left-action" />
          </div>
        </div>
        <div class="page-title__action d-flex align-center">
          <slot name="action" />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import vuetify from '../../service/useVuetify';
import NavigationBreadcrumbs, { breadcrumb } from '../navigation/Breadcrumbs.vue';
import Skeleton from '../loaders/Skeleton.vue';

const props = withDefaults(
  defineProps<{
    title?: string;
    breadcrumbs?: Array<breadcrumb>;
    sticky?: boolean;
    distanceTop?: number | boolean;
    loading?: boolean;
    noRightPadding?: boolean;
    noMargin?: boolean;
  }>(),
  {
    breadcrumbs: () => [],
    loading: false,
    title: '',
    distanceTop: false,
    sticky: false,
    noRightPadding: false,
    noMargin: false,
  }
);

const cssVars = computed(() => {
  return {
    ...(props &&
      props.distanceTop && {
        '--distance-top': typeof props.distanceTop === 'number' ? props.distanceTop : headerHeigth.value + 'px',
      }),
  };
});

const headerHeigth = computed(() => {
  switch (vuetify.value.breakpoint.name) {
    case 'sm':
      return 44;
    case 'md':
    case 'lg':
      return 56;
    case 'xl':
      return 64;
    default:
      return 64;
  }
});
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main';

.page-title {
  height: 40px;
  padding: 12px 5px 12px 4px;
  margin: 6px 0;
  &__wrapper {
    &--sticky {
      display: unset;
    }
  }
  &__name {
    @include subtitle-1;
    color: $primary;
  }
  &__left-action {
    display: inline-block;
    vertical-align: middle;
  }
  &--sticky {
    position: sticky;
    z-index: 5;
    background: white;
    top: 0px;
  }
  &--top {
    top: var(--distance-top);
  }
  &__skeleton {
    flex-grow: 1;
  }
}

.full-screen--enabled {
  .page-title--sticky.page-title--top {
    top: 0;
  }
}
</style>
