<template>
  <div>
    <nav-sub-item
      v-for="(subItem, subItemIndex) in subItems"
      :key="subItemIndex"
      :name="subItem.name"
      :active="!!subItem.active"
      :minimized="minimized"
      :white-label="whiteLabel"
      @click="emit('subItemClicked', parentIndex, subItemIndex)"
    />
    <div
      v-if="hasInfiniteHandler && !paginationRef.completed"
      v-intersect="handleIntersection"
    >
      <loading-spinner
        v-if="!paginationRef.loaded"
        is-loading
        :absolute="false"
        :class="['infinite-loader']"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { IntersectionPaginationOption, IntersectionStateChanger, NavBarSubItem } from '@/utils/types';
import NavSubItem from './NavSubItem.vue';
import LoadingSpinner from '../../loaders/LoadingSpinner.vue';

const props = withDefaults(
  defineProps<{
    subItems: NavBarSubItem[];
    parentIndex: number;
    minimized?: boolean;
    hasInfiniteHandler?: boolean;
    whiteLabel: string;
  }>(),
  {
    minimized: false,
    hasInfiniteHandler: false,
  }
);

const emit = defineEmits<{
  (e: 'subItemClicked', index: number, subIndex: number): void;
  (e: 'handleIntersection', index: number, state: IntersectionStateChanger): void;
}>();

const paginationRef = ref<IntersectionPaginationOption>({
  loaded: false,
  completed: false,
  searchText: undefined,
  bounceTimer: undefined,
});

const state: IntersectionStateChanger = {
  loaded: () => {
    paginationRef.value.loaded = true;
  },
  completed: () => {
    paginationRef.value.completed = true;
  },
  reset: () => {
    paginationRef.value.loaded = false;
    paginationRef.value.completed = false;
  },
};

function handleIntersection(
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver,
  isIntersecting: boolean
) {
  if (!isIntersecting) {
    paginationRef.value.loaded = false;
    return;
  }
  if (paginationRef.value.completed) return;
  paginationRef.value.loaded = false;
  emit('handleIntersection', props.parentIndex, state);
}
</script>

<style scoped>
.infinite-loader {
  text-align: center;
  padding-bottom: 10px;
}
</style>
