<template>
  <div>
    <v-autocomplete
      v-model="selectedItem"
      :label="labelText"
      :items="items"
      :append-icon="appendIcon"
      :filled="!clear"
      dense
      :search-input.sync="paginationRef.searchText"
      height="36px"
      :no-filter="paginated"
      single-line
      :outlined="!clear"
      :solo="clear"
      :flat="clear"
      class="dropdown__simple"
      :class="{
        'dropdown__simple--disabled': disabled,
        'dropdown__simple--no-translate ': stopIconTransform,
        'dropdown__simple--min-height': showSubText,
      }"
      :disabled="disabled"
      return-object
      item-color="white"
      :loading="loading"
      :attach="isAttached"
      :no-data-text="!paginated ? '$vuetify.noDataText' : ''"
      :rules="[...rules]"
      :menu-props="menuProps"
      @update:error="onError"
      @blur="handleBlur"
      @focus="syncValue"
    >
      <template
        v-if="paginated"
        #append-item
      >
        <div v-intersect="handleIntersection" />
        <loading-spinner
          v-if="!paginationRef.loaded && !paginationRef.completed"
          is-loading
          :absolute="false"
          :class="['infinite-loader', { 'infinite-loader--margin': !items.length }]"
        />
      </template>

      <template
        v-if="showSubText"
        #item="{ item, on }"
      >
        <v-list-item
          :ripple="false"
          v-on="on"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.subText">
              {{ item.subText }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template
        v-if="paginated"
        #no-data
      >
        <div v-if="paginationRef.completed">
          {{ noDataText }}
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import LoadingSpinner from '../../components/loaders/LoadingSpinner.vue';
import { IntersectionPaginationOption, IntersectionStateChanger } from '@/utils/types';

type Item = {
  text: string;
  value: string | number;
  subText?: string;
};

type MenuProps = {
  closeOnClick?: boolean;
  closeOnContentClick?: boolean;
  disableKeys?: boolean;
  openOnClick?: boolean;
  maxHeight?: number;
  maxWidth?: number;
  offsetY?: boolean;
  offsetX?: boolean;
  offsetOverflow?: boolean;
  transition?: boolean | string;
  top?: boolean;
  left?: boolean;
  right?: boolean;
  bottom?: boolean;
  openOnHover?: false;
  closeDelay?: number;
  openDelay?: number;
  nudgeBottom?: number;
  nudgeTop?: number;
  nudgeLeft?: number;
  nudgeRight?: number;
};
export type AutoCompleteValue = Item | string;

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    value: AutoCompleteValue;
    items: Array<AutoCompleteValue>;
    labelText?: string;
    loading?: boolean;
    appendIcon?: string;
    stopIconTransform?: boolean;
    isAttached?: boolean;
    paginated?: boolean;
    noDataText?: string;
    rules?: Array<string | boolean | ((value: object) => boolean | string)>;
    menuProps?: MenuProps;
    clear?: boolean;
  }>(),
  {
    appendIcon: '$icon_arrow_down',
    stopIconTransform: false,
    disabled: false,
    labelText: '',
    isAttached: false,
    paginated: false,
    noDataText: '',
    rules: () => [],
    clear: false,
  }
);

const emit = defineEmits<{
  (e: 'input', value?: AutoCompleteValue): void;
  (
    e: 'search',
    value: {
      searchText: string | undefined | null;
      state: IntersectionStateChanger;
    }
  ): void;
  (e: 'infinite', value: IntersectionStateChanger): void;
  (e: 'blur'): void;
}>();

const selectedItem = computed({
  get: () => props.value,
  set: (newValue) => {
    emit('input', newValue);
  },
});

function handleIntersection(
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver,
  isIntersecting: boolean
) {
  if (isIntersecting) {
    if (paginationRef.value.completed) return;
    paginationRef.value.loaded = false;
    emit('infinite', state);
  }
}

const paginationRef = ref<IntersectionPaginationOption>({
  loaded: false,
  completed: false,
  searchText: undefined,
  bounceTimer: undefined,
});

const hasError = ref(false);

const showSubText = computed(() => props.items?.every((item) => typeof item !== 'string' && item.subText));

function onError(value: boolean) {
  hasError.value = value;
}

function handleBlur() {
  emit('blur');

  hasError.value = props.rules.some((rule) => {
    return typeof rule === 'string' || (typeof rule === 'boolean' && rule);
  });
}

const syncValue = (e: Event) => {
  const target = e.target as HTMLInputElement;

  if (!props.paginated) return;
  if (paginationRef.value && target.value && paginationRef.value.searchText !== target.value) {
    state.reset();
    emit('search', { searchText: target.value, state });
    paginationRef.value.searchText = target.value;
    selectedItem.value = target.value;
  }
};

watch(
  () => paginationRef.value.searchText,
  (newValue, oldValue) => {
    if (!props.paginated) return;
    if (newValue === oldValue) return;
    state.reset();

    if (props.value) {
      if (paginationRef.value.searchText?.length && paginationRef.value.searchText === props.value.text) {
        // watcher for searchText will be called whenever an option is selected from dropdown, to avoid search in the case the condition is added
        return;
      }
    }
    // debounce for the search
    clearTimeout(paginationRef.value.bounceTimer);
    paginationRef.value.bounceTimer = setTimeout(() => {
      emit('search', { searchText: paginationRef.value.searchText, state });
    }, 500);
  }
);

const state: IntersectionStateChanger = {
  loaded: () => {
    paginationRef.value.loaded = true;
  },
  completed: () => {
    paginationRef.value.completed = true;
  },
  reset: () => {
    paginationRef.value.loaded = false;
    paginationRef.value.completed = false;
  },
};
</script>

<style lang="scss">
@import '../../assets/styles/main';
.dropdown__simple .v-input__append-inner {
  margin: 6px 0 !important;
  align-self: center;
}

.dropdown__simple .v-label {
  position: unset !important;
}

.dropdown__simple {
  position: relative;
  .v-text-field__details {
    position: absolute;
    bottom: -24px;
    left: -12px;
    .v-messages__message {
      @include body-2;
    }
  }
  input {
    font-size: 14px;
  }

  &.error--text {
    fieldset {
      border: 1px solid $negative !important;
    }
  }
  .v-input__slot {
    min-height: 36px !important;
    padding-right: 8px !important;
    & fieldset {
      background: $backgrounds;
      color: $elements !important;
      border: 1px solid;
    }
    input {
      font-size: 14px;
      color: $primary;
    }

    & label {
      @include body-2;
      color: $secondaryMedium;
    }

    &:hover {
      box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
    }

    &:focus-within {
      & fieldset {
        border: 1px solid $accentFixed;
      }
    }
    z-index: 2;
    .v-list-item {
      min-height: 28px !important;
    }

    .v-list-item--active {
      background: $accentFixed !important;
      border-radius: 4px;
    }
  }
}

.infinite-loader {
  text-align: center;

  &--margin {
    margin-top: -30px;
  }
}

.v-autocomplete__content.v-menu__content {
  box-shadow: 2px 4px 12px 0px #2a344014, 0 0 0 1px $elements;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 4px;
  max-height: 235px !important;
  padding: {
    right: 4px;
    left: 4px;
  }

  .v-list--dense .v-list-item {
    padding: 0 12px;
    &__title {
      @include body-2;
    }
    &__subtitle {
      @include body-3;
    }

    &__mask {
      color: $primaryMedium !important;
      background: transparent !important;
      font-weight: 700 !important;
    }
  }
}

.dropdown__simple {
  &--disabled .v-input__slot {
    & fieldset {
      background: $elements;
    }
  }

  &--min-height .v-list--dense .v-list-item {
    min-height: 50px;
  }

  &--no-translate {
    .notranslate {
      transform: none !important;
    }
  }

  & .v-icon {
    color: $secondaryMedium !important;

    & .primary--text {
      color: $secondaryMedium !important;
    }
  }
}
</style>
