<template>
  <div
    class="eewc-form-field"
    :class="big ? 'big' : ''"
    :style="{
      gridTemplateColumns: labelWidthValue ? labelWidthValue + ' 1fr' : '',
    }"
  >
    <div class="form-field__label d-flex align-center justify-end">
      <Tooltip
        v-if="props.tooltipOptions && props.tooltipOptions.text && props.tooltipOptions?.position === 'left'"
        :text="props.tooltipOptions.text"
        :max-width="props.tooltipOptions.maxWidth"
        :nudge-right="props.tooltipOptions.nudgeRight"
      >
        <template #content="{ on, attrs }">
          <v-icon
            color="accent"
            size="24"
            v-bind="attrs"
            class="mr-1"
            v-on="on"
          >
            {{ props.tooltipOptions.icon ?? '$icon_attention' }}
          </v-icon>
        </template>
      </Tooltip>
      <div class="d-flex flex-column justify-center align-end">
        <div class="label subtitle-2">
          {{ label }}
        </div>
        <div
          v-if="optional"
          class="optional"
        >
          {{ optional }}
        </div>
      </div>
    </div>
    <div :class="[{ slot: loading }]">
      <div class="eewc-form-field__input d-flex align-center">
        <div
          v-if="!loading"
          class="flex-grow-1"
        >
          <slot />
        </div>
        <skeleton
          v-else
          class="flex-grow-1"
          type="text"
          full-height
        />
        <Tooltip
          v-if="props.tooltipOptions && props.tooltipOptions.text && props.tooltipOptions?.position === 'right'"
          :text="props.tooltipOptions.text"
          :max-width="props.tooltipOptions.maxWidth"
          :nudge-right="props.tooltipOptions.nudgeRight"
        >
          <template #content="{ on, attrs }">
            <v-icon
              color="accent"
              size="24"
              class="ml-1"
              v-bind="attrs"
              v-on="on"
            >
              {{ props.tooltipOptions.icon ?? '$icon_attention' }}
            </v-icon>
          </template>
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import Skeleton from './loaders/Skeleton.vue';
import Tooltip from './tooltip/Tooltip.vue';

const props = defineProps<{
  label?: string;
  labelWidth?: number | string;
  optional?: string;
  big?: boolean;
  loading?: boolean;
  tooltipOptions?: {
    text: string;
    maxWidth: number;
    nudgeRight: number;
    position: 'left' | 'right';
    icon: string;
  };
}>();

const labelWidthValue = computed(() =>
  props.labelWidth ? (typeof props.labelWidth === 'number' ? props.labelWidth + 'px' : props.labelWidth) : undefined
);
</script>

<style lang="scss" scoped>
@import '../assets/styles/main';

.eewc-form-field {
  display: grid;
  grid-template-columns: 126px 1fr;
  padding-left: 4px;

  &.big {
    grid-template-columns: 130px 1fr;
  }

  margin-top: 0;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .form-field__label {
    text-align: right;
    padding-right: 12px;
    height: 36px;

    .label {
      color: $primaryMedium;
    }

    .optional {
      font-size: 10px;
      line-height: 1;
      color: $secondaryMedium;
    }
  }

  &__input {
    height: 100%;
    &__slot-holder {
      flex: 1;
    }
  }

  .slot {
    height: -webkit-fill-available;
  }
}
</style>
