<template>
  <div :class="['startDate', 'endDate'].includes(label) ? 'text-input__date' : 'text-input__time'">
    <text-field
      ref="inputRef"
      :value="inputValue"
      :right-icon="rightIcon"
      :maxlength="maxLength"
      :input-type="label"
      :validation-error="hasError"
      @focus="handleInputOnFocus"
      @input="handleInput"
      @blur="handleBlur"
      @clickAppend="handleClickAppend"
      @input-ref="handleInputRef"
      @keydown="handleKeydown"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import TextField from '../text-inputs/TextField.vue';

const props = defineProps<{
  value: string;
  hasError: boolean;
  maxLength: number;
  label: string;
}>();

const rightIcon = computed(() => {
  if (!props.hasError) {
    return ['startDate', 'endDate'].includes(props.label) ? '$icon_calendar' : '$icon_history';
  }
  return '$icon_attention';
});

const inputValue = ref(props.value);

const emit = defineEmits<{
  (e: 'input', inputValue: { value: string; label: string }): void;
  (e: 'focus', shouldShowTimePanel: string): void;
  (e: 'blur'): void;
  (e: 'keydown', event: KeyboardEvent): void;
}>();

const currentValue = computed(() => props.value);

const handleBlur = () => {
  emit('blur');
};

const handleInputOnFocus = () => {
  emit('focus', props.label);
};

watch(inputValue, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    emit('input', { value: newValue, label: props.label });
  }
});

watch(currentValue, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    inputValue.value = newValue;
  }
});

const handleInput = (value: string) => {
  inputValue.value = value;
};

const inputRefValue = ref<HTMLElement | null>(null);

const handleClickAppend = () => {
  inputRefValue.value?.focus();
};

const handleInputRef = (value: HTMLElement | null) => {
  inputRefValue.value = value;
};

const handleKeydown = (event: KeyboardEvent) => {
  emit('keydown', event);
};
</script>

<style scoped lang="scss">
@import '../../assets/styles/main';

.text-input {
  &__date {
    width: 120px;
  }

  &__time {
    width: 127px;
  }
}
</style>
