<template>
  <v-card
    class="face-recognition-card"
    @click="handleFaceCardClick"
  >
    <slot />
  </v-card>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: 'click'): void;
}>();

const handleFaceCardClick = () => {
  emit('click');
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main';

.face-recognition-card {
  min-width: 26px;
  min-height: 26px;
  border-radius: 4px;
  padding: 0 !important;
  aspect-ratio: 1/1;
}
</style>
