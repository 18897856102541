<template>
  <div>
    <v-card
      class="editableTableCard pa-0"
      outlined
    >
      <v-card-title class="text-md-subtitle-2 ml-4 my-1 pa-0">
        {{ headerText }}
        <v-spacer />
        <ButtonCommon
          :append-icon="'$icon_plus'"
          type="clear"
          icon
          class="mr-1"
          :icon-size="24"
          @click.stop="appendData()"
        />
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <v-data-table
          :headers="tableHeaders"
          hide-default-footer
          hide-default-header
          :items="tableData"
          dense
          class="pa-0 ma-0"
        >
          <template #[`item.key`]="{ item, index }">
            <td
              class="tableColumn"
              width="50%"
            >
              <TextField
                v-model="item.key"
                :class="index === tableData.length - 1 && 'propertyField'"
                :label="propertyLabel"
                :rules="item.value ? rules : []"
                :show-error-icon="false"
                hide-details
                @input-ref="addInputRef($event, `property-${item.id}`)"
              >
                <template
                  v-if="checkInputValidity(`property-${item.id}`)"
                  #append
                >
                  <Tooltip
                    :text="requiredText"
                    bottom
                  >
                    <template #content="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="textfieldIcon"
                        v-on="on"
                      >
                        $icon_attention
                      </v-icon>
                    </template>
                  </Tooltip>
                </template>
              </TextField>
            </td>
          </template>
          <template #[`item.value`]="{ item }">
            <td
              class="tableColumn"
              width="50%"
            >
              <TextField
                v-model="item.value"
                :label="valueLabel"
                :rules="item.key ? rules : []"
                :show-error-icon="false"
                hide-details
                @input-ref="addInputRef($event, `value-${item.id}`)"
              >
                <template
                  v-if="checkInputValidity(`value-${item.id}`)"
                  #append
                >
                  <Tooltip
                    :text="requiredText"
                    bottom
                  >
                    <template #content="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="textfieldIcon"
                        v-on="on"
                      >
                        $icon_attention
                      </v-icon>
                    </template>
                  </Tooltip>
                </template>
              </TextField>
            </td>
          </template>
          <template #[`item.action`]="{ item, index }">
            <td
              width="10%"
              class="tableButton"
              :class="index === tableData.length - 1 && 'tableButtonRadius'"
            >
              <Tooltip
                :text="tableData.length == 1 ? '' : !item.key && !item.value ? '' : deleteText"
                bottom
              >
                <template #content="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <ButtonCommon
                      :append-icon="'$icon_delete'"
                      type="clear"
                      icon
                      :icon-size="24"
                      :disabled="tableData.length == 1 ? true : !item.key && !item.value"
                      @click="removeRow(index)"
                    />
                  </div>
                </template>
              </Tooltip>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import TextField from './text-inputs/TextField.vue';
import ButtonCommon from './buttons/ButtonCommon.vue';
import Tooltip from './tooltip/Tooltip.vue';

type KeyValue = {
  id?: string;
  key: string;
  value: string;
};

type Props = {
  headerText: string;
  propertyLabel: string;
  valueLabel: string;
  data: KeyValue[];
  rules: () => [];
  requiredText: string;
  deleteText: string;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'input', value: KeyValue[]): void;
}>();

const tableHeaders = ref([
  {
    text: '',
    value: 'key',
    sortable: false,
    align: 'left',
    divider: true,
    width: '50% ',
  },
  {
    text: '',
    value: 'value',
    sortable: false,
    align: 'left',
    divider: true,
    width: '50%',
  },
  {
    text: '',
    value: 'action',
    sortable: false,
    align: 'left',
    divider: true,
  },
]);

const tableData = ref<KeyValue[]>(props.data.map((data, index) => ({ ...data, id: index.toString() })));
const inputMap = new Map();

watch(
  () => tableData.value,
  () => {
    emit('input', tableData.value);
  },
  { deep: true }
);

const appendData = () => {
  tableData.value.push({
    id: tableData.value.length.toString(),
    key: '',
    value: '',
  });
};

const removeRow = (index: number) => {
  tableData.value.splice(index, 1);
};

const addInputRef = (input, key) => {
  if (input) {
    inputMap.set(key, input);
  }
};

const checkInputValidity = (key) => {
  const input = inputMap.get(key);
  if (input) {
    return !input.valid;
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/styles/main';

.editableTableCard {
  border: 1px solid $secondaryLight !important;
  background-color: $backgrounds !important;
  border-radius: 6px;
}

.tableColumn {
  padding: 0 !important;
  margin: 0;
}

.propertyField {
  ::v-deep(.v-input__slot fieldset) {
    border-bottom-left-radius: 4px !important;
  }
}

::v-deep(.v-input__slot) {
  min-height: 38px !important;
  margin-bottom: 0 !important;
  padding: 0 8px 0 12px !important;

  fieldset {
    border-radius: 0% !important;
    border: 1px solid $secondaryLight;
    background: $primaryWhite !important;
  }

  &:focus-within {
    & fieldset {
      color: $secondaryLight !important;
    }
  }

  &:hover {
    & fieldset {
      color: $secondaryLight !important;
    }
  }
}

.tableButton {
  padding: 0 !important;
  border: 1px solid $secondaryLight;
  border-left: none;
  background-color: white;
  display: flex;
  height: 38px !important;
  width: fit-content;
  align-items: center;
  justify-content: center;
}

.tableButtonRadius {
  border-bottom-right-radius: 4px;
}

.textfieldIcon {
  cursor: pointer;
}
</style>
