<template>
  <div
    class="tooltip-container"
    :style="{ maxWidth: maxWidthValue }"
  >
    <tooltip
      v-if="show"
      :text="text"
      bottom
    >
      <template #content="{ on, attrs }">
        <div
          ref="textRef"
          class="text-content"
          v-bind="attrs"
          v-on="on"
          @mouseover="mouseover"
        >
          {{ text }}
        </div>
      </template>
    </tooltip>
    <div
      v-else
      ref="textRef"
      class="text-content"
      @mouseover="mouseover"
    >
      {{ text }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue';
import Tooltip from '../Tooltip.vue';

const props = defineProps<{
  text?: string;
  maxWidth?: number | string;
}>();

const show = ref(false);
const textRef = ref();

const maxWidthValue = computed(() =>
  props.maxWidth ? (typeof props.maxWidth === 'number' ? props.maxWidth + 'px' : props.maxWidth) : undefined
);

onMounted(async () => {
  await nextTick(() => {
    checkRange();
  });
});

function checkRange() {
  const el = textRef.value;
  if (el) {
    const range = new Range();
    range.selectNodeContents(el);

    const rangeRect = range.getBoundingClientRect();
    const elRect = el.getBoundingClientRect();

    // add .5px bcz when the range width shoud be exactly the same as that of the rect,
    // it is slightly smaller, so the tooltip is shown where not yet needed
    show.value = rangeRect.width > elRect.width + 0.5;
  }
}

function mouseover() {
  checkRange();
}

defineExpose({ show });
</script>

<style lang="scss">
.tooltip-container {
  min-width: 0;
}

.text-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
