<template>
  <div
    :class="[
      'action-card',
      {
        'action-card--hover': actionCardHover,
        'action-card--active': actionCardActive || isActive,
      },
    ]"
    @mouseenter="actionCardHover = true"
    @mouseleave="onMouseLeave"
    @mousedown="actionCardActive = true"
    @mouseup="actionCardActive = false"
    @click="actionCardClicked"
  >
    <drop-down-menu
      v-if="actions?.length"
      :tooltip="moreTooltip"
      class="pa-3 action-card__menu"
      :menu-items="actions"
      custom-toggler
      @select="(value) => $emit('dropdown-menu-select', value)"
      @mouseover.native="actionCardHover = false"
      @mouseleave.native="actionCardHover = true"
      @mousedown.native.stop
      @mouseup.native.stop
    >
      <template #toggler="{ on, attrs }">
        <button-common
          icon
          :icon-size="24"
          append-icon="$icon_more_vert"
          small
          type="clear"
          v-bind="attrs"
          v-on="on"
        />
      </template>
    </drop-down-menu>

    <div class="action-card__name mb-2 pr-6">
      <v-icon
        v-if="icon"
        color="accent"
        size="32"
        class="mr-1"
      >
        {{ icon }}
      </v-icon>

      {{ title }}
    </div>
    <div class="action-card__description">
      <slot name="description" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import DropDownMenu from './../../dropdowns/DropDownMenu.vue';
import ButtonCommon from './../../buttons/ButtonCommon.vue';
import { MenuItem } from './../../dropdowns/types';

const actionCardHover = ref(false);
const actionCardActive = ref(false);

withDefaults(
  defineProps<{
    moreTooltip?: string;
    actions?: MenuItem[];
    title?: string;
    description?: string;
    isActive?: boolean;
    icon?: string;
  }>(),
  {
    moreTooltip: undefined,
    title: undefined,
    description: undefined,
    icon: undefined,
    isActive: false,
  }
);

const emit = defineEmits<{
  (e: 'dropdown-menu-select', value: string | number | boolean | object): void;
  (e: 'click', event: Event): void;
}>();

function actionCardClicked(event: Event) {
  actionCardActive.value = false;
  emit('click', event);
}

function onMouseLeave() {
  actionCardHover.value = false;
  actionCardActive.value = false;
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/main';

.action-card {
  padding: 12px 12px 16px 12px;
  border-radius: 4px;
  border: 1px solid $elements;
  background: $primaryWhite;
  position: relative;
  transition: 0.2s;
  cursor: pointer;
  user-select: none;

  &__name {
    @include subtitle-1;
    color: $primary;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__description {
    @include body-3;
  }

  &__menu {
    position: absolute;
    right: 0;
    top: 0;
  }

  &--hover {
    box-shadow: 0 2px 12px 0 rgba(42, 52, 64, 0.08);
  }

  &--active {
    border: 1px solid $elements;
    background: $backgrounds;
    box-shadow: none;
  }
}
</style>
