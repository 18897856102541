<template>
  <div
    v-click-outside="hideDropdown"
    class="dropdown-range-input"
    @keyup.enter="hideDropdown"
  >
    <button
      class="dropdown-range-input__select"
      :class="{
        'dropdown-range-input__select--active': isDropdownOpen,
        'dropdown-range-input__select--none-label': valueLabel === labelString,
      }"
      @click="isDropdownOpen = !isDropdownOpen"
    >
      <span>{{ valueLabel }} </span>
      <v-icon
        :size="24"
        class="ml-auto"
        color="secondaryMedium"
      >
        $icon_arrow_down
      </v-icon>
    </button>
    <div v-if="isDropdownOpen">
      <v-card class="dropdown-range-input__content mt-1 py-3 d-flex">
        <div class="mx-2 dropdown-range-input__content--input">
          <slot name="minInput" />
        </div>
        <div class="mt-2 text-center">
          -
        </div>
        <div class="mx-2 dropdown-range-input__content--input">
          <slot name="maxInput" />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

/**
 * Types
 */
type RangeValue = string | undefined;

type Range = RangeValue[];

/**
 * Components
 */

const props = defineProps<{
  value: Range;
  rangeUnit: string;
  labelString: string;
  min?: number;
  max?: number;
}>();

const isDropdownOpen = ref(false);

const valueLabel = computed(() => {
  const minValue = props.value[0] !== '' ? Number(props.value[0]) : props.value[0];
  const maxValue = props.value[1] !== '' ? Number(props.value[1]) : props.value[1];

  const isMinValueTypeNumber = typeof minValue === 'number';
  const isMaxValueTypeNumber = typeof maxValue === 'number';

  if (!isDropdownOpen.value) {
    if (typeof props.min === 'number' && typeof props.max === 'number') {
      if ((isMinValueTypeNumber && minValue < props.min) || (isMaxValueTypeNumber && maxValue > props.max)) {
        return props.labelString;
      }
    }

    if (isMinValueTypeNumber && isMaxValueTypeNumber && minValue <= maxValue) {
      return `${minValue} - ${maxValue} ${props.rangeUnit}`;
    }

    if (isMinValueTypeNumber && !isMaxValueTypeNumber) {
      return `> ${minValue}${props.rangeUnit}`;
    }

    if (isMaxValueTypeNumber && !isMinValueTypeNumber) {
      return `< ${maxValue}${props.rangeUnit}`;
    }
  }

  return props.labelString;
});

const hideDropdown = () => {
  isDropdownOpen.value = false;
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/main.scss';

.dropdown-range-input {
  position: relative;
  @include body-2;

  &__select {
    min-height: 36px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 12px;
    height: 36px;
    border: 1px solid $elements;
    border-radius: 4px;
    background-color: $backgrounds;

    &--active {
      border-color: $accentFixed;

      .v-icon {
        transform: rotate(180deg);
      }
    }

    &--none-label {
      color: $secondaryMedium;
    }

    &--error {
      border: 1px solid $negative;
    }

    &:focus {
      outline: none;
    }
  }

  &__content {
    position: absolute;
    z-index: 10;
    width: 100%;
    background-color: $primaryWhite;
    border: 1px solid $elements;
    border-radius: 4px;

    &--input {
      width: 90%;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
