<template>
  <v-navigation-drawer
    :class="`menu-bar ${isMini ? 'menu-bar--mini' : ''}`"
    :mini-variant="isMini"
    floating
    app
    permanent
    disable-route-watcher
    :width="isMini && vuetify.breakpoint.mdAndUp ? 48 : navigationWidth"
  >
    <div class="menu-bar__extended-area" />
    <div
      v-if="navBlocked"
      class="menu-bar__nav_overlay"
      @click="emit('blocked-nav-clicked')"
    />
    <logo
      :mini="isMini"
      :loading="isLogoLoading"
      :logo-margin-s-m="logoMarginSM"
      :logo-margin-m-d="logoMarginMD"
      :logo-margin-l-g="logoMarginLG"
      :logo-margin-x-l="logoMarginXL"
      :logo-small-width="logoSmallWidth"
      :logo-small-height="logoSmallHeight"
      :logo-small="logoSmall"
      :logo-big-width="logoBigWidth"
      :logo-big-height="logoBigHeight"
      :logo-big="logoBig"
      :alt-logo-small="altLogoSmall"
      :alt-logo-big="altLogoBig"
      :is-e-e-n="isEEN"
      @dblclick.alt.native="emit('on-alt-dblclick-logo', $event)"
      @click.native.exact="emit('on-click-logo', $event)"
      @dblclick.shift.native="emit('on-shift-dblclick-logo')"
    />
    <v-divider class="sidebar__divider--up" />

    <nav-items
      :items="items"
      :mini="isMini"
      :expand-tooltip="tooltips.expandTooltip"
      :collapse-tooltip="tooltips.collapseTooltip"
      :white-label="whiteLabel"
      @update:mini="updateMini"
      @subItemClicked="subItemClicked"
      @infiniteHandler="infiniteHandler"
    />

    <support-contact
      v-if="supportEmail && supportPhone"
      :mini="isMini"
      :email="supportEmail"
      :phone="supportPhone"
    />

    <nav-footer
      v-if="showNavFooter"
      :mini="isMini"
      :help-items="helpItems"
      :user-items="userItems"
      :user-name="userName"
      :email="email"
      :white-label="whiteLabel"
      :profile-tooltip="tooltips.profileTooltip"
      @logout="emit('logout')"
      @onusershiftdblclick="emit('onusershiftdblclick')"
      @onusercontroldblclick="emit('onusercontroldblclick')"
      @onusermetadblclick="emit('onusermetadblclick')"
    />
  </v-navigation-drawer>
</template>

<script setup lang="ts">
export type HelpItems = Array<{
  click: () => void;
  icon: string;
  name: string;
}>;
export type UserItems = Array<{
  click: () => void;
  icon: string;
  name: string;
  active?: boolean;
  isLoading?: boolean;
}>;
import Logo from './Logo.vue';
import NavFooter from './NavFooter.vue';
import SupportContact from './SupportContact.vue';
import { computed, onMounted } from 'vue';
import vuetify from '../../../service/useVuetify';
import NavItems from './NavItems.vue';
import { IntersectionStateChanger } from '@/utils/types';

const props = withDefaults(
  defineProps<{
    items: Array<any>;
    helpCenter: string;
    helpItems: HelpItems;
    userItems: UserItems;
    email: string;
    phone: string;
    supportEmail: string;
    supportPhone: string;
    showNavFooter?: boolean;
    tooltips: {
      expandTooltip: string;
      collapseTooltip: string;
      emailTooltip: string;
      phoneTooltip: string;
      profileTooltip: string;
    };
    userName: string;
    logoSmall: string;
    logoSmallWidth?: string;
    logoSmallHeight?: string;
    logoBig: string;
    logoBigWidth?: string;
    logoBigHeight?: string;
    logoMarginSM?: string;
    logoMarginMD?: string;
    logoMarginLG?: string;
    logoMarginXL?: string;
    whiteLabel?: string;
    isMini?: boolean;
    isEEN: boolean;
    isLogoLoading?: boolean;
    altLogoSmall?: string;
    altLogoBig?: string;
    navBlocked?: boolean;
  }>(),
  {
    logoSmallWidth: '40px',
    logoSmallHeight: '40px',
    logoBigWidth: '120px',
    logoBigHeight: '44px',
    logoMarginSM: 'margin: 12px 4px',
    logoMarginMD: 'margin: 12px 8px',
    logoMarginLG: 'margin: 10px 56px 21px 38px',
    logoMarginXL: 'margin: 10px 76px 21px 59px',
    isMini: false,
    isLogoLoading: false,
    showNavFooter: true,
    whiteLabel: 'EEN',
  }
);

const emit = defineEmits<{
  (e: 'on-click-logo', event: Event): void;
  (e: 'on-shift-dblclick-logo'): void;
  (e: 'get-subItemId-clicked', index: number, subItemIndex?: number): void;
  (e: 'updateMini', value: boolean): void;
  (e: 'infiniteHandler', parentIndex: number, state: IntersectionStateChanger): void;
  (e: 'logout'): void;
  (e: 'onusershiftdblclick'): void;
  (e: 'onusercontroldblclick'): void;
  (e: 'onusermetadblclick'): void;
  (e: 'on-alt-dblclick-logo', event: Event): void;
  (e: 'blocked-nav-clicked'): void;
}>();

function infiniteHandler(parentIndex: number, state: IntersectionStateChanger) {
  emit('infiniteHandler', parentIndex, state);
}

function updateMini(value: boolean) {
  emit('updateMini', value);
}

function subItemClicked(index: number, subItemIndex?: number) {
  emit('get-subItemId-clicked', index, subItemIndex);
}

const navigationWidth = computed(() => {
  switch (vuetify.value && vuetify.value.breakpoint.name) {
    case 'sm':
      return 48;
    case 'md':
      return 56;
    case 'lg':
      return 212;
    case 'xl':
      return 252;
    default:
      return 48;
  }
});

onMounted(() => {
  switch (vuetify.value && vuetify.value.breakpoint.name) {
    case 'sm':
    case 'md':
      return updateMini(true);
    case 'lg':
    case 'xl':
    default:
      return updateMini(false);
  }
});
const menuBarBackground = computed(() => {
  const colors = {
    CAPTURE: vuetify.value.theme.themes.light.primary,
    WISENET: vuetify.value.theme.themes.light.primary,
    MOBOTIX: vuetify.value.theme.themes.light.backgrounds,
    EEN: vuetify.value.theme.themes.light.primary,
  };
  return colors[props.whiteLabel ?? 'EEN'];
});

const dividerColor = computed(() => {
  const colors = {
    CAPTURE: vuetify.value.theme.themes.light.primaryLight,
    WISENET: vuetify.value.theme.themes.light.primaryLight,
    MOBOTIX: vuetify.value.theme.themes.light.elements,
    EEN: vuetify.value.theme.themes.light.primaryLight,
  };
  return colors[props.whiteLabel ?? 'EEN'];
});
</script>

<style lang="scss">
@import '../../../assets/styles/main';

.menu-bar {
  display: flex;
  flex-direction: column;
  background-color: v-bind(menuBarBackground) !important;
  overflow: unset !important;
  padding-right: 4px;

  .v-navigation-drawer__content {
    overflow-y: unset !important;
    display: flex;
    flex-direction: column;
  }

  .menu-bar__nav_overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10;
  }

  .menu-bar__extended-area {
    position: absolute;
    width: 16px;
    // subtracting 132px from the bottom, to account for the height of HB timelines and toolbars
    height: calc(100vh - 132px);
    right: -16px;
  }

  &:hover {
    .expand-buttton {
      opacity: 1;
      transition-delay: 0.2s;
      pointer-events: unset;
    }
  }

  .v-divider {
    border-color: v-bind(dividerColor) !important;
    height: 1px;
    border-radius: 0px;
    display: block;

    @media (max-width: $MD) {
      margin: 0px 9px 32px;
    }
    @media (min-width: $MD) {
      margin: 0px 10.5px 32px;
    }
    @media (min-width: $LG) {
      margin: 0px 12px 24px;
    }
  }

  .nav-icon {
    @include width-height(20px, 20px);
    color: white !important;

    @media (max-width: $LG) {
      margin-right: 0;
      @include width-height(24px, 24px);
    }

    @media (max-width: $LG) {
      margin-right: 0 !important;
    }
  }
}
</style>
