<template>
  <v-img
    :key="componentKey"
    :alt="alt"
    :aspect-ratio="aspectRatio"
    :contain="shouldContain"
    :content-class="contentClass"
    :eager="isEager"
    :gradient="gradient"
    :height="height"
    :lazy-src="lazySrc"
    :max-height="maxHeight"
    :max-width="maxWidth"
    :min-height="minHeight"
    :min-width="minWidth"
    :options="options"
    :position="position"
    :sizes="sizes"
    :src="currentImgSrc"
    :srcset="srcset"
    :style="imageStyle"
    :width="width"
    @error="handleImageError"
    @load="handleImageLoad"
    @loadstart="handleLoadstart"
  >
    <template
      v-if="!error"
      #placeholder
    >
      <v-row
        v-if="src"
        class="fill-height ma-0 pa-0"
        align="center"
        justify="center"
      >
        <loading-spinner
          color="grey lighten-5"
          :is-loading="isLoading"
          :size="Number(progressCircleSize)"
        />
      </v-row>
    </template>
    <slot />
    <div
      v-if="shouldShowErrorState"
      class="error-container"
    >
      <div :style="errorContainerStyle">
        <v-icon
          color="negative"
          :size="errorIconSize"
        >
          {{ statusIcon }}
        </v-icon>
      </div>
    </div>
    <div
      v-else-if="shouldShowRetryState"
      class="error-container"
    >
      <div :style="errorContainerStyle">
        <p class="ma-0 mb-3">
          {{ failedToLoadText }}
        </p>
        <button-common
          :text="retryButtonText"
          type="accent"
          @click="handleRetryClick"
        />
      </div>
    </div>
  </v-img>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from 'vue';

import LoadingSpinner from '../../components/loaders/LoadingSpinner.vue';
import ButtonCommon from '../buttons/ButtonCommon.vue';

import ThemeService from '../../service/themes.js';

/**
 * Types
 */

type Props = {
  alt?: string;
  aspectRatio?: string | number;
  contentClass?: string;
  failedToLoadText?: string;
  fallbackSrc?: string | object;
  gradient?: string;
  height?: number | string;
  isDark?: boolean;
  isEager?: boolean;
  lazySrc?: string;
  light?: boolean;
  maxHeight?: number | string;
  maxWidth?: number | string;
  minHeight?: number | string;
  minWidth?: number | string;
  options?: object;
  position?: string;
  progressCircleSize?: number | string;
  retryButtonOffsetY?: number | string;
  retryButtonText?: string;
  shouldContain?: boolean;
  sizes?: string;
  src: string | object;
  srcset?: string;
  width?: number | string;
  shouldShowRetryButton?: boolean; // If no retry button => auto retry
  errorIconSize?: number | string;
  statusIcon?: string;
};

/**
 * Utils
 */

const getImageBackgroundColors = () => {
  const BLACK_COLOR_CODE = '#000';

  return {
    black: BLACK_COLOR_CODE,
    primary: new ThemeService().defaultColors.primary,
  };
};

/**
 * Constants
 */

const MAXIMUM_RETRY_COUNT = 2;

/**
 * Component
 */

const props = withDefaults(defineProps<Props>(), {
  alt: undefined,
  aspectRatio: undefined,
  shouldContain: false,
  contentClass: undefined,
  isEager: false,
  gradient: undefined,
  height: undefined,
  lazySrc: undefined,
  maxHeight: undefined,
  maxWidth: undefined,
  minHeight: undefined,
  minWidth: undefined,
  options: () => ({}),
  position: 'center center',
  sizes: undefined,
  src: undefined,
  fallbackSrc: undefined,
  srcset: undefined,
  width: undefined,
  progressCircleSize: 20,
  retryButtonOffsetY: -18,
  shouldShowRetryButton: true,
  errorIconSize: '48',
  statusIcon: '$icon_unavailable',
  retryButtonText: '',
  failedToLoadText: '',
});

const imageRetryCount = ref<number>(0);

const imageBackgroundColor = ref<string>(getImageBackgroundColors().primary);

const error = ref<Error | null>(null);

const isLoading = ref<boolean>(false);

const componentKey = computed(() => `${Math.random()}-${imageRetryCount.value}-key`);

const imageStyle = computed(() => ({
  backgroundColor: imageBackgroundColor.value,
}));

const shouldShowErrorState = computed(
  () => imageRetryCount.value >= MAXIMUM_RETRY_COUNT && !isLoading.value && error.value
);

const shouldShowRetryState = computed(() => props.shouldShowRetryButton && error.value && !shouldShowErrorState.value);

const currentImgSrc = computed(() => {
  switch (imageRetryCount.value) {
    case 0:
      return props.src;

    case 1:
    case 2:
      return props.fallbackSrc || props.src; // Switch to src if no fallbackSrc

    default:
      return '';
  }
});

const errorContainerStyle = computed(() => ({
  transform: `translateY(${props.retryButtonOffsetY}px)`,
}));

const handleImageLoad = () => {
  imageBackgroundColor.value = props.src ? getImageBackgroundColors().black : getImageBackgroundColors().primary;

  isLoading.value = false;
  error.value = null;
};

const handleImageError = async () => {
  if (!props.shouldShowRetryButton) {
    // If no retry button => call its click handler
    handleRetryClick();

    await nextTick();

    // To skip error states during retry
    if (imageRetryCount.value === MAXIMUM_RETRY_COUNT) {
      return;
    }
  }

  imageBackgroundColor.value = getImageBackgroundColors().primary;

  isLoading.value = false;
  error.value = new Error('Something went wrong!');
};

const handleRetryClick = () => {
  imageRetryCount.value += 1;
  isLoading.value = true;
};

const handleLoadstart = () => {
  error.value = null;
  isLoading.value = true;
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main.scss';

:deep .v-image__image.v-image__image--preload {
  filter: none !important;
}

.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primaryWhite;
  flex-grow: 1;
  @include body-2;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

:deep .v-responsive__content {
  display: flex;
  flex-direction: column;
}
</style>
