<template>
  <div class="sortable-list">
    <div class="sortable-list__container">
      <draggable
        :list="valueList"
        group="items"
        handle=".sortable-list__item--draggable"
        :style="{
          height: heightValue,
          overflow: heightValue ? 'scroll' : 'unset',
        }"
        class="sortable-list__draggable-part"
        animation="200"
        ghost-class="sortable-ghost"
        chosen-class="sortable-chosen"
        drag-class="sortable-drag"
        @start="drag = true"
        @end="drag = false"
      >
        <div
          v-for="(element, index) in valueList"
          :key="element.value"
          class="sortable-list__item"
        >
          <div class="sortable-list__item--draggable">
            <v-icon
              :size="20"
              class="mr-1"
            >
              $icon_drag_and_drop
            </v-icon>
            <div class="sortable-list__item--text">
              {{ element.text }}
            </div>
          </div>
          <div :class="['sortable-list__item--buttons', { 'display--none': drag }]">
            <tooltip
              v-if="index > 0"
              :text="upBtnText"
            >
              <template #content="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <button-common
                    type="clear"
                    :icon-size="20"
                    icon
                    append-icon="$icon_arrow_up"
                    :class="[{ 'mr-2': index !== valueList.length - 1 }]"
                    @click="moveTop(index)"
                  />
                </div>
              </template>
            </tooltip>
            <tooltip
              v-if="index !== valueList.length - 1"
              :text="downBtnText"
            >
              <template #content="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <button-common
                    type="clear"
                    :icon-size="20"
                    icon
                    append-icon="$icon_arrow_down"
                    @click="moveBottom(index)"
                  />
                </div>
              </template>
            </tooltip>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import draggable from 'vuedraggable';
import ButtonCommon from '../buttons/ButtonCommon.vue';
import Tooltip from '../tooltip/Tooltip.vue';
//Type for each Item in array
type ArrayValue = {
  text: '';
  value: '';
};

const props = defineProps<{
  value: ArrayValue[];
  upBtnText: string;
  downBtnText: string;
  height?: string | number;
}>();

const emit = defineEmits<{
  (e: 'input', value: ArrayValue[]): void;
}>();

const drag = ref(false);
const heightValue = computed(() =>
  props.height ? (typeof props.height === 'number' ? props.height + 'px' : props.height) : undefined
);

const valueList = computed({
  get: () => props.value,
  set: (newValue) => {
    if (newValue !== props.value) {
      emit('input', newValue);
    }
  },
});

function moveTop(index: number) {
  const element = valueList.value[index];
  const list = valueList.value.slice();
  list.splice(index, 1);
  valueList.value = [element, ...list];
}

function moveBottom(index: number) {
  const element = valueList.value[index];
  const list = valueList.value.slice();
  list.splice(index, 1);
  valueList.value = [...list, element];
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main';

.sortable-list {
  .display--none {
    display: none !important;
  }
  &__container {
    box-shadow: inset 0 0 0 1px $elements;
    background: $backgrounds;
    border-radius: 4px;
    padding: 12px;
    flex-direction: column;
    margin-bottom: 10px;
  }

  &__draggable-part {
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $secondaryMedium;
      border-left: 4px solid $primaryWhite;
    }
  }

  .sortable-chosen {
    background: $accent !important;
    * {
      color: $primaryWhite;
    }
  }

  .sortable-drag {
    background: transparent !important;
    border-color: transparent !important;
    * {
      color: transparent !important;
    }

    .v-icon {
      display: none;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 12px;
    @include body-2;
    color: $primaryMedium;
    border: 1px solid $elements;
    border-radius: 4px;
    background: $primaryWhite;
    filter: drop-shadow(0px 2px 12px rgba(42, 52, 64, 0.08));
    cursor: pointer;
    user-select: none;
    &:not(:last-child) {
      margin-bottom: 4px;
    }
    &:hover:not(.sortable-chosen) {
      background: $accentClear;
      .sortable-list__item--buttons {
        display: flex;
      }
      .sortable-list__item--draggable {
        width: calc(100% - 80px);
        height: 100%;
      }

      .een-button.isIcon {
        border: 1px solid $secondaryLight !important;
      }
    }

    &.sortable-chosen:not(.sortable-ghost) {
      background: $accent;
      color: $primaryWhite;

      .sortable-list__item--buttons {
        display: none;
      }

      .sortable-list__item--draggable {
        width: 100%;
      }

      span {
        color: $primaryWhite;
      }
    }

    &--draggable {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &--text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--buttons {
      display: none;
    }
  }
}
</style>
