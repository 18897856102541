<template>
  <div class="search-template">
    <div
      v-click-outside="toggleSearchActive"
      class="search-template__searchbar-wrapper"
      @click="searchActive = true"
    >
      <v-icon :size="iconSize">
        {{ icon }}
      </v-icon>
      <input
        v-model="currentSearch"
        :placeholder="placeholder"
        class="search-template__floorplan-search-input"
        @input="$emit('input', $event.target.value)"
      >
      <v-icon
        v-show="currentSearch"
        class="search-template__close-icon"
        size="20"
        @click.stop="currentSearch = ''"
      >
        $icon_close
      </v-icon>
    </div>
    <div
      v-show="searchActive && showCollection"
      class="search-template__search-results"
    >
      <template v-if="filteredCollections.length > 0">
        <template v-for="collection of filteredCollections">
          <button
            v-for="item of collection.items"
            :key="item.id"
            class="search-template__result search-template__positive-result"
            @click="
              () => {
                clickedItem(item, collection);
              }
            "
          >
            <v-icon
              v-if="collection.typeIcon"
              :size="24"
            >
              {{ collection.typeIcon }}
            </v-icon>
            <div class="search-template__result-data">
              <p class="search-template__search-title">
                {{ item.namePrepend }}<strong>{{ item.nameHighlightedText }}</strong>{{ item.nameAppend }}
              </p>
              <p
                v-if="collection.typeName"
                class="search-template__search-category"
              >
                {{ collection.typeName }}
              </p>
            </div>
          </button>
        </template>
      </template>
      <div
        v-else
        class="search-template__no-result"
      >
        <p>
          {{ noResult }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, ComputedRef } from 'vue';
import { Item, Collection } from './types';

const props = withDefaults(
  defineProps<{
    placeholder: string;
    noResult?: string;
    disableFiltering?: boolean;
    collections: Collection[];
    showCollection?: boolean;
    value?: string;
    icon?: string;
    iconSize?: number;
  }>(),
  {
    showCollection: true,
    icon: '$icon_search',
    iconSize: 24,
    noResult: '',
    value: '',
  }
);

const emit = defineEmits<{
  (e: 'itemClicked', item: Item): void;
  (e: 'input', value: string | null): void;
}>();

const searchActive = ref<boolean>(false);

const currentSearch = computed({
  get: () => props.value,
  set: (newValue) => {
    emit('input', newValue);
  },
});

const currentSearchLower = computed(() => currentSearch.value.toLowerCase());

const filteredCollections: ComputedRef<Collection[]> = computed(() =>
  props.collections.flatMap((collection) => {
    if (!collection.items || !collection.items.length) {
      return [];
    }

    if (!currentSearch.value || props.disableFiltering) {
      collection.items.map((item) => {
        item.namePrepend = item.name;
        item.nameHighlightedText = '';
        item.nameAppend = '';
        return item;
      });
      return [collection];
    }

    const found_items = collection.items.filter((item) => {
      const itemName = item.name.toLowerCase();
      const currentSearchLength = currentSearchLower.value.length;
      const foundTextStartIndex = itemName.indexOf(currentSearchLower.value);
      const foundTextEndIndex = foundTextStartIndex + currentSearchLength;

      if (foundTextStartIndex > -1) {
        item.namePrepend = item.name.substring(0, foundTextStartIndex);
        item.nameHighlightedText = item.name.substring(foundTextStartIndex, foundTextEndIndex);
        item.nameAppend = item.name.substring(foundTextEndIndex);
      }

      return foundTextStartIndex > -1;
    });

    if (found_items.length <= 0) {
      return [];
    }

    return [
      {
        ...collection,
        items: found_items,
      },
    ];
  })
);

function clickedItem(item: Item, collection: Collection) {
  currentSearch.value = '';
  collection.callback && collection.callback(item);
  emit('itemClicked', item);
}

function toggleSearchActive() {
  searchActive.value = false;
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/main';
.search-template {
  width: 100%;
  position: relative;
  z-index: 7;
  &__searchbar-wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 4px 12px;
    height: 36px;
    border: 1px solid $elements;
    border-radius: 4px;
    background-color: $backgrounds;

    &:hover {
      box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
    }
    &:focus-within {
      border: 1px solid $accentFixed;
    }
  }
  &__floorplan-search-input {
    @include body-2;
    height: 20px;
    color: $primary;
    border: 0;
    outline: 0;
    width: 100%;
    padding-left: 4px;

    &::placeholder {
      color: #969ca4;
    }
    &:focus::placeholder {
      color: transparent;
    }
    &__close-icon {
      align-self: flex-end;
    }
  }
  &__search-results {
    @include body-2;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    border: 1px solid $elements;
    border-radius: 4px;
    background: $primaryWhite;
    padding: 12px;
    max-height: 378px;
    overflow: auto overlay;
    margin-top: 4px;
    box-shadow: 0px 2px 12px rgba(42, 52, 64, 0.08);

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: white;
    }

    &::-webkit-scrollbar-thumb {
      background: $secondaryMedium;
      border-radius: 8px;
      background-clip: padding-box;
      border: 4px solid white;
    }
  }
  &__result {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
    min-height: 44px;
  }
  &__search-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: $primaryLight;
    strong {
      color: $primary;
    }
  }
  &__search-category {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: $secondary;
  }
  &__result-data {
    flex: 1;
    text-align: left;

    p {
      margin: 0;
    }
  }
  &__floorplan-icon {
    height: 30px;
    width: 30px;
  }
  &__positive-result {
    &:hover {
      background-color: $accentClear;
    }
    &:active {
      background-color: $secondaryLight;
    }
  }
  &__no-result {
    line-height: 36px;
    p {
      margin: 0;
    }
  }
}
</style>
