<template>
  <div class="range-slider">
    <div
      class="range-slider__label subtitle-2 text-center"
      :class="[
        {
          'range-slider__label--disabled': disabled,
          'range-slider__label--text-disabled': isTextNameDisabled,
        },
      ]"
    >
      {{ translations.name }}
    </div>
    <div
      :class="[
        {
          'custom-width': hasTextBox,
        },
        'range-slider__wrapper',
      ]"
    >
      <text-field
        v-if="hasTextBox"
        v-model="minValue"
        class="range-slider__text-field"
        :show-error-icon="false"
        :rules="minValueRules"
        @blur="handleSliderMinValueText"
        @keydown="handleMinEnterChange($event)"
        @input-ref="setMinValueTextRef"
      />
      <v-range-slider
        v-model="range"
        :disabled="disabled"
        :step="step"
        :min="min"
        :max="max"
        :thumb-label="thumbLabel"
        :class="[
          'align-center',
          {
            'custom-slider': hasTextBox,
          },
        ]"
      >
        <template
          v-if="!!thumbLabel"
          #thumb-label="props"
        >
          {{ props.value }}
        </template>
      </v-range-slider>
      <text-field
        v-if="hasTextBox"
        v-model="maxValue"
        class="range-slider__text-field"
        :show-error-icon="false"
        :rules="maxValueRules"
        @blur="handleSliderMaxValueText"
        @keydown="handleMaxEnterChange($event)"
        @input-ref="setMaxValueTextRef"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import TextField from '../../text-inputs/TextField.vue';

const props = withDefaults(
  defineProps<{
    value: number[];
    disabled?: boolean;
    step?: number;
    min?: number;
    max?: number;
    hasTextBox?: boolean;
    thumbLabel?: boolean | string;
    translations: {
      from: string;
      to: string;
      name: string;
    };
    minValueRules?: Array<string | boolean | ((value: any) => boolean | string)>;
    maxValueRules?: Array<string | boolean | ((value: any) => boolean | string)>;
    showErrorIcon?: boolean;
    isTextNameDisabled?: boolean;
  }>(),
  {
    disabled: false,
    step: 1,
    min: 0,
    max: 10,
    hasTextBox: false,
    isTextNameDisabled: false,
  }
);

const emit = defineEmits<{
  (e: 'input', value?: number[]): void;
  (e: 'min', value?: number): void;
  (e: 'max', value?: number): void;
}>();

const minValue = ref<number>(props.value[0]);
const maxValue = ref<number>(props.value[1]);
const minRefValue = ref<HTMLElement>();
const maxRefValue = ref<HTMLElement>();
const prevMaxValue = ref<number>();
const prevMinValue = ref<number>();

function setMinValueTextRef(value: HTMLElement) {
  minRefValue.value = value;
}

function setMaxValueTextRef(value: HTMLElement) {
  maxRefValue.value = value;
}

const range = computed({
  get: () => props.value,
  set: (newValue) => {
    minValue.value = newValue[0];
    maxValue.value = newValue[1];

    emit('input', newValue);
  },
});

function handleMinEnterChange(e: KeyboardEvent) {
  if (e.key === 'Enter') {
    minRefValue.value?.blur();
    handleSliderMinValueText();
  }
}

function handleMaxEnterChange(e: KeyboardEvent) {
  if (e.key === 'Enter') {
    maxRefValue.value?.blur();
    handleSliderMaxValueText();
  }
}

function handleSliderMinValueText() {
  if (!minValue.value) {
    minValue.value = setValidMinValue();
  }

  minValue.value = Number(minValue.value);

  // check for letters, numbers with sign and value less than minimum possible value
  if (isNaN(minValue.value) || minValue.value >= maxValue.value || minValue.value < props.min) {
    minValue.value = setValidMinValue();
    return;
  }

  // setting prevMinValue to revert to old value if user types in invalid number
  prevMinValue.value = minValue.value;
  emit('min', minValue.value);
}

function handleSliderMaxValueText() {
  if (!maxValue.value) {
    maxValue.value = setValidMaxValue();
  }

  maxValue.value = Number(maxValue.value);

  // check for letters, numbers with sign and value less than maximum possible value
  if (isNaN(maxValue.value) || maxValue.value <= minValue.value || maxValue.value > props.max) {
    maxValue.value = setValidMaxValue();
    return;
  }

  // setting prevMaxValue to revert to old value if user types in invalid number
  prevMaxValue.value = maxValue.value;
  emit('max', maxValue.value);
}

function setValidMinValue() {
  return prevMinValue.value ? prevMinValue.value : props.value[0];
}

function setValidMaxValue() {
  return prevMaxValue.value ? prevMaxValue.value : props.value[1];
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/main';

.range-slider {
  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  &__text-field {
    width: 54px !important;
    border-radius: 4px !important;
    color: $secondaryMedium;
    background: 1px solid $elements !important;
  }

  &__label {
    color: $secondary;
    &--disabled {
      color: $secondaryMedium;
    }
    &--text-disabled {
      color: rgba(71, 82, 104, 0.8) !important;
    }
  }
}

.default-settings .custom-width.range-slider {
  &__wrapper {
    max-width: unset !important;
  }
}

.custom-slider {
  min-width: 226px !important;

  :deep {
    .v-input__slot {
      margin-bottom: 0px !important; // to make the slider align centrally
    }

    .v-messages.theme--light {
      display: none !important; // to hide the container below the slider that is not used
    }
  }
}

:deep {
  .v-slider {
    &:hover {
      cursor: pointer;
    }

    .v-slider__track-container {
      height: 8px;

      .v-slider__track-fill {
        background-color: $secondary !important;
      }

      .v-slider__track-background {
        border-radius: 20px;
        background-color: $elements !important;
      }
    }

    .v-slider__thumb-label {
      @include subtitle-1;
      background-color: transparent !important;
      color: $primaryLight;
      transform: translateX(-50%) translateY(40px) !important;

      > * {
        transform: none;
      }
    }

    .v-slider__thumb-container {
      transition: none !important;
      .v-slider__thumb {
        background: $primary !important;
        border: 4px solid $primaryWhite !important;
        box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.36);
        width: 20px;
        height: 20px;
        left: -11px;
        transition: box-shadow 0.2s;

        &:hover {
          cursor: pointer;
        }

        &:before,
        &:after {
          content: none;
        }
      }
      &:hover {
        .v-slider__thumb {
          background: $primaryLight !important;
        }
      }
      &.v-slider__thumb-container--active {
        .v-slider__thumb {
          box-shadow: none;
          background: $secondary !important;
        }

        .v-slider__thumb-label {
          color: $primary;
        }
      }
    }

    &.v-slider--disabled {
      .v-slider__thumb-container {
        .v-slider__thumb {
          background: $secondaryMedium !important;
        }
      }
    }
  }
}
</style>
