<template>
  <v-snackbar
    :value="showing"
    bottom
    color="primary"
    elevation="0"
    max-width="620"
    min-width="480"
    :timeout="timeout"
    transition="slide-y-reverse-transition"
  >
    <div class="snackbar">
      <div class="snackbar__title-icon-wrapper">
        <v-icon
          :color="iconColor"
          aria-hidden="true"
          size="24"
        >
          {{ icon }}
        </v-icon>
        <div class="snackbar__content">
          <span
            v-if="title"
            class="snackbar__content--title"
          >
            {{ title }}
          </span>
          <div class="snackbar__content--main">
            <span v-html="body" />
          </div>
        </div>
      </div>
      <div class="snackbar__actionslot">
        <slot name="action" />
        <v-progress-linear
          v-if="showProgress"
          absolute
          bottom
          color="accent"
          indeterminate
        />
        <button-common
          :icon-size="24"
          append-icon="$icon_close"
          icon
          small
          icon-color="primaryWhite"
          @click.stop="closeNotificationDialog"
        />
      </div>
    </div>
  </v-snackbar>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import ButtonCommon from '../buttons/ButtonCommon.vue';

interface ToastProps {
  body: string;
  icon: string;
  iconColor?: string;
  showing?: boolean;
  showProgress?: boolean;
  title?: string;
  transitionTimeout?: number;
}

const props = withDefaults(defineProps<ToastProps>(), {
  iconColor: 'positive',
  showing: true,
  showProgress: false,
  transitionTimeout: 10000,
});

// we need to subtract 100ms from the transition time out to make the snackbar animation work
const timeout = computed(() => props.transitionTimeout - 100);

const emit = defineEmits<{
  (e: 'closeNotificationDialog'): void;
}>();

const closeNotificationDialog = () => {
  emit('closeNotificationDialog');
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main';

:deep(.v-snack__content) {
  padding: 12px !important;
}

:deep(.v-snack__wrapper) {
  margin: 0 !important;
}

:deep(.v-snack__action) {
  display: none !important;
}

.snackbar {
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: space-between;

  &__title-icon-wrapper {
    display: flex;
    column-gap: 8px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    row-gap: 4px;
    margin-top: 2px;
    color: $primaryWhite;

    &--title {
      @include subtitle-2;
    }

    &--main {
      @include body-2;
      overflow-wrap: break-word;
    }
  }

  &__actionslot {
    @include subtitle-2;
    display: flex;
    gap: 4px;
  }
}
</style>
