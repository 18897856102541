<template>
  <v-skeleton-loader
    class="skeleton-loader"
    :class="skeletonClasses"
    type="image"
    :style="{
      height,
      aspectRatio: skeletonAspectRatio,
    }"
    :max-height="maxHeight"
    :max-width="maxWidth"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface AspectRatio {
  width: number;
  height: number;
}

const props = withDefaults(
  defineProps<{
    type?: string;
    maxHeight?: number | string;
    maxWidth?: number | string;
    fullWidth?: boolean;
    fullHeight?: boolean;
    height?: string;
    aspectRatio?: AspectRatio;
  }>(),
  {
    type: 'text',
    aspectRatio: () => ({
      width: 0,
      height: 0,
    }),
  }
);

const skeletonClasses = computed(() => {
  return {
    'skeleton-loader--full-height': props.fullHeight,
    'skeleton-loader--full-width': props.fullWidth,
    [`${props.type}`]: true,
  };
});

const skeletonAspectRatio = computed(() => {
  const ratio = props.aspectRatio;

  return Object.values(ratio).some((value) => value === 0) ? 'auto' : `${ratio?.width} / ${ratio?.height}`;
});
</script>

<style lang="scss">
@import '../../assets/styles/main';

.skeleton-loader {
  &.text {
    height: 16px;
  }

  &.tooltiped-text {
    height: 22px;
  }

  &.title {
    height: 24px;
    max-width: 220px;

    @media (min-width: $MD) {
      max-width: 466px;
    }
  }
  &.select {
    height: 36px;
  }

  &.image,
  .v-skeleton-loader__image {
    height: 222px;
  }

  &--full-height {
    &.image,
    .v-skeleton-loader__image {
      height: 100%;
    }

    &.text {
      height: 100%;
    }
  }

  &--full-width {
    &.image,
    .v-skeleton-loader__image {
      width: 100%;
    }
  }

  &.big-icon {
    height: 24px;
    width: 24px;
  }

  &.icon {
    height: 16px;
    width: 16px;
  }

  &.input {
    height: 36px;
  }
  &.action-card {
    height: 100px;
  }

  .v-skeleton-loader__image {
    background: $elements !important;
  }
}
</style>
