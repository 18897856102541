<template>
  <div>
    <v-data-table
      :key="key"
      class="eewc-table"
      :class="{
        'eewc-table--dense': denseRows,
        'eewc-table--large-row': rowHeight === 'large',
      }"
      hide-default-header
      hide-default-footer
      item-key="name"
      :page.sync="page"
      :headers="tableHeaders"
      :items="tableData"
      :items-per-page="itemsPerPage"
      :server-items-length="serverItemsLength"
      :search="search"
      :no-results-text="noResultsText"
      :options.sync="tableOptions"
      @current-items="displayedItems = $event"
      @page-count="pageCount = $event"
      @pagination="updatePaginationData"
      @update:options="emitOptions"
    >
      <template #header="args">
        <thead>
          <div :class="`header-selector ${selectedRows.length > 0 ? 'header-selector--block' : ''}`">
            <check-box
              v-if="selectable"
              :value="displayedItems.every((td) => selectedRows.includes(td.id)) && selectedRows.length > 0"
              :indeterminate="
                !displayedItems.every((td) => selectedRows.includes(td.id)) &&
                  displayedItems.some((td) => selectedRows.includes(td.id))
              "
              @change="selectAll"
            />
          </div>
          <tr>
            <th
              v-for="(header, index) in args.props.headers"
              :key="index"
              :class="[
                'eewc-table__header',
                {
                  'eewc-table__header--sortable': header.sortable,
                },
              ]"
            >
              <div v-if="loading && header.text">
                <skeleton
                  class="header-skeleton"
                  :class="header.justify"
                  type="text"
                  :max-width="header.skeletonWidth || '124px'"
                />
              </div>
              <a
                v-else
                class="subtitle-2 d-flex align-center"
                :class="[
                  header.justify && 'justify-' + header.justify,
                  {
                    'table-header--center-text-sortable': header.justify === 'center' && header.sortable,
                  },
                ]"
                @click="header.sortable && args.on.sort(header.value)"
              >
                <tooltipped-text :text="header.text" />
                <v-icon
                  v-if="header.value === args.props.options.sortBy[0] && header.sortable"
                  size="12.5"
                  :class="(args.props.options.sortDesc[0] ? 'rotate180' : '') + ' ml-2'"
                  color="primary"
                >
                  $icon_sort
                </v-icon>
                <v-icon
                  v-else-if="header.sortable"
                  size="12.5"
                  class="table-header__hover-sort-icon ml-2"
                  color="secondaryMedium"
                >
                  $icon_sort
                </v-icon>
              </a>
            </th>
          </tr>
        </thead>
      </template>
      <template #item="{ item }">
        <tr v-if="loading">
          <slot name="skeletonRow" />
          <td
            v-if="hasActions"
            class="table__table-cell--last-cell"
          >
            <skeleton type="icon" />
          </td>
        </tr>

        <!-- This is for the case where the row slot is sent as a tr (of group of tr's), not just td's -->
        <!-- <slot v-else-if="isDataTr" name="row" :item="{ item, expand, isExpanded }"/> -->

        <slot
          v-else-if="item.isChild && expanded.includes(item[parentIdPropertyName])"
          name="expandedRow"
          :item="item"
          :select-row="selectRow"
        />

        <tr
          v-else-if="!item.isChild"
          :class="[
            {
              'expanded-row--color': (isRowClickable && expanded.includes(item.id)) || selectedRows.includes(item.id),
              selected: selectedRows.includes(item.id),
              'table-row-hover': hoverRowId === item.id,
              'table-row-hover__cursor': !isRowClickable,
              'table-row--selected': selectedRow === item.id,
            },
          ]"
          @click="item.children && isRowClickable ? expandChildren(item) : $emit('row-click', item, selectRow)"
        >
          <!-- row slot is consisting of td's -->
          <slot
            name="row"
            :item="item"
            :is-expanded="expanded.includes(item.id)"
            :select-row="selectRow"
          />
          <td
            v-if="item.actions"
            class="table__table-cell--last-cell"
            @click.stop
          >
            <drop-down-menu
              :tooltip="moreTooltip"
              class="pa-2"
              :data-testid="'table-row-action-button-' + item.id"
              :menu-items="item.actions"
              :icon-size="20"
              custom-toggler
              @select="(value) => emitSelect(value, item)"
              @menuCollapsed="(value) => selectRow(value, item.id)"
            >
              <template #toggler="{ on, attrs }">
                <button-common
                  icon
                  :icon-size="20"
                  append-icon="$icon_more_vert"
                  v-bind="attrs"
                  small
                  type="clear"
                  :class="[
                    'dropdown',
                    {
                      'dropdown--active': selectedRow === item.id,
                    },
                  ]"
                  v-on="on"
                />
              </template>
            </drop-down-menu>
          </td>
        </tr>

        <div
          v-if="selectable && item?.isItemSelectable !== false"
          class="row-selector"
          @mouseover="onCheckBoxHover(item.id)"
          @mouseleave="onCheckBoxHover(null)"
        >
          <check-box
            :data-testid="'table-row-check-box-' + item.id"
            :value="selectedRows.includes(item.id)"
            @change="() => selectRowItem(item.id)"
          />
        </div>
      </template>
      <!-- <template #expanded-item="{ item }">
        <slot name="expandedRow" :item="item" :selectRow="selectRow" />
      </template> -->
      <template
        v-if="
          !loading &&
            (props.serverItemsLength || tableData.length) > itemsPerPageOptions[0] &&
            displayedItems.length > 0
        "
        #footer
      >
        <div class="table-footer d-flex">
          <div class="table-footer__page-size d-flex align-baseline">
            <span class="subtitle-2 pa-2 pr-0"> {{ pageSizeText }}: </span>
            <sort-drop-down
              v-if="itemsPerPageMenuOptions"
              v-model="itemsPerPageOption"
              :items="itemsPerPageMenuOptions"
              append-text=""
              @input="(value) => selectEvent(value)"
            />
          </div>
          <div
            v-if="pageCount > 1"
            class="table-footer__pagination ml-auto d-flex align-center subtitle-2"
          >
            <div class="mr-10">
              {{ `${pageStart}-${pageStop} of ${itemsLengthLabel}` }}
            </div>
            <v-btn
              icon
              :ripple="false"
              :disabled="page === 1"
              text
              class="table-footer__page-arrow"
              @click="page = prevPage"
            >
              <tooltip :text="page !== 1 ? previousTooltip : ''">
                <template #content="{ on, attrs }">
                  <v-icon
                    size="15"
                    v-bind="attrs"
                    :color="page !== 1 ? 'primary' : ''"
                    v-on="on"
                  >
                    $icon_arrow_left
                  </v-icon>
                </template>
              </tooltip>
            </v-btn>
            <v-btn
              icon
              :ripple="false"
              :disabled="page === pageCount"
              text
              class="table-footer__page-arrow"
              @click="page = nextPage"
            >
              <tooltip :text="nextTooltip">
                <template #content="{ on, attrs }">
                  <v-icon
                    size="15"
                    v-bind="attrs"
                    :color="page !== pageCount ? 'primary' : ''"
                    v-on="on"
                  >
                    $icon_arrow_right
                  </v-icon>
                </template>
              </tooltip>
            </v-btn>
          </div>
        </div>
      </template>
      <!-- For empty state -->
      <template #no-data>
        <slot name="noData" />
      </template>
      <template
        v-if="displayedItems.length === 0 && !loading && search && $slots.noResult"
        #body
      >
        <slot name="noResult" />
      </template>
    </v-data-table>
  </div>
</template>

<script setup lang="ts">
import Skeleton from './loaders/Skeleton.vue';
import DropDownMenu from './dropdowns/DropDownMenu.vue';
import SortDropDown from './dropdowns/SortDropDown.vue';
import ButtonCommon from './buttons/ButtonCommon.vue';
import TooltippedText from './tooltip/TooltippedText/TooltippedText.vue';
import Tooltip from './tooltip/Tooltip.vue';
import CheckBox from './selection-controls/CheckBox.vue';
import { computed, onBeforeUpdate, ref, watch } from 'vue';
import vuetify from '../service/useVuetify';

type Item = {
  id: number;
  children?: [];
  isChild?: boolean;
  isItemSelectable?: boolean;
};

type PageMenuItem = {
  text: string;
  value: string;
};

type Pagination = {
  pageStart: number;
  pageStop: number;
  itemsLength: number;
  page: number;
  pageCount: number;
};

type Option = {
  page: number;
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
  groupBy: string[];
  groupDesc: boolean[];
  mustSort: boolean;
  multiSort: boolean;
};

const props = withDefaults(
  defineProps<{
    data?: Array<Item>;
    loading?: boolean;
    headers?: Array<{
      skeletonWidth?: string;
      justify?: string;
      text?: string;
      value?: string;
      sortable?: boolean;
    }>;
    hasActions?: boolean;
    previousTooltip?: string;
    nextTooltip?: string;
    selectable?: boolean;
    selectedRows?: Array<any>;
    moreTooltip?: string;
    parentIdPropertyName?: string;
    itemsPerPage?: number;
    serverItemsLength?: number;
    search?: string;
    expandAllRows?: boolean;
    pageSizeText?: string;
    isNoTotalSize?: boolean;
    noResultsText?: string;
    itemsPerPageOptions?: Array<number>;
    rowHeight?: 'small' | 'large';
    isRowClickable?: boolean;
    currentPage?: number;
    selectedRowId?: number | string;
  }>(),
  {
    data: () => [],
    headers: () => [],
    previousTooltip: '',
    nextTooltip: '',
    selectable: false,
    selectedRows: () => [],
    moreTooltip: '',
    parentIdPropertyName: '',
    itemsPerPage: 25,
    pageSizeText: 'Page size',
    expandAllRows: false,
    isNoTotalSize: false,
    itemsPerPageOptions: () => [25, 50, 100],
    rowHeight: 'small',
    isRowClickable: false,
    currentPage: 1,
  }
);

const page = ref(props.currentPage ?? 1);
const refs = ref<string[]>([]);
const pageCount = ref<number>(0);
const itemsPerPage = ref(props.itemsPerPage);
const itemsPerPageOption = ref<PageMenuItem>({
  text: itemsPerPage.value.toString(),
  value: itemsPerPage.value.toString(),
});
const itemsPerPageOptions = ref(props.itemsPerPageOptions);
const pageStart = ref<number | null>(null);
const pageStop = ref<number | null>(null);
const itemsLength = ref<number | null>(null);
const selectedRow = ref<number | string | null>(null);
const hoverRowId = ref<number | null>(null);
const key = ref(0);
const expanded = ref<number[]>([]);
const displayedItems = ref([]);
const tableOptions = ref<Option>({
  page: props.currentPage ?? 1,
  itemsPerPage: itemsPerPage.value,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  mustSort: false,
  multiSort: false,
});

const emit = defineEmits<{
  (e: string, item?: Item): void;
  (e: 'row-select', item: number | number[]): void;
  (e: 'pagination', pagination: Pagination): void;
  (e: 'options', option: Option): void;
}>();

watch(
  () => props.expandAllRows,
  () => {
    props.expandAllRows
      ? props.data.forEach((item) => !item.isChild && expanded.value.push(item.id))
      : (expanded.value = []);
  }
);

watch(
  () => [props.currentPage, props.itemsPerPage],
  (newValue, oldValue) => {
    const pageNumber = newValue[0] !== oldValue[0] ? newValue[0] : null;
    const perPage = newValue[1] !== oldValue[1] ? newValue[1] : null;

    tableOptions.value = {
      ...tableOptions.value,
      page: pageNumber ?? tableOptions.value.page,
      itemsPerPage: perPage ?? tableOptions.value.itemsPerPage,
    };
    page.value = tableOptions.value.page;
    itemsPerPage.value = tableOptions.value.itemsPerPage;

    itemsPerPageOption.value = {
      text: itemsPerPage.value.toString(),
      value: itemsPerPage.value.toString(),
    };
  }
);

watch(
  () => props.selectedRowId,
  () => {
    selectedRow.value = props.selectedRowId ?? null;
  }
);

// Watch for changes to this optional prop to get the latest value from the parent
watch(
  () => props.itemsPerPageOptions,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      itemsPerPageOptions.value = newValue;
    }
  }
);

const prevPage = computed(() => {
  const result = page.value - 1;
  if (result < 1) return 1;
  else return result;
});
const nextPage = computed(() => {
  const result = page.value + 1;
  if (result > pageCount.value!) return result - 1;
  else return result;
});
const denseRows = computed(() => {
  //rows are dense on larger breakpoints
  return vuetify.value.breakpoint.name === 'xl';
});
const itemsPerPageMenuOptions = computed(() => {
  let paginationOption: number[] = [];
  let firstBiggerThanItemsLengthAdded = false;
  const itemsLength = props.serverItemsLength || tableData.value.length;

  if (props.isNoTotalSize) {
    paginationOption = itemsPerPageOptions.value;
  } else {
    itemsPerPageOptions.value.forEach(function (optionValue) {
      if (itemsLength >= optionValue) {
        paginationOption.push(optionValue);
      } else if (itemsLength < optionValue && !firstBiggerThanItemsLengthAdded) {
        paginationOption.push(optionValue);
        firstBiggerThanItemsLengthAdded = true;
      }
    });
  }

  return paginationOption.map((item) => {
    return { text: item.toString(), value: item.toString() };
  });
});
const tableData = computed(() => {
  return props.data.filter(
    (da) => (expanded.value.includes((da as any)[props.parentIdPropertyName]) && da.isChild) || !da.isChild
  );
});
const tableHeaders = computed(() => {
  let headers = props.headers;
  if (props.hasActions) {
    headers = props.headers.concat({ sortable: false });
  }
  return headers;
});
const itemsLengthLabel = computed(() => (props.isNoTotalSize ? `${itemsLength.value}+` : itemsLength.value));
function emitOptions(option: Option) {
  emit('options', option);
}
function onCheckBoxHover(rowId: number | null) {
  hoverRowId.value = rowId;
}
onBeforeUpdate(() => {
  refs.value = [];
});
function selectRow(value: boolean, id: number, rowProp = null) {
  //row becomes grey when having its menu open
  // rowProp could be sent if this method is used in the exapanded items
  if (value) {
    selectedRow.value = id;
  } else {
    if (selectedRow.value === id) selectedRow.value = null;
  }
}
function selectRowItem(item: number) {
  emit('row-select', item);
}
function selectAll(value: boolean) {
  let selectedIds = [];
  const displayedIds = displayedItems.value.map((td) => td.id);

  if (value) {
    selectedIds = props.selectedRows.length
      ? Array.from(new Set([...props.selectedRows, ...displayedIds]))
      : displayedIds;
  } else {
    selectedIds = props.selectedRows.length
      ? props.selectedRows.filter((selectedRow) => !displayedIds.includes(selectedRow))
      : [];
  }

  emit('row-select', selectedIds);
}

function updatePaginationData(pagination: Pagination) {
  pageStart.value = pagination.pageStart + 1;
  pageStop.value = pagination.pageStop;
  itemsLength.value = pagination.itemsLength;
  page.value = pagination.page > pagination.pageCount ? pagination.pageCount : pagination.page;
  emit('pagination', pagination);
  if (props.serverItemsLength !== undefined) {
    expanded.value = [];
  }
}
function expandChildren(item: Item) {
  if (item.children && item.children.length) {
    if (expanded.value.includes(item.id)) {
      // remove this item from the expanded array
      expanded.value = expanded.value.filter((e) => e !== item.id);
    } else {
      // add this item to the expanded array
      expanded.value = expanded.value.concat(item.id);
    }
  }
}
function emitSelect(value: string | number | boolean | object, item: Item) {
  emit(value as string, item);
}
function selectEvent(item: PageMenuItem) {
  itemsPerPageOption.value = item;
  tableOptions.value = {
    ...tableOptions.value,
    itemsPerPage: parseInt(item.value),
    page: 1,
  };
}

function resetToFirstPage() {
  page.value = 1;
}

defineExpose({
  resetToFirstPage,
  expandChildren,
});
</script>
<style lang="scss">
@import '../assets/styles/main';

@mixin cells-padding {
  padding: 0px !important;

  > * {
    left: 12px;
  }

  &:first-child {
    > * {
      left: 32px;
    }
  }
}

@mixin row-hover {
  td {
    background-color: $accentClear;
  }

  td:first-child {
    .v-icon.v-icon {
      color: $accent !important;
    }
  }
}

.table-row-hover {
  &:not(.expanded-row--color):not(.table-row--selected) {
    @include row-hover;
  }

  &__cursor {
    cursor: default !important;
  }
}

.v-data-table--mobile {
  & > .v-data-table__wrapper {
    tbody {
      display: table-row-group !important;
    }
  }
}

.eewc-table {
  border: 1px solid $elements;

  & > .v-data-table__wrapper {
    overflow-x: unset !important;
    overflow-y: unset !important;
  }

  td:not(:last-child),
  th {
    position: relative;

    & > * {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  thead {
    .header-selector {
      display: none;
      position: absolute;
      margin-top: 11px;
      margin-left: 8px;

      .v-input--selection-controls__input {
        height: 20px !important;
        width: 20px !important;
      }

      .v-icon,
      svg {
        height: 12px !important;
        width: 12px !important;
      }

      &:hover,
      &--block {
        display: block;
      }
    }
    tr {
      background-color: $backgrounds;

      th {
        border-bottom: 1px solid $elements !important;
        height: 44px !important;
        @include cells-padding;

        .text-content {
          color: $primaryMedium;
        }

        .table-header__hover-sort-icon {
          visibility: collapse;
        }

        .table-header--center-text-sortable {
          padding-left: 13px;
        }

        &:hover {
          .table-header__hover-sort-icon {
            visibility: visible;
          }
        }

        .header-skeleton {
          &.center {
            margin: auto;
          }
        }
      }

      .eewc-table__header {
        a {
          cursor: default;
        }
      }

      .eewc-table__header--sortable {
        &:hover {
          a {
            cursor: pointer;
          }
          .text-content {
            color: $primary;
          }
        }
      }
    }
    &:hover {
      .header-selector {
        display: block;
      }
    }
  }

  tbody {
    .row-selector {
      display: none;
      position: absolute;
      margin-top: -30px;
      margin-left: 8px;

      .v-input--selection-controls__input {
        height: 20px !important;
        width: 20px !important;
      }

      .v-icon,
      svg {
        height: 12px !important;
        width: 12px !important;
      }

      &:hover {
        display: block;
      }
    }
    tr {
      cursor: pointer;

      td {
        border-bottom: none !important;
        height: 40px !important;
        @include cells-padding;
        @include body-2;
        color: $primary;

        &.table__table-cell--last-cell {
          width: 1%;

          & > div {
            width: 48px;
            text-align: center;
            padding: 4px 12px !important;
          }
        }

        &:not(.table__table-cell--last-cell) {
          .v-icon__component,
          .v-icon__svg {
            width: 24px;
            height: 24px;
          }
        }

        &:first-child {
          .v-icon {
            position: unset !important;
            color: $primary;
          }
        }
      }

      // used nth-of-type to not select the checkbox, as it's in the same level as the row.
      // they're in the same level because we don't want the checkbox to mess with the row contents/cells.
      &:nth-of-type(even) {
        background-color: $backgrounds;
      }

      &:not(.expanded-row--color):not(.table-row--selected):hover {
        @include row-hover;
      }

      &:hover + .row-selector,
      &.selected + .row-selector {
        display: block;
      }

      &.expanded-row--color:not(.table-row--selected) {
        background: $accentLight !important;

        td:first-child {
          .v-icon.v-icon {
            color: $accent !important;
          }
        }
      }
    }
    .v-data-table__empty-wrapper {
      &:hover {
        td {
          background-color: $primaryWhite !important;
        }
      }
    }
  }

  &.eewc-table--large-row tbody tr td {
    height: 68px !important;
  }

  &.eewc-table--dense {
    .header-selector {
      margin-top: 6px;
    }

    tbody {
      .row-selector {
        padding-top: 4px;
      }
    }

    th {
      height: 36px !important;
    }

    td {
      height: 32px !important;
    }
  }

  .table-row--selected {
    td {
      background-color: $elements !important;
    }
  }

  .table-footer {
    background-color: $backgrounds;
    border-radius: 0px 0px 4px 4px;
    border-top: 1px solid $elements;

    &__pagination {
      @include subtitle-2;
      color: $primary;
    }

    &__page-size {
      max-width: 300px;
      margin-left: 16px !important;
      margin-right: 16px !important;
      color: $primary;
    }

    &__page-arrow:before {
      background-color: unset;
    }
  }

  .dropdown {
    &:hover {
      background: $elements !important;
    }

    &--active {
      &:focus {
        background: $secondaryLight !important;
      }
    }
  }
}
</style>
