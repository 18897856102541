<template>
  <DDR
    v-model="transform"
    class="eewc-ddr"
    :resize-handler="['tl', 'tr', 'br', 'bl']"
    :parent="true"
    :accept-ratio="true"
    :rotatable="true"
    @resizestart="onResizeStart"
    @rotatestart="onResizeStart"
    @resize="onResize"
    @rotate="onRotate"
    @drag="onRotate"
    @resizeend="onResizeEnd"
    @rotateend="onRotateEnd"
    @dragend="onDragEnd"
  >
    <img
      ref="image"
      :src="floorplanImg"
      @load="setDRRDimensions"
    >
  </DDR>
</template>

<script setup lang="ts">
// noinspection TypeScriptCheckImport
import DDR from 'yoyoo-ddr';
import 'yoyoo-ddr/dist/yoyoo-ddr.css';
import { ref, Ref } from 'vue';

type nextTransfer = {
  x: number;
  y: number;
  width: number;
  height: number;
  rotation: number;
};

defineProps<{
  floorplanImg: File;
}>();

const emit = defineEmits<{
  (e: 'onChange'): void;
}>();
const transform: Ref<nextTransfer> = ref({
  x: 100,
  y: 100,
  width: 100,
  height: 100,
  rotation: 0,
});
const lastCorrectTransformValue = ref(transform.value);
let initialAspectRatio: number;
const image = ref();

const setSizeBasedOnWidth = (transformCopy: nextTransfer, parentNodeWidth: number, aspectRatio: number) => {
  transformCopy.width = parentNodeWidth * 0.5;
  transformCopy.height = transformCopy.width / aspectRatio;
};
const setSizeBasedOnHeight = (transformCopy: nextTransfer, parentNodeHeight: number, aspectRatio: number) => {
  transformCopy.height = parentNodeHeight * 0.5;
  transformCopy.width = transformCopy.height * aspectRatio;
};

const setDRRDimensions = () => {
  const transformCopy = { ...transform.value };
  const imageWidth = image.value.naturalWidth;
  const imageHeight = image.value.naturalHeight;
  const aspectRatio = (initialAspectRatio = imageWidth / imageHeight);
  const ddrEl = document.querySelector('.eewc-ddr');
  const parentEl = ddrEl && ddrEl.parentElement;

  if (!parentEl) {
    return;
  }

  const parentNodeWidth = parentEl.clientWidth;
  const parentNodeHeight = parentEl.clientHeight;

  if (initialAspectRatio > 1) {
    setSizeBasedOnWidth(transformCopy, parentNodeWidth, aspectRatio);
    if (transformCopy.height > parentNodeHeight) {
      setSizeBasedOnHeight(transformCopy, parentNodeHeight, aspectRatio);
    }
  } else {
    setSizeBasedOnHeight(transformCopy, parentNodeHeight, aspectRatio);
    if (transformCopy.width > parentNodeWidth) {
      setSizeBasedOnWidth(transformCopy, parentNodeWidth, aspectRatio);
    }
  }

  transformCopy.x = (parentNodeWidth - transformCopy.width) / 2;
  transformCopy.y = (parentNodeHeight - transformCopy.height) / 2;

  lastCorrectTransformValue.value = transformCopy;
  transform.value = transformCopy;
};

const setNewDDRSize = () => {
  setTimeout(() => {
    transform.value = {
      ...lastCorrectTransformValue.value,
    };
  }, 0);

  setTimeout(() => {
    const imageWidth = image.value.width;
    const imageHeight = image.value.height;
    transform.value = {
      ...lastCorrectTransformValue.value,
      width: imageWidth,
      height: imageHeight,
    };
  }, 1);
};

const onResizeStart = () => {
  const ddrEl = document.querySelector('.eewc-ddr');
  const parentEl = ddrEl && ddrEl.parentElement;
  const updateDDRSize = () => {
    setNewDDRSize();

    if (parentEl) {
      parentEl.removeEventListener('mouseup', updateDDRSize);
    }
  };

  lastCorrectTransformValue.value = { ...transform.value };

  if (parentEl) {
    parentEl.addEventListener('mouseup', updateDDRSize);
  }
};

const onResize = (event: Event, nextTransfer: nextTransfer) => {
  if (isCorrectImageAndDDRSize(nextTransfer) && isInParent()) {
    lastCorrectTransformValue.value = nextTransfer;
  }
};

const onRotate = (event: Event, nextTransfer: nextTransfer) => {
  if (isInParent()) {
    lastCorrectTransformValue.value = nextTransfer;
  }
};

const onResizeEnd = (event: Event, nextTransfer: nextTransfer) => {
  emit('onChange');

  if (!isCorrectImageAndDDRSize(nextTransfer) || !isInParent()) {
    setNewDDRSize();
  }
};

const onRotateEnd = () => {
  if (!isInParent()) {
    transform.value = lastCorrectTransformValue.value;
  }
};

const onDragEnd = () => {
  emit('onChange');
  if (!isInParent()) {
    transform.value = lastCorrectTransformValue.value;
  }
};

const isInParent = () => {
  const resizeHandlers = document.querySelectorAll('.resize-handler');
  const ddrEl = document.querySelector('.eewc-ddr');
  const parentEl = ddrEl && ddrEl.parentElement;
  const parentElRect = parentEl && parentEl.getBoundingClientRect();
  let isInPArent = true;

  if (!parentElRect) {
    return false;
  }

  resizeHandlers.forEach(function (element) {
    const elementRect = element.getBoundingClientRect();
    const elementRectLeftCenter = elementRect.left + elementRect.width / 2;
    const isInsideOfX = parentElRect.right > elementRectLeftCenter && elementRectLeftCenter > parentElRect.left;

    if (!isInsideOfX) {
      isInPArent = false;
    }

    const elementRectTopCenter = elementRect.top + elementRect.height / 2;
    const isInsideOfY = parentElRect.bottom > elementRectTopCenter && elementRectTopCenter > parentElRect.top;

    if (!isInsideOfY) {
      isInPArent = false;
    }
  });

  return isInPArent;
};

const isCorrectImageAndDDRSize = (nextTransfer: nextTransfer) => {
  const imageWidth = image.value.width;
  const imageHeight = image.value.height;
  const isDifferenceInWidth = Math.abs(nextTransfer.width - imageWidth) < 5;
  const isDifferenceInHeight = Math.abs(nextTransfer.height - imageHeight) < 5;
  const isCorrectImageAndDDRSize = isDifferenceInWidth && isDifferenceInHeight;

  return isCorrectImageAndDDRSize;
};
</script>

<style lang="scss">
@import '../../../src/assets/styles/main';

.eewc-ddr {
  img {
    width: 100%;
    max-width: 100%;
  }
  &.active {
    border: none;
  }

  .resize-handler {
    &.bl,
    &.br,
    &.tl,
    &.tr {
      background: none;
      border-width: 2px solid $primaryLight;
      width: 14px !important;
      height: 14px !important;
    }
  }
}
</style>
