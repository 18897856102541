<template>
  <div
    :key="dateTimeRangePickerKey"
    @click="handleDateTimePickerClick"
  >
    <date-picker
      v-model="selectedRange"
      value-type="timestamp"
      :append-to-body="shouldAppendToBody"
      :class="['mx-dt-picker', isFullWidth && 'mx-dt-picker--full-width']"
      :clearable="false"
      :disabled-date="disabledDate"
      :disabled-time="disabledTime"
      :disabled="isDisabled"
      :editable="false"
      :lang="lang"
      :placeholder="translatedTexts.placeholder"
      :range="isRange"
      :type="type"
      :open.sync="isDatePickerOpen"
      :show-time-panel="isTimePanelOpen"
      :use12h="use12h"
      :popup-class="isRange ? 'mx-dt-picker-popup' : 'mx-dt-picker-popup-no-range'"
      :format="inputFormat"
      @close="handlePanelClose"
      @pick="handlePick"
    >
      <template
        v-if="!shouldShowDateRangePickerIcon"
        #icon-calendar
      >
        <template v-if="shouldFormatDisplayText">
          <v-icon
            :size="24"
            :class="['ml-auto', { 'date-time-picker-icon-active': isDatePickerOpen }]"
            :color="'secondaryMedium'"
          >
            $icon_arrow_down
          </v-icon>
        </template>
        <template v-else>
          {{ null }}
        </template>
      </template>
      <template
        v-if="presetText || shouldFormatDisplayText"
        #input
      >
        <div :class="shouldFormatDisplayText ? 'date-time-picker-display-text' : 'mx-input'">
          {{ presetText ? presetText : formattedDisplayText }}
        </div>
      </template>
      <template
        v-if="isRange"
        #header
      >
        <div
          v-if="isRange"
          class="date-time-container"
        >
          <div>
            <span class="date-time-container__range-text">
              {{ translatedTexts.startDateTimeLabel }}
            </span>
            <div :class="['date-time-inputs-container', { large: type === 'date' }]">
              <date-time-input-box
                v-if="['date', 'datetime'].includes(type)"
                :value="selectedStartDate"
                :has-error="hasErrorInStartDate"
                :max-length="DATE_INPUT_MAX_LENGTH"
                label="startDate"
                @blur="handleDatesOnBlur"
                @input="handleInputValue"
                @focus="handleDateInputOnFocus"
                @keydown="handleDatesKeydown"
              />
              <div
                v-if="hasErrorInStartDate && errorText"
                class="error-popup start-date-popup-position"
              >
                <div class="input-box-container">
                  <v-icon
                    color="red"
                    size="20"
                    @click="handleErrorPopupClose"
                  >
                    $icon_close
                  </v-icon>
                  <span>{{ errorText }}</span>
                  <div class="pointer-arrow date" />
                </div>
              </div>
              <date-time-input-box
                v-if="['datetime', 'time'].includes(type)"
                :value="selectedStartTime"
                :has-error="hasErrorInStartTime"
                :max-length="use12h ? TIME_INPUT_LENGTH_FOR_12_HOUR_FORMAT : TIME_INPUT_LENGTH_FOR_24_HOUR_FORMAT"
                label="startTime"
                @blur="handleTimeOnBlur"
                @input="handleInputValue"
                @focus="handleTimeInputOnFocus"
                @keydown="handleTimeKeydown"
              />
              <div
                v-if="hasErrorInStartTime && errorText"
                class="error-popup start-time-popup-position"
              >
                <div class="input-box-container">
                  <v-icon
                    color="red"
                    size="20"
                    @click="handleErrorPopupClose"
                  >
                    $icon_close
                  </v-icon>
                  <span>{{ errorText }}</span>
                  <div class="pointer-arrow time" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <span class="date-time-container__range-text">
              {{ translatedTexts.endDateTimeLabel }}
            </span>
            <div :class="['date-time-inputs-container', { large: ['date', 'time'].includes(type) }]">
              <date-time-input-box
                v-if="['datetime', 'date'].includes(type)"
                :value="selectedEndDate"
                :has-error="hasErrorInEndDate"
                :max-length="DATE_INPUT_MAX_LENGTH"
                label="endDate"
                @blur="handleDatesOnBlur"
                @input="handleInputValue"
                @focus="handleDateInputOnFocus"
                @keydown="handleDatesKeydown"
              />
              <div
                v-if="hasErrorInEndDate && errorText"
                class="error-popup end-date-popup-position"
              >
                <div class="input-box-container">
                  <v-icon
                    color="red"
                    size="20"
                    @click="handleErrorPopupClose"
                  >
                    $icon_close
                  </v-icon>
                  <span>{{ errorText }}</span>
                  <div class="pointer-arrow date" />
                </div>
              </div>
              <date-time-input-box
                v-if="['datetime', 'time'].includes(type)"
                :value="selectedEndTime"
                :has-error="hasErrorInEndTime"
                :max-length="use12h ? TIME_INPUT_LENGTH_FOR_12_HOUR_FORMAT : TIME_INPUT_LENGTH_FOR_24_HOUR_FORMAT"
                label="endTime"
                @blur="handleTimeOnBlur"
                @input="handleInputValue"
                @focus="handleTimeInputOnFocus"
                @keydown="handleTimeKeydown"
              />
              <div
                v-if="hasErrorInEndTime && errorText"
                class="error-popup end-time-popup-position"
              >
                <div class="input-box-container">
                  <v-icon
                    color="red"
                    size="20"
                    @click="handleErrorPopupClose"
                  >
                    $icon_close
                  </v-icon>
                  <span>{{ errorText }}</span>
                  <div class="pointer-arrow time" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div
          ref="footerContainer"
          class="footer"
        >
          <div
            v-if="isRange"
            class="scroll-options"
          >
            <div :class="['scroll-options__arrow left', { hide: hideLeftArrow }]">
              <v-icon @click="handleScrollToLeft">
                $icon_arrow_left
              </v-icon>
            </div>
            <div
              ref="rangeOptionsContainer"
              :class="['range-option-container', { 'range-option-container__full-width': isRange }]"
              @scroll="handleScroll"
            >
              <div
                v-for="shortcut in shortcuts"
                :key="shortcut.value"
              >
                <button
                  class="range-option"
                  @click.stop="handleSidebarOptionClick(shortcut)"
                >
                  {{ shortcut.text }}
                </button>
              </div>
            </div>
            <div :class="['scroll-options__arrow right', { hide: hideRightArrow }]">
              <v-icon @click="handleScrollToRight">
                $icon_arrow_right
              </v-icon>
            </div>
          </div>
          <div class="footer__buttons">
            <button-common
              v-if="isClearable"
              type="clear"
              :text="translatedTexts.cancelText"
              color="depressed"
              @click.stop="handleClearClick"
            />
            <button-common
              v-else
              type="clear"
              :text="translatedTexts.cancelText"
              color="depressed"
              @click.stop="handleCancelClick"
            />
            <button-common
              type="clear"
              :text="translatedTexts.doneText"
              color="accent"
              @click.stop="handleConfirmClick"
            />
          </div>
        </div>
      </template>
    </date-picker>
  </div>
</template>

<script lang="ts" setup>
import { withDefaults, defineEmits, defineProps, computed, ref, watch, onMounted, watchEffect } from 'vue';
import DatePicker from 'vue2-datepicker';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';

import ButtonCommon from '../buttons/ButtonCommon.vue';
import DateTimeInputBox from './DateTimeInputBox.vue';

import 'vue2-datepicker/index.css';

dayjs.extend(utc);
dayjs.extend(dayjsTimezone);

import {
  ARROW_LEFT_KEY_CODE,
  ARROW_RIGHT_KEY_CODE,
  BACKSPACE_KEY_CODE,
  DATE_FORMAT,
  DATE_INPUT_MAX_LENGTH,
  DELETE_KEY_CODE,
  INDEX_ONE,
  LENGTH_UPTO_HOUR_END,
  LENGTH_UPTO_MINUTES_END,
  LENGTH_UPTO_MINUTES_START,
  LENGTH_UPTO_MONTH_END,
  LENGTH_UPTO_PERIOD_END,
  LENGTH_UPTO_PERIOD_START,
  LENGTH_UPTO_SECONDS_START,
  LENGTH_UPTO_YEAR_END,
  NUMBERS_HYPHEN_REGEX,
  NUMBERS_SEMICOLON_REGEX,
  ONE_HOUR_IN_MILLISECONDS,
  ONE_MINUTE_IN_MS,
  SEVEN_DAYS,
  SINGLE_DIGIT_REGEX,
  THIRTY_DAYS,
  TIME_INPUT_LENGTH,
  TIME_INPUT_LENGTH_FOR_12_HOUR_FORMAT,
  TIME_INPUT_LENGTH_FOR_24_HOUR_FORMAT,
  TWELVE_HOURS,
  TWELVE_HOUR_FORMAT,
  TWELVE_HOUR_PERIOD_REGEX,
  TWELVE_HOUR_TIME_CHARACTERS_REGEX,
  TWENTY_FOUR_HOURS,
  TWENTY_FOUR_HOURS_FORMAT,
  ZERO_INDEX,
} from './constants';

/**
 * Types
 */

type DateInput = number | null | [number, number];
type SidebarOptionValue = 'today' | 'last24Hours' | 'yesterday' | 'last7Days' | 'last30Days';

type SidebarOption = {
  text: string;
  option: SidebarOptionValue;
};

type ErrorMessages = {
  requiredField?: string;
  invalidDate?: string;
  invalidTime?: string;
  dateFormat?: string;
  timeFormat24Hours?: string;
  timeFormat12Hours?: string;
  earlierThanStart?: string;
  laterThanEnd?: string;
};

type TranslatedTexts = {
  cancelText: string;
  doneText: string;
  monthNames: string[];
  placeholder?: string;
  sidebarOptions: SidebarOption[];
  weekDayNames: string[];
  startDateTimeLabel?: string;
  endDateTimeLabel?: string;
  errorMessages?: ErrorMessages;
};

type Props = {
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isRange?: boolean;
  shouldAppendToBody?: boolean;
  type?: 'date' | 'datetime';
  value?: DateInput;
  use12h?: boolean;
  shouldShowDateRangePickerIcon?: boolean;
  shouldFormatDisplayText?: boolean;
  isClearable?: boolean;
  minDate?: Date;
  maxDate?: Date | null;
  timezone?: string;
  translatedTexts: TranslatedTexts;
};

type Shortcut = {
  text: string;
  range: DateInput;
  value: SidebarOptionValue;
};

type Value = {
  value: string;
  label: string;
};

/**
 * Utils
 */

const checkIfTimeIsValid = (value: string) => {
  const TIME_VALIDATION_REGEX =
    /^[0-2]{0,1}$|^(?:[01]?[0-9]|2[0-3])$|^(?:[01]?[0-9]|2[0-3]):$|^(?:[01]?[0-9]|2[0-3]):[0-5]$|^(?:[01]?[0-9]|2[0-3]):[0-5][0-9]$|^(?:[01]?[0-9]|2[0-3]):[0-5][0-9]:$|^(?:[01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5]$|^(?:[01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;

  return TIME_VALIDATION_REGEX.test(value);
};

const checkIfDateIsValid = (value: string) => {
  const DATE_VALIDATION_REGEX =
    /^\d{0,1}$|^\d{2}$|^\d{3}$|^\d{4}$|^\d{4}-$|^\d{4}-\d{1}$|^\d{4}-\d{2}$|^\d{4}-\d{2}-$|^\d{4}-\d{2}-\d{1}$|^\d{4}-\d{2}-\d{2}$/;

  return DATE_VALIDATION_REGEX.test(value);
};

const getLast24HoursDateRange = (): [number, number] => {
  const currentDate =
    new Date().getTime() - localTimezoneOffsetInMilliseconds.value + userTimezoneOffsetInMilliseconds.value;

  const last24HoursStart = currentDate - TWENTY_FOUR_HOURS * ONE_HOUR_IN_MILLISECONDS;

  return [last24HoursStart, currentDate];
};

const getCurrentDayDateRange = (): [number, number] => {
  const localizedTimestamp =
    new Date().getTime() - localTimezoneOffsetInMilliseconds.value + userTimezoneOffsetInMilliseconds.value;

  const startDate = new Date(localizedTimestamp).setHours(0, 0, 0, 0);
  const endDate = localizedTimestamp;

  return [startDate, endDate];
};

const getYesterdayDateRange = (): [number, number] => {
  const yesterdayDateTime =
    new Date().getTime() -
    localTimezoneOffsetInMilliseconds.value +
    userTimezoneOffsetInMilliseconds.value -
    TWENTY_FOUR_HOURS * ONE_HOUR_IN_MILLISECONDS;

  const start = new Date(yesterdayDateTime).setHours(0, 0, 0, 0);
  const end = new Date(yesterdayDateTime).setHours(23, 59, 59, 999);

  return [start, end];
};

const getLast7DaysDateRange = (): [number, number] => {
  const currentDate =
    new Date().getTime() - localTimezoneOffsetInMilliseconds.value + userTimezoneOffsetInMilliseconds.value;
  const last7DaysStart = currentDate - TWENTY_FOUR_HOURS * SEVEN_DAYS * ONE_HOUR_IN_MILLISECONDS;

  return [last7DaysStart, currentDate];
};

const getLast30DaysDateRange = (): [number, number] => {
  const currentDate =
    new Date().getTime() - localTimezoneOffsetInMilliseconds.value + userTimezoneOffsetInMilliseconds.value;
  const last30DaysStart = currentDate - TWENTY_FOUR_HOURS * THIRTY_DAYS * ONE_HOUR_IN_MILLISECONDS;

  return [last30DaysStart, currentDate];
};

const addPadZero = (value: number | string) => (value = value.toString().padStart(2, '0'));

const formatDateToYYYYMMDD = (timestamp: number) => {
  if (!timestamp) return '';

  const year = new Date(timestamp).getFullYear();
  const month = addPadZero(new Date(timestamp).getMonth() + 1);
  const date = addPadZero(new Date(timestamp).getDate());

  return `${year}-${month}-${date}`;
};

const formatDateToHHMMSS = (timestamp: number) => {
  if (!timestamp) return '';

  let hours = new Date(timestamp).getHours();
  const minutes = new Date(timestamp).getMinutes();
  const seconds = new Date(timestamp).getSeconds();

  if (props.use12h) {
    if (hours >= TWELVE_HOURS) {
      hours = hours === TWELVE_HOURS ? hours : hours - TWELVE_HOURS;

      return `${addPadZero(hours)}:${addPadZero(minutes)}:${addPadZero(seconds)} PM`;
    }

    return `${addPadZero(hours ? hours : TWELVE_HOURS)}:${addPadZero(minutes)}:${addPadZero(seconds)} AM`;
  }

  return `${addPadZero(hours)}:${addPadZero(minutes)}:${addPadZero(seconds)}`;
};

const formatDateString = (newValue: string, oldValue: string) => {
  if (newValue !== oldValue && newValue.length > oldValue.length) {
    const hyphenCount = newValue.split('-').length - 1;

    if ([LENGTH_UPTO_YEAR_END, LENGTH_UPTO_MONTH_END].includes(newValue.length) && hyphenCount < 2) newValue += '-';
  }

  return newValue;
};

const formatTimeString = (newValue: string, oldValue: string) => {
  if (newValue !== oldValue && newValue.length > oldValue.length) {
    const colonCount = newValue.split(':').length - 1;
    if (
      [LENGTH_UPTO_HOUR_END, LENGTH_UPTO_MINUTES_END].includes(newValue.length) &&
      colonCount < 2 &&
      SINGLE_DIGIT_REGEX.test(newValue[newValue.length - 1])
    ) {
      newValue += ':';
    }
  }

  return newValue.toUpperCase();
};

const validDate = (dateString: string) => {
  const dateValidationRegex = /^\d{4}-\d{2}-\d{2}$/;

  if (!dateValidationRegex.test(dateString)) return false;

  const dateComponents = dateString.split('-').map((component) => parseInt(component, 10));
  const localTimezoneOffsetInMilliseconds = dayjs(dateString).tz(dayjs.tz.guess()).utcOffset() * ONE_MINUTE_IN_MS;
  const date = new Date(new Date(dateString).getTime() - localTimezoneOffsetInMilliseconds);

  return (
    dateComponents[0] === date.getFullYear() &&
    dateComponents[1] === date.getMonth() + 1 &&
    dateComponents[2] === date.getDate()
  );
};

const isDateTimeRangeAreEqual = (timeRange_one: [number, number], timeRange_two: [number, number]) =>
  timeRange_one[ZERO_INDEX] === timeRange_two[ZERO_INDEX] && timeRange_one[INDEX_ONE] === timeRange_two[INDEX_ONE];

/**
 * Component
 */

const props = withDefaults(defineProps<Props>(), {
  isDisabled: false,
  isFullWidth: false,
  shouldAppendToBody: false,
  type: 'datetime',
  value: null,
  use12h: false,
  shouldShowDateRangePickerIcon: false,
  shouldFormatDisplayText: false,
  maxDate: () => null,
  minDate: () => new Date(1),
  translatedTexts: () => ({
    cancelText: '',
    doneText: '',
    monthNames: [],
    placeholder: '',
    sidebarOptions: [],
    weekDayNames: [],
    startDateTimeLabel: '',
    endDateTimeLabel: '',
    errorMessages: {},
  }),
});

const emit = defineEmits<{
  (e: 'confirm-click', value: DateInput): void;
  (e: 'cancel-click'): void;
  (e: 'preset', value: string): void;
}>();

const isDateInputOnFocus = ref<boolean>(false);
const isTimeInputOnFocus = ref<boolean>(false);
const isDatePickerOpen = ref<boolean>(false);
const isTimePanelOpen = ref<boolean>(false);
const isSidebarOptionClicked = ref<boolean>(false);
const isInputValueValid = ref<boolean>(true);
const focusedInputLabel = ref<string>('');
const errorText = ref<string>('');
const presetText = ref('');
const selectedRange = ref<DateInput>(props.value);
const initialDateRange = ref<DateInput>(props.value);
const isDatePicked = ref<boolean>(false);
const initialSelectedRange = ref<DateInput>(props.value);
const shouldPickEndDate = ref<boolean>(false);

const defaultSelectedRange = computed(() => props.value);
const errorMessages = computed(() => props.translatedTexts.errorMessages);

const dateTimeRangePickerKey = computed(() =>
  Array.isArray(defaultSelectedRange.value)
    ? ` ${defaultSelectedRange.value[0]}-${defaultSelectedRange.value[1]}`
    : defaultSelectedRange.value
);

const selectedStartDate = ref<string>(
  Array.isArray(selectedRange.value) ? formatDateToYYYYMMDD(selectedRange.value[0]) : ''
);
const selectedStartTime = ref<string>(
  Array.isArray(selectedRange.value) ? formatDateToHHMMSS(selectedRange.value[0]) : ''
);
const selectedEndDate = ref<string>(
  Array.isArray(selectedRange.value) ? formatDateToYYYYMMDD(selectedRange.value[1]) : ''
);
const selectedEndTime = ref<string>(
  Array.isArray(selectedRange.value) ? formatDateToHHMMSS(selectedRange.value[1]) : ''
);

const hideLeftArrow = ref<boolean>(true);
const hideRightArrow = ref<boolean>(true);

const rangeOptionsContainer = ref<HTMLElement | null>(null);
const footerContainer = ref<HTMLElement | null>(null);

const inputFormat = computed(() => {
  let format = DATE_FORMAT;

  if (props.type === 'datetime') {
    format = props.use12h ? TWELVE_HOUR_FORMAT : TWENTY_FOUR_HOURS_FORMAT;
  }

  return format;
});

const userTimezoneOffsetInMilliseconds = computed(() => dayjs().tz(props.timezone).utcOffset() * ONE_MINUTE_IN_MS);
const localTimezoneOffsetInMilliseconds = computed(() => dayjs().tz(dayjs.tz.guess()).utcOffset() * ONE_MINUTE_IN_MS);

const dateTimeRangePickerMaxDate = computed(() =>
  props.maxDate
    ? props.maxDate.getTime() - localTimezoneOffsetInMilliseconds.value + userTimezoneOffsetInMilliseconds.value
    : null
);

const dateTimeRangePickerMinDate = computed(
  () => props.minDate.getTime() - localTimezoneOffsetInMilliseconds.value + userTimezoneOffsetInMilliseconds.value
);

const disabledDate = (value: Date) => {
  const maxDate = dateTimeRangePickerMaxDate.value
    ? new Date(dateTimeRangePickerMaxDate.value).setHours(0, 0, 0, 0)
    : null;
  const minDate = new Date(dateTimeRangePickerMinDate.value).setHours(0, 0, 0, 0);

  return (maxDate && maxDate < value.getTime()) || minDate > value.getTime();
};

const disabledTime = (value: Date) => {
  const minDate = new Date(dateTimeRangePickerMinDate.value).setMilliseconds(0);
  const maxDate = dateTimeRangePickerMaxDate.value
    ? new Date(dateTimeRangePickerMaxDate.value).setMilliseconds(0)
    : null;

  return (maxDate && maxDate < value.setMilliseconds(0)) || minDate > value.setMilliseconds(0);
};

const inputRange = computed(() => {
  const startHour = Number(selectedStartTime.value.slice(ZERO_INDEX, LENGTH_UPTO_HOUR_END));
  const endHour = Number(selectedEndTime.value.slice(ZERO_INDEX, LENGTH_UPTO_HOUR_END));

  let selectedStartHour = startHour;
  let selectedEndHour = endHour;

  if (props.use12h) {
    const startTimePeriod = selectedStartTime.value.slice(LENGTH_UPTO_PERIOD_START, LENGTH_UPTO_PERIOD_END);
    const endTimePeriod = selectedEndTime.value.slice(LENGTH_UPTO_PERIOD_START, LENGTH_UPTO_PERIOD_END);

    if (startTimePeriod === 'AM' && selectedStartHour === TWELVE_HOURS) {
      selectedStartHour = selectedStartHour - TWELVE_HOURS;
    } else if (startTimePeriod === 'PM' && selectedStartHour !== TWELVE_HOURS) {
      selectedStartHour += TWELVE_HOURS;
    }

    if (endTimePeriod === 'AM' && selectedEndHour === TWELVE_HOURS) {
      selectedEndHour = selectedEndHour - TWELVE_HOURS;
    } else if (endTimePeriod === 'PM' && selectedEndHour !== TWELVE_HOURS) {
      selectedEndHour += TWELVE_HOURS;
    }
  }

  const localTimezoneOffsetforStartDate =
    dayjs(selectedStartDate.value).tz(dayjs.tz.guess()).utcOffset() * ONE_MINUTE_IN_MS;
  const localTimezoneOffsetforEndDate =
    dayjs(selectedEndDate.value).tz(dayjs.tz.guess()).utcOffset() * ONE_MINUTE_IN_MS;

  const inputRangeStartDate = new Date(
    new Date(selectedStartDate.value).getTime() - localTimezoneOffsetforStartDate
  ).setHours(
    selectedStartHour,
    Number(selectedStartTime.value.slice(LENGTH_UPTO_MINUTES_START, LENGTH_UPTO_MINUTES_END)),
    Number(selectedStartTime.value.slice(LENGTH_UPTO_SECONDS_START, TIME_INPUT_LENGTH))
  );

  const inputRangeEndDate = new Date(
    new Date(selectedEndDate.value).getTime() - localTimezoneOffsetforEndDate
  ).setHours(
    selectedEndHour,
    Number(selectedEndTime.value.slice(LENGTH_UPTO_MINUTES_START, LENGTH_UPTO_MINUTES_END)),
    Number(selectedEndTime.value.slice(LENGTH_UPTO_SECONDS_START, TIME_INPUT_LENGTH))
  );

  let selectedDateRange: DateInput = selectedRange.value;

  if (
    ((props.use12h &&
      TIME_INPUT_LENGTH_FOR_12_HOUR_FORMAT === selectedStartTime.value.length &&
      selectedEndTime.value.length === TIME_INPUT_LENGTH_FOR_12_HOUR_FORMAT &&
      startHour <= TWELVE_HOURS &&
      endHour <= TWELVE_HOURS) ||
      (!props.use12h &&
        TIME_INPUT_LENGTH_FOR_24_HOUR_FORMAT === selectedStartTime.value.length &&
        selectedEndTime.value.length === TIME_INPUT_LENGTH_FOR_24_HOUR_FORMAT)) &&
    DATE_INPUT_MAX_LENGTH === selectedStartDate.value.length &&
    DATE_INPUT_MAX_LENGTH === selectedEndDate.value.length
  ) {
    selectedDateRange =
      inputRangeStartDate <= inputRangeEndDate
        ? [inputRangeStartDate, inputRangeEndDate]
        : [inputRangeStartDate, inputRangeStartDate];
  }

  return selectedDateRange;
});

const hasErrorInStartDate = computed(() => !isInputValueValid.value && focusedInputLabel.value === 'startDate');

const hasErrorInEndDate = computed(() => !isInputValueValid.value && focusedInputLabel.value === 'endDate');

const hasErrorInStartTime = computed(() => !isInputValueValid.value && focusedInputLabel.value === 'startTime');

const hasErrorInEndTime = computed(() => !isInputValueValid.value && focusedInputLabel.value === 'endTime');

const shortcuts = computed(() => {
  return (
    props.isRange
      ? props.translatedTexts.sidebarOptions?.map((sidebarOption) => ({
          text: sidebarOption.text,
          value: sidebarOption.option,
        }))
      : []
  ) as Shortcut[];
});

const lang = computed(() => {
  return {
    formatLocale: {
      firstDayOfWeek: 1,
      months: props.translatedTexts.monthNames,
      weekdaysMin: props.translatedTexts.weekDayNames,
    },
    monthFormat: 'MMMM',
  };
});

const formattedDisplayText = computed(() => {
  return selectedRange.value && Array.isArray(selectedRange.value)
    ? `${dayjs(selectedRange.value[0]).format('MMM D')} - ${dayjs(selectedRange.value[1]).format('MMM D')} @ ${dayjs(
        selectedRange.value[0]
      ).format('h:mma')}  -  ${dayjs(selectedRange.value[1]).format('h:mma')} `
    : '';
});

const getDateRangeBySidebarOption = (option: SidebarOptionValue) => {
  switch (option) {
    case 'today':
      return getCurrentDayDateRange();
    case 'yesterday':
      return getYesterdayDateRange();
    case 'last7Days':
      return getLast7DaysDateRange();
    case 'last30Days':
      return getLast30DaysDateRange();
    case 'last24Hours':
    default:
      return getLast24HoursDateRange();
  }
};

const validateDate = (newValue: string) => {
  let isDateValid = true;
  let errorMessage = '';

  if (!newValue) {
    errorMessage = errorMessages.value?.requiredField ?? '';
    isDateValid = false;
  } else if (newValue.length !== DATE_INPUT_MAX_LENGTH) {
    errorMessage = errorMessages.value?.invalidDate ?? '';
    isDateValid = false;
  } else {
    if (!checkIfDateIsValid(newValue)) {
      errorMessage = errorMessages.value?.dateFormat ?? '';
      isDateValid = false;
    } else if (!validDate(newValue)) {
      errorMessage = errorMessages.value?.invalidDate ?? '';
      isDateValid = false;
    }
  }

  return { errorMessage, isDateValid };
};

watchEffect(() => {
  if (rangeOptionsContainer.value && footerContainer.value) {
    if (footerContainer.value.clientWidth < rangeOptionsContainer.value.scrollWidth) {
      hideRightArrow.value = false;
    }
  }
});

watch(selectedStartDate, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    selectedStartDate.value = formatDateString(newValue, oldValue);

    const { errorMessage, isDateValid } = validateDate(newValue);
    errorText.value = errorMessage;
    isInputValueValid.value = isDateValid;
  }
});

watch(selectedEndDate, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    selectedEndDate.value = formatDateString(newValue, oldValue);

    const { errorMessage, isDateValid } = validateDate(newValue);
    errorText.value = errorMessage;
    isInputValueValid.value = isDateValid;
  }
});

const validateTime = (newValue: string) => {
  let errorMessage = '';
  let isTimeValid = true;
  let time = newValue;

  if (!time) {
    errorMessage = errorMessages.value?.requiredField ?? '';
    isTimeValid = false;
  } else {
    if (props.use12h) {
      time = newValue.slice(ZERO_INDEX, TIME_INPUT_LENGTH);

      const period = newValue.slice(TIME_INPUT_LENGTH, LENGTH_UPTO_PERIOD_END);

      if (!TWELVE_HOUR_PERIOD_REGEX.test(period) || newValue.length < TIME_INPUT_LENGTH_FOR_12_HOUR_FORMAT) {
        errorMessage = errorMessages.value?.timeFormat12Hours ?? '';
        isTimeValid = false;
      }
    }

    if (!checkIfTimeIsValid(time)) {
      if (props.use12h) {
        errorMessage = props.use12h
          ? errorMessages.value?.timeFormat12Hours ?? ''
          : errorMessages.value?.timeFormat24Hours ?? '';
      }
      isTimeValid = false;
    }
  }

  return { errorMessage, isTimeValid };
};

watch(selectedStartTime, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    selectedStartTime.value = formatTimeString(newValue, oldValue);

    const { errorMessage, isTimeValid } = validateTime(newValue);
    errorText.value = errorMessage ?? '';
    isInputValueValid.value = isTimeValid;
  }
});

watch(selectedEndTime, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    selectedEndTime.value = formatTimeString(newValue, oldValue);

    const { errorMessage, isTimeValid } = validateTime(newValue);
    errorText.value = errorMessage ?? '';
    isInputValueValid.value = isTimeValid;
  }
});

watch(inputRange, (newValue, oldValue) => {
  if (
    props.isRange &&
    Array.isArray(newValue) &&
    Array.isArray(oldValue) &&
    !isDateTimeRangeAreEqual(newValue, oldValue)
  ) {
    const newStartDate = newValue[ZERO_INDEX];
    const newEndDate = newValue[INDEX_ONE];
    const oldStartDate = oldValue[ZERO_INDEX];
    let startTime = selectedStartTime.value;
    let endTime = selectedEndTime.value;

    if (props.use12h) {
      startTime = selectedStartTime.value.slice(ZERO_INDEX, TIME_INPUT_LENGTH);
      endTime = selectedEndTime.value.slice(ZERO_INDEX, TIME_INPUT_LENGTH);
    }

    const isInputFullLength =
      selectedStartDate.value.length === DATE_INPUT_MAX_LENGTH &&
      selectedEndDate.value.length === DATE_INPUT_MAX_LENGTH &&
      startTime.length === TIME_INPUT_LENGTH &&
      endTime.length === TIME_INPUT_LENGTH;

    const isNewTimestampValid = isInputValueValid.value && isInputFullLength;
    const isInputOnFocus = isTimeInputOnFocus.value || isDateInputOnFocus.value;
    const isNewPickedTimestampValid = isNewTimestampValid && !isInputOnFocus && isDatePicked.value;
    const isRangeBetweenMaxAndMinDate =
      dateTimeRangePickerMaxDate.value &&
      new Date(newValue[0]).setMilliseconds(0) >= new Date(dateTimeRangePickerMinDate.value).setMilliseconds(0) &&
      newValue[1] <= dateTimeRangePickerMaxDate.value;
    const isStartTimeGreaterThanMinDate =
      !dateTimeRangePickerMaxDate.value &&
      new Date(newValue[0]).setMilliseconds(0) >= new Date(dateTimeRangePickerMinDate.value).setMilliseconds(0);

    if (isNewTimestampValid && isInputOnFocus && (isStartTimeGreaterThanMinDate || isRangeBetweenMaxAndMinDate)) {
      selectedRange.value = [newStartDate, newEndDate];
    }

    if (isNewPickedTimestampValid) {
      if (Array.isArray(selectedRange.value) && selectedRange.value[0] === selectedRange.value[1]) {
        shouldPickEndDate.value = true;
      }

      if (newStartDate < dateTimeRangePickerMinDate.value && newEndDate < dateTimeRangePickerMinDate.value) {
        selectedRange.value = [dateTimeRangePickerMinDate.value, dateTimeRangePickerMinDate.value];
        shouldPickEndDate.value = true;
      } else if (
        dateTimeRangePickerMaxDate.value &&
        newStartDate > dateTimeRangePickerMaxDate.value &&
        newEndDate > dateTimeRangePickerMaxDate.value
      ) {
        selectedRange.value = [dateTimeRangePickerMaxDate.value, dateTimeRangePickerMaxDate.value];
      } else if (shouldPickEndDate.value && oldStartDate < newStartDate) {
        selectedRange.value =
          dateTimeRangePickerMaxDate.value && dateTimeRangePickerMaxDate.value < newEndDate
            ? [oldStartDate, dateTimeRangePickerMaxDate.value]
            : [oldStartDate, newEndDate];
        shouldPickEndDate.value = false;
      } else {
        if (dateTimeRangePickerMaxDate.value && dateTimeRangePickerMaxDate.value < newEndDate) {
          selectedRange.value = [newStartDate, dateTimeRangePickerMaxDate.value];
        } else if (newStartDate < dateTimeRangePickerMinDate.value) {
          selectedRange.value = [dateTimeRangePickerMinDate.value, newEndDate];
        } else {
          selectedRange.value = [newStartDate, newEndDate];
        }

        shouldPickEndDate.value = true;
      }

      isDatePicked.value = false;
    }
  }
});

const setPresetText = (timestampRange: [number, number]) => {
  const localizedTimestamp =
    new Date().getTime() - localTimezoneOffsetInMilliseconds.value + userTimezoneOffsetInMilliseconds.value;
  const today = localizedTimestamp;
  const todayStart = new Date(today).setHours(0, 0, 0, 0);
  const yesterdayStart = todayStart - TWENTY_FOUR_HOURS * ONE_HOUR_IN_MILLISECONDS;
  const timeRangeDifference = timestampRange[1] - timestampRange[0];

  if (
    timeRangeDifference === TWENTY_FOUR_HOURS * ONE_HOUR_IN_MILLISECONDS &&
    localizedTimestamp - timestampRange[1] < ONE_MINUTE_IN_MS
  ) {
    presetText.value =
      props.translatedTexts.sidebarOptions.find((option) => option.option === 'last24Hours')?.text ?? '';
  } else if (
    timeRangeDifference === SEVEN_DAYS * TWENTY_FOUR_HOURS * ONE_HOUR_IN_MILLISECONDS &&
    localizedTimestamp - timestampRange[1] < ONE_MINUTE_IN_MS
  ) {
    presetText.value = props.translatedTexts.sidebarOptions.find((option) => option.option === 'last7Days')?.text ?? '';
  } else if (
    TWENTY_FOUR_HOURS * ONE_HOUR_IN_MILLISECONDS - 1 - timeRangeDifference < ONE_MINUTE_IN_MS &&
    yesterdayStart === timestampRange[0]
  ) {
    presetText.value = props.translatedTexts.sidebarOptions.find((option) => option.option === 'yesterday')?.text ?? '';
  } else if (
    timeRangeDifference === TWENTY_FOUR_HOURS * ONE_HOUR_IN_MILLISECONDS * THIRTY_DAYS &&
    localizedTimestamp - timestampRange[1] < ONE_MINUTE_IN_MS
  ) {
    presetText.value =
      props.translatedTexts.sidebarOptions.find((option) => option.option === 'last30Days')?.text ?? '';
  } else if (timestampRange[0] === todayStart && localizedTimestamp - timestampRange[1] < ONE_MINUTE_IN_MS) {
    presetText.value = props.translatedTexts.sidebarOptions.find((option) => option.option === 'today')?.text ?? '';
  } else {
    presetText.value = '';
  }

  emit('preset', presetText.value);
};

watch(selectedRange, (newValue, oldValue) => {
  if (
    props.isRange &&
    Array.isArray(newValue) &&
    Array.isArray(oldValue) &&
    newValue &&
    !isDateTimeRangeAreEqual(newValue, oldValue)
  ) {
    initialSelectedRange.value = oldValue;
    selectedStartDate.value = formatDateToYYYYMMDD(newValue[0]);
    selectedStartTime.value = formatDateToHHMMSS(newValue[0]);
    selectedEndDate.value = formatDateToYYYYMMDD(newValue[1]);
    selectedEndTime.value = formatDateToHHMMSS(newValue[1]);

    setPresetText(newValue);
  }
});

watch(defaultSelectedRange, (newValue, oldValue) => {
  if (props.isRange && newValue !== oldValue && Array.isArray(newValue) && !isDatePickerOpen.value) {
    selectedRange.value = [
      newValue[0] - localTimezoneOffsetInMilliseconds.value + userTimezoneOffsetInMilliseconds.value,
      newValue[1] - localTimezoneOffsetInMilliseconds.value + userTimezoneOffsetInMilliseconds.value,
    ];

    initialDateRange.value = selectedRange.value;
  }
});

const setInitialDateTimeRange = () => {
  if (props.isRange && Array.isArray(selectedRange.value)) {
    selectedRange.value = [
      selectedRange.value[0] - localTimezoneOffsetInMilliseconds.value + userTimezoneOffsetInMilliseconds.value,
      selectedRange.value[1] - localTimezoneOffsetInMilliseconds.value + userTimezoneOffsetInMilliseconds.value,
    ];

    initialDateRange.value = selectedRange.value;
    setPresetText(selectedRange.value);
  } else if (!props.isRange && !Array.isArray(selectedRange.value) && selectedRange.value) {
    selectedRange.value = selectedRange.value - localTimezoneOffsetInMilliseconds.value;
  }
};

watch(
  () => props.timezone,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      setInitialDateTimeRange();
    }
  }
);

const handleCancelClick = () => {
  isDatePickerOpen.value = false;
  isTimePanelOpen.value = false;
  selectedRange.value = initialDateRange.value;

  emit('cancel-click');
};

const handlePanelClose = () => {
  if (props.isRange) {
    handleDatesOnBlur();
    handleTimeOnBlur();
    handleCancelClick();
  } else {
    handleConfirmClick();
  }
};

const handleClearClick = () => {
  isDatePickerOpen.value = false;
  selectedRange.value = null;
  initialDateRange.value = null;
  emit('confirm-click', null);
};

const handleDatesOnBlur = () => {
  if (Array.isArray(selectedRange.value)) {
    selectedStartDate.value = formatDateToYYYYMMDD(selectedRange.value[0]);
    selectedEndDate.value = formatDateToYYYYMMDD(selectedRange.value[1]);
  }

  isInputValueValid.value = true;
  isDateInputOnFocus.value = false;
  errorText.value = '';
  focusedInputLabel.value = '';
};

const handleTimeOnBlur = () => {
  if (Array.isArray(selectedRange.value)) {
    selectedStartTime.value = formatDateToHHMMSS(selectedRange.value[0]);
    selectedEndTime.value = formatDateToHHMMSS(selectedRange.value[1]);
  }

  isInputValueValid.value = true;
  isTimeInputOnFocus.value = false;
  errorText.value = '';
  focusedInputLabel.value = '';
};

const handlePick = (timestamp: Date) => {
  isDatePickerOpen.value = true;
  const formattedPickedValue = formatDateToYYYYMMDD(timestamp.getTime());
  selectedStartDate.value = formattedPickedValue;
  selectedEndDate.value = formattedPickedValue;
  isDatePicked.value = true;
};

const handleInputValue = (inputValue: Value) => {
  switch (inputValue.label) {
    case 'startDate':
      selectedStartDate.value = inputValue.value;
      break;
    case 'startTime':
      selectedStartTime.value = inputValue.value;
      break;
    case 'endDate':
      selectedEndDate.value = inputValue.value;
      break;
    case 'endTime':
      selectedEndTime.value = inputValue.value;
      break;
  }
};

const handleSidebarOptionClick = (shortcut: Shortcut) => {
  selectedRange.value = getDateRangeBySidebarOption(shortcut.value);
  isSidebarOptionClicked.value = true;
  isDatePickerOpen.value = false;
  isTimePanelOpen.value = false;

  const range: [number, number] = [
    selectedRange.value[0] + localTimezoneOffsetInMilliseconds.value - userTimezoneOffsetInMilliseconds.value,
    selectedRange.value[1] + localTimezoneOffsetInMilliseconds.value - userTimezoneOffsetInMilliseconds.value,
  ];

  presetText.value = shortcut.text;

  initialDateRange.value = selectedRange.value;
  emit('preset', presetText.value);
  emit('confirm-click', range);
};

const handleTimeInputOnFocus = (value: string) => {
  focusedInputLabel.value = value;
  isTimeInputOnFocus.value = true;
  isTimePanelOpen.value = true;
};

const handleDateInputOnFocus = (value: string) => {
  focusedInputLabel.value = value;
  isDateInputOnFocus.value = true;
  isTimePanelOpen.value = false;
};

const handleConfirmClick = () => {
  isDatePickerOpen.value = false;
  isTimePanelOpen.value = false;

  if (props.isRange && Array.isArray(selectedRange.value)) {
    const range: [number, number] = [
      selectedRange.value[0] + localTimezoneOffsetInMilliseconds.value - userTimezoneOffsetInMilliseconds.value,
      selectedRange.value[1] + localTimezoneOffsetInMilliseconds.value - userTimezoneOffsetInMilliseconds.value,
    ];

    emit('confirm-click', range);
  } else {
    emit('confirm-click', selectedRange.value);
  }

  initialDateRange.value = selectedRange.value;
};

const handleErrorPopupClose = () => {
  errorText.value = '';
};

const handleScrollToLeft = () => {
  if (rangeOptionsContainer.value) {
    rangeOptionsContainer.value.scrollLeft -= 50;
  }
};

const handleScrollToRight = () => {
  if (rangeOptionsContainer.value) {
    rangeOptionsContainer.value.scrollLeft += 50;
  }
};

const handleScroll = () => {
  if (rangeOptionsContainer.value) {
    if (rangeOptionsContainer.value.scrollLeft === 0) {
      hideLeftArrow.value = true;
    } else if (
      rangeOptionsContainer.value.scrollLeft + rangeOptionsContainer.value.clientWidth ===
      rangeOptionsContainer.value.scrollWidth
    ) {
      hideRightArrow.value = true;
    } else {
      hideLeftArrow.value = false;
      hideRightArrow.value = false;
    }
  }
};

const handleDateTimePickerClick = () => {
  isDatePickerOpen.value = true;
};

const handleDatesKeydown = (event: KeyboardEvent) => {
  if (
    !(
      NUMBERS_HYPHEN_REGEX.test(event.key) ||
      [BACKSPACE_KEY_CODE, ARROW_LEFT_KEY_CODE, ARROW_RIGHT_KEY_CODE, DELETE_KEY_CODE].includes(event.keyCode)
    )
  ) {
    event.preventDefault();
  }
};

const handleTimeKeydown = (event: KeyboardEvent) => {
  const validCharactersRegex = props.use12h ? TWELVE_HOUR_TIME_CHARACTERS_REGEX : NUMBERS_SEMICOLON_REGEX;
  if (
    !(
      validCharactersRegex.test(event.key) ||
      [BACKSPACE_KEY_CODE, ARROW_LEFT_KEY_CODE, ARROW_RIGHT_KEY_CODE, DELETE_KEY_CODE].includes(event.keyCode)
    )
  ) {
    event.preventDefault();
  }
};

onMounted(() => {
  setInitialDateTimeRange();
});
</script>

<style lang="scss">
@import '../../assets/styles/main';

@mixin arrow-btn {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background: $elements;
  }

  &:active {
    background: $secondaryLight;
  }

  &:focus-visible {
    border: 1px solid $primary;
  }
}

@mixin arrow-btn-dark {
  &:hover {
    background: $primaryLight;
  }

  &:active {
    background: $secondaryMedium;
  }

  &:focus-visible {
    background: none;
    border: 1px solid $primaryWhite;
  }
}

@mixin calender-cell {
  .mx-date-row {
    .cell {
      &.active {
        background: $accent !important;
        color: $primaryWhite !important;
        position: relative;

        &::before {
          background: $accentLight;
          position: absolute;
          z-index: -1;
          pointer-events: none;
          content: '';
          display: inline-block;
          width: 33px;
          height: 32px;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

// Custom pop-up class
.mx-dt-picker-popup {
  margin-top: 4px !important;
  border-radius: 4px;
  border: 1px solid $elements;
  box-shadow: 2px 4px 12px rgba(42, 52, 64, 0.08);
  padding: 4px 0 4px 0px;
  font-family: Roboto, sans-serif;
  transform: none;
  background: $backgrounds !important;

  .mx-datepicker-popup-range {
    .mx-calendar {
      width: 230px !important;
    }
  }

  .mx-time {
    background: $backgrounds !important;
  }

  .mx-icon-right:before,
  .mx-icon-left:before {
    border-width: 3px 0 0 3px;
    border-radius: 0;
  }

  .mx-datepicker-header {
    border-bottom: 0px;
    padding: 0;
  }

  .mx-calendar {
    width: 233px;
  }

  .mx-datepicker-footer {
    display: flex;
    align-items: baseline;
    justify-content: end;
    border-top: 1px solid $elements;
    margin: 0;
    padding: 6px 0;

    .mx-datepicker-btn-confirm {
      padding: 8px 16px;
      border-radius: 4px;

      &:hover {
        background: $accentClear;
      }

      &:focus-visible {
        background: none;
        border: 1px solid $primary;
      }

      &:active {
        background: $accentLight;
      }
    }
  }

  .mx-btn-text {
    @include subtitle-2;
    color: $primary;
  }

  .mx-calendar-panel-month,
  .mx-calendar-panel-year {
    padding: 8px 4px 4px 4px !important;

    .mx-calendar-header-label {
      pointer-events: none;
    }

    .mx-calendar-header {
      color: $primary;
      @include subtitle-2;
      text-align: center;
    }

    .mx-btn-icon-double-left,
    .mx-btn-icon-double-right {
      padding: 0;
    }

    .mx-icon-double-right {
      @include arrow-btn;
      padding-right: 2px;
      padding-top: 1px;

      &:before {
        left: 6px;
        border-width: 3px 0 0 3px;
        border-radius: 0;
      }

      &:after {
        left: -4px;
      }
    }

    .mx-icon-double-left {
      @include arrow-btn;
      padding-top: 1px;

      &:before {
        left: 5px;
        border-width: 3px 0 0 3px;
        border-radius: 0;
      }

      &:after {
        left: -5px;
      }
    }

    .mx-calendar-header-label {
      button {
        margin-top: 4px;
      }
    }

    .mx-table-year,
    .mx-table-month {
      -webkit-border-horizontal-spacing: 12px;
      -webkit-border-vertical-spacing: 23px;
      @include body-2;
      text-align: center;
      color: $primary;

      .cell {
        &:hover {
          color: $primary;
          background: $accentClear;
          border-radius: 4px;
        }
      }

      .active {
        background: $accent !important;
        border-radius: 4px;

        &:hover {
          color: $primaryWhite;
        }
      }
    }

    .mx-table-month {
      width: 180px;
      margin: auto;
      border-spacing: 0;
      -webkit-border-vertical-spacing: 23px;
    }

    .mx-table-year {
      width: 180px;
      margin: auto;
      border-spacing: 0;
      -webkit-border-vertical-spacing: 14px;
      -webkit-border-horizontal-spacing: 20px;
    }
  }

  .mx-calendar-panel-date {
    padding: 8px 4px 4px 4px !important;

    .mx-icon-double-right,
    .mx-icon-double-left {
      display: none;
    }

    .mx-btn-current-year {
      pointer-events: none;
    }

    .mx-btn-current-month {
      margin-top: 4px;
    }

    .mx-btn-icon-left,
    .mx-btn-icon-right {
      @include arrow-btn;
    }

    .mx-btn-text:hover {
      color: $primary;
    }

    .mx-calendar-header {
      color: $primary;
      @include subtitle-2;
      text-align: center;
    }
  }

  .mx-datepicker-footer .mx-btn {
    border: none;
    @include subtitle-2;
    color: $accent;
  }

  .mx-table {
    width: 222px;
  }

  .mx-table-date th {
    color: $secondaryMedium;
    @include subtitle-2;
    text-align: center;
  }

  .mx-date-row {
    .cell {
      @include body-2;
      color: $primary;
      border-radius: 50%;
      text-align: center;

      &.not-current-month {
        background: unset !important;
        pointer-events: none;
        color: $secondaryMedium !important;
      }

      &.today {
        @include subtitle-2;
        text-align: center;
        color: $accent;
      }

      &.active {
        color: $primaryWhite;
        background-color: $accent;

        &:hover {
          background-color: $accent;
          color: $primaryWhite;
        }

        &::before {
          border-radius: 33px 0 0 33px;
          background: $accentLight;
        }
      }

      &:hover {
        background: $accentLight;
        color: $primary;
      }

      &.in-range {
        background: $accentLight;
        color: $primary;
        border-radius: 0;
      }

      &.hover-in-range {
        background: none;
        border-radius: 0;
        border-top: 1px dashed $elements;
        border-bottom: 1px dashed $elements;
        color: $primary;
      }

      &.disabled {
        background: unset;
        color: $secondaryLight;
        cursor: auto;
      }
    }

    .in-range ~ .active {
      &::before {
        border-radius: 0 33px 33px 0;
        background: $accentLight;
      }
    }
  }

  &__dark {
    background-color: $primary !important;
    border: 1px solid $primaryMedium !important;
    box-shadow: 2px 4px 12px rgba(42, 52, 64, 0.08);

    .mx-calendar-panel-date {
      .mx-btn-text:hover {
        color: $primaryWhite;
      }

      .mx-calendar-header {
        * {
          color: $primaryWhite;
        }

        .mx-btn-icon-left,
        .mx-btn-icon-right {
          @include arrow-btn-dark;
        }
      }

      .mx-table-date {
        .not-current-month {
          color: $secondary !important;
        }

        .cell {
          color: $primaryWhite;

          &.today:not(.active) {
            color: $accent;

            &:active {
              color: $primaryWhite;
            }
          }

          &.disabled {
            color: $secondary;
            pointer-events: none;
          }

          &:hover:not(.disabled) {
            background: $accentLight;
            color: $primary;
          }

          &:active {
            background: $accent !important;
          }

          &.active {
            background: $accentDark !important;

            &:hover {
              background: $accentDark !important;
              color: $primaryWhite;
            }
          }
        }
      }
    }

    .mx-calendar-panel-month {
      .mx-calendar-header {
        * {
          color: $primaryWhite;
        }

        .mx-icon-double-right,
        .mx-icon-double-left {
          @include arrow-btn-dark;
        }
      }

      .mx-table-month {
        .cell {
          color: $primaryWhite;

          &:hover {
            background: $accentDark;
            border-radius: 4px;
            color: $primaryWhite;
          }

          &.active:hover {
            background: $accent;
          }
        }
      }
    }

    .mx-datepicker-footer {
      border-color: $primaryMedium;

      .clearBtn {
        color: $primaryWhite;

        &:hover {
          background: $primaryLight !important;
        }

        &:focus-visible {
          background: none;
          border: 1px solid $primaryWhite;
        }

        &:active {
          background: $secondaryMedium !important;
        }
      }

      .mx-datepicker-btn-confirm {
        &:hover {
          background: $primaryLight;
        }

        &:focus-visible {
          background: none;
          border: 1px solid $accent;
        }

        &:active {
          background: $accentLight;
        }
      }
    }
  }
}

// Custom picker class
.mx-dt-picker {
  .mx-input {
    text-align: left !important;
    background: $backgrounds !important;
  }

  .mx-input-wrapper {
    .datepicker-input-field {
      .v-input__slot {
        & fieldset {
          color: $accent !important;
          border: 1px solid $accent !important;
        }
      }

      &__dark {
        .v-input .v-input__slot {
          fieldset {
            background: unset;
            backdrop-filter: unset;
            border: unset;
          }

          &:focus-within {
            & fieldset {
              color: $accent !important;
              border: 1px solid $accent !important;
            }
          }

          input,
          svg {
            color: $primaryWhite !important;
          }
          background: rgba(33, 42, 52, 0.72);
          backdrop-filter: blur(2px);
        }
      }
    }
  }

  .input-placeholder-text {
    position: absolute;
    top: 8px;
    z-index: 2;
    color: $secondaryMedium;
    @include body-2;
    padding-left: 10px;
    margin-left: 2px;
    background-color: $backgrounds;
  }

  &--full-width {
    width: 100%;
  }
}

.mx-time-header {
  display: none !important;
}

// Custom full width class
.mx-dt-picker--full-width {
  width: 100% !important;
}

.date-time-container {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;

  &__range-text {
    @include subtitle-3;
    padding-left: 16px;
    color: $primary;
  }
}

.date-time-inputs-container {
  display: flex;
  gap: 2px;
  padding: 3px 8px 16px 8px;
  position: relative;

  &.large {
    width: 269px;
  }
}

.border-line {
  width: 1px;
  background-color: $elements;
}

.error-popup {
  @include body-3;
  position: absolute;
  bottom: 50px;
  right: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  border: 1px solid $elements;
  border-bottom: 0px;
  border-radius: 4px;
  padding: 8px 12px 8px 8px;
  box-shadow: 0px 2px 12px 0px $secondaryMedium;
  background-color: $primaryWhite;

  .v-icon__component {
    fill: $negative;
  }
}

.start-date-popup-position {
  bottom: 60px;
  left: 0px;
}

.start-time-popup-position {
  bottom: 60px;
  right: 0px;
}

.end-date-popup-position {
  bottom: 60px;
  left: 0px;
}

.end-time-popup-position {
  bottom: 60px;
  right: 0px;
}

.input-box-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .error-text {
    @include body-3;
  }
}

.pointer-arrow {
  position: absolute;
  top: 28px;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-bottom: 0;
  border-top: 7px solid $primaryWhite;

  &.date {
    left: 50px;
  }

  &.time {
    right: 50px;
  }
}

.range-option-container {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: nowrap;
  padding-bottom: 12px;
  padding-inline: 16px;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &__full-width {
    width: 536px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__buttons {
    padding: 6px 4px 0px 4px;
  }
}

.range-option {
  @include body-2;
  width: max-content;
  padding: 2px 12px;
  border-radius: 40px;
  color: $primaryWhite;
  background: $primary;
  text-align: center;
}

.scroll-options {
  display: flex;
  border-bottom: 1px solid $elements;
  position: relative;

  &__arrow {
    position: absolute;
    top: 0;
    width: 24px;
    height: 26px;
    background-color: $backgrounds;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.hide {
      display: none;
    }
  }
}

.mx-datepicker-main {
  &.mx-datepicker-popup {
    &.mx-dt-picker-popup {
      z-index: 8 !important;

      .mx-range-wrapper {
        justify-content: center;

        .mx-calendar-panel-date {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0px !important;

          .mx-calendar-header {
            width: 100%;
            padding: 0px 24px;
          }

          .mx-calendar-content {
            .mx-table {
              width: 224px !important;
            }
          }
        }
      }

      .mx-datepicker-footer {
        width: 100%;
        margin: 0px;
        justify-content: center;
        border-top: 0;
        padding-top: 16px;
        padding-bottom: 3px;
      }

      .mx-time-item {
        @include body-2;
        text-align: center;
        line-height: 32px;
        color: $primary;

        &.active {
          color: $primaryWhite;
          background-color: $accent;
        }

        &.disabled {
          color: $secondaryLight;
          background-color: $backgrounds;
        }
      }

      .mx-scrollbar-track {
        display: none;
      }

      [data-type='hour'] {
        padding-left: 4px;
        .active {
          border-radius: 4px 0px 0px 4px;
        }
      }

      [data-type='second'] {
        padding-right: 4px;
        .active {
          border-radius: 0px 4px 4px 0px;
        }
      }

      [data-type='ampm'] {
        padding: 0px 4px;
        .active {
          border-radius: 4px;
        }
      }
    }
  }
}

.mx-datepicker.mx-datepicker-range.mx-dt-picker {
  .mx-input-wrapper {
    .mx-input {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 36px;
      border: 1px solid $elements;
      color: $primary;
      box-shadow: none;
      padding: 8px 8px 8px 12px;

      &:hover {
        box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
      }
    }
  }
}

.date-time-picker-icon-active {
  transform: rotate(180deg);
}

.date-time-picker-display-text {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 36px;
  padding: 8px 40px 8px 12px;
  white-space: nowrap;
}
</style>
