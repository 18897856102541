<template>
  <v-select
    ref="selectElementRef"
    v-model="selectedItem"
    :items="items"
    :append-icon="isError || error ? '$icon_attention' : appendIcon"
    filled
    dense
    :height="height"
    :solo="solo"
    :flat="flat"
    :outlined="outlined"
    class="select-box"
    :class="{
      'select-box--disabled': disabled,
      'select-box--none-label': selectedItem?.value === '',
      'select-box--clear': clear,
    }"
    :disabled="disabled"
    item-color="white"
    :menu-props="{
      bottom: true,
      offsetY: true,
      contentClass: lastNegative ? `warningBox ${contentClass}` : contentClass,
      closeOnClick: closeOnClick,
    }"
    :return-object="returnObject"
    :hide-details="hideDetails"
    :placeholder="placeholder"
    :rules="[...rules]"
    :item-text="itemText"
    :item-value="itemValue"
    :error="error"
    :error-messages="errorMessages"
    :loading="loading ? loaderColor : false"
    :attach="isAttached"
    @blur="handleBlur"
  >
    <template
      v-if="selectionSlot"
      #selection="{ item }"
    >
      <slot
        name="selection"
        :item="item"
      />
    </template>
    <template
      v-if="itemSlot"
      #item="{ item }"
    >
      <slot
        name="item"
        :item="item"
      />
    </template>
  </v-select>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue';

type Item = {
  text: string | number | object;
  value: string | number | object;
  disabled: boolean;
  divider: boolean;
  header: string;
};

type Props = {
  disabled?: boolean;
  value?: any;
  items: (Item | string)[];
  lastNegative?: boolean;
  itemSlot?: boolean;
  height?: string | number;
  selectionSlot?: boolean;
  solo?: boolean;
  flat?: boolean;
  outlined?: boolean;
  placeholder?: string;
  rules?: Array<string | boolean | ((value: object) => boolean | string)>;
  hideDetails?: boolean;
  closeOnClick?: boolean;
  itemText?: string;
  itemValue?: string;
  error?: boolean;
  errorMessages?: string | string[];
  appendIcon?: string;
  returnObject?: boolean;
  contentClass?: string;
  loading?: boolean | string;
  isAttached?: boolean;
  autoPrefill?: boolean;
  clear?: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  outlined: true,
  height: 36,
  hideDetails: true,
  closeOnClick: true,
  appendIcon: '$icon_arrow_down',
  error: false,
  errorMessages: '',
  returnObject: true,
  loading: false,
  itemText: 'text',
  itemValue: 'value',
  rules: () => [],
  // this should be default otherwise select-box will break in many places
  isAttached: false,
  //set the value to the 1st item if there's only one element in items and autoPrefill is true.
  autoPrefill: false,
  clear: false,
});

const emit = defineEmits<{
  (e: 'input', value: any): void;
  (e: 'blur'): void;
}>();

const selectElementRef = ref();

const selectedItem = computed({
  get: () => props.value,
  set: (newValue) => {
    if (newValue !== props.value) {
      emit('input', newValue);
    }
  },
});

const loaderColor = computed(() => (isError.value || props.error ? 'negative' : 'secondaryMedium'));

const isError = computed(() => selectElementRef.value?.hasError && selectElementRef.value?.shouldValidate);

watch(
  () => props.items?.length,
  () => {
    if (props.items?.length === 1 && props.autoPrefill) {
      selectedItem.value = props.items[0];
    }
  },
  { immediate: true }
);

const handleBlur = () => {
  emit('blur');
};
</script>

<style lang="scss">
@import '../../assets/styles/main';

.v-select.error--text.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none !important;
}

.select-box .v-input__append-inner {
  margin-top: unset !important;
  align-self: center !important;
}

.select-box .v-input__slot {
  min-height: 36px !important;
  z-index: 2;

  & fieldset {
    background: $backgrounds;
    color: $elements !important;
    border: 1px solid;
  }

  & label {
    @include body-2;
  }

  &:hover {
    box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
    border-radius: 4px;
  }

  .v-list-item--active {
    background: $accentFixed !important;
  }
}

.select-box {
  display: grid;

  .v-input__slot {
    min-height: 36px !important;
  }

  .v-select__selections {
    @include body-2;
  }

  .v-select__selection {
    color: $primary;

    &--disabled {
      color: $secondaryMedium !important;
    }
  }

  .v-list-item {
    min-height: 28px !important;
  }

  .v-list-item--link {
    &:before {
      border-radius: 4px !important;
    }
  }

  .v-input__icon--append {
    .v-icon {
      color: $secondaryMedium !important;
      caret-color: $secondaryMedium !important;

      svg {
        color: unset !important;
        caret-color: unset !important;
      }

      &.v-icon.v-icon--disabled {
        color: $secondaryLight !important;
      }
    }
  }

  .v-text-field__details {
    position: absolute;
    bottom: -24px;
    left: -12px;

    .v-messages__message {
      @include body-2;
    }
  }

  &.v-select--is-menu-active,
  &:active {
    & fieldset {
      border: 1px solid $accentFixed !important;
    }
  }

  &.error--text {
    & fieldset {
      border: 1px solid $negative !important;
    }
    .v-icon {
      color: $negative !important;
      caret-color: $negative !important;
      &__component {
        height: 24px;
        width: 24px;
      }
    }
  }
}

.v-select-list {
  .v-list-item {
    height: 28px;
    .v-select__selection--comma,
    .v-list-item__title {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
    }
  }
}

.v-menu__content.menuable__content__active {
  box-shadow: 2px 4px 12px 0px #2a344014, 0 0 0 1px $elements;
  border-radius: 4px;
  background: white;
  margin-top: 4px;

  padding: {
    right: 4px;
    left: 4px;
  }
}

.select-box--disabled .v-input__slot {
  & fieldset {
    background: $elements;
  }
}

.select-box--none-label {
  & .v-select__selection {
    color: $secondaryMedium !important;
  }
}

.select-box--clear {
  .v-input__control .v-input__slot {
    background: none !important;

    fieldset {
      background: none !important;
      border: none;
    }
  }
}

.warningBox > div > div:last-child {
  margin-top: 4px;

  &:hover {
    background: $negativeClear !important;
  }

  .v-list-item__content {
    border-top: 1px solid $elements;
    color: $negative !important;
  }
}

.v-menu__content {
  //to dont override styles for v-menu--content, it is common used component.
  &.select-box-content {
    .v-subheader {
      @include body-3;
      color: $secondaryMedium;
      height: auto;
    }
    .v-list-item {
      padding: 4px 8px;
    }
  }
}

.v-menu__content .v-list-item {
  padding: 0 8px !important;
}
</style>
