<template>
  <v-expansion-panels
    v-model="openPanels"
    accordion
    :max="maxSelections"
    :multiple="shouldAllowMultipleSelections"
    :is-loading="isLoading"
    :disabled="disabled"
    :header-height="headerHeight"
  >
    <slot />
  </v-expansion-panels>
</template>

<script setup lang="ts">
import { defineProps, computed, defineEmits } from 'vue';

/**
 * Types
 */

type Panel = number;

type Panels = Array<Panel>;

type PanelOrPanels = Panel | Panels;

type Props = {
  maxSelections?: number | string;
  shouldAllowMultipleSelections?: boolean;
  value?: PanelOrPanels;
  isLoading?: boolean;
  headerHeight?: string;
  disabled?: boolean;
};

/**
 * Component
 */

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'input', value?: PanelOrPanels): void;
}>();

const openPanels = computed({
  get: () => props.value,
  set: (newPanels?: PanelOrPanels) => emit('input', newPanels),
});
</script>

<style>
.v-expansion-panels:focus-within {
  z-index: 2;
}
</style>
