<template>
  <div
    class="progress-step__container my-2 my-lg-4"
    :style="{
      'max-width': maxWidthContainer ? maxWidthContainer + 'px' : 'unset',
    }"
  >
    <div
      v-for="(stepItem, index) in steps"
      :id="stepItem.id"
      :key="stepItem.id"
      class="progress-step"
      :class="{
        'progress-step--last': index === steps.length - 1,
        'progress-step--current': value.id === stepItem.id,
        'progress-step--completed': stepItem.isCompleted,
      }"
      @click="onStepClick(stepItem, index)"
    >
      <div class="text-center progress-step__icon-container">
        <div class="progress-step__line" />
        <v-icon class="progress-step__icon">
          {{ stepItem.icon ? stepItem.icon : '$icon_check_zero' }}
        </v-icon>
      </div>
      <div class="progress-step__title">
        {{ stepItem.title }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Component } from 'vue';

type step = {
  id: string;
  title: string;
  icon: string;
  isCompleted: boolean;
  component: Component;
};

const props = defineProps<{
  steps: step[];
  value: step;
  maxWidthContainer?: number;
}>();

const emit = defineEmits<{
  (e: 'input', value: step): void;
}>();

function onStepClick(clickedStep: step, index: number) {
  const isCompletedPreviousStep = props.steps[index - 1] && props.steps[index - 1].isCompleted;
  const isFirst = index === 0;
  const isActiveStep = props.value.id === clickedStep.id;

  if ((!isCompletedPreviousStep && !isFirst) || isActiveStep) {
    return;
  }

  emit('input', clickedStep);
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/main';

.progress-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0;
  color: $secondaryMedium;
  z-index: 1;
  @include body-2;
  text-align: center;

  &__container {
    display: flex;
    justify-content: center;
    align-items: self-start;
    margin: auto;
    width: 100%;
  }

  &__icon-container {
    position: relative;
  }

  &__icon {
    border-radius: 50%;
    border: 2px solid $elements;
    position: relative;
    margin-bottom: 5px;
    background: $elements;
    color: #fff;

    .v-icon__component,
    .v-icon__svg {
      height: 24px;
      width: 24px;
    }
  }
  &__line {
    height: 3px;
    width: 100%;
    position: absolute;
    right: -50%;
    border-bottom: 3px solid;
    z-index: -1;
    top: 50%;
    margin-top: -4px;
    border-color: $elements;
  }
  &--last {
    .progress-step__line {
      display: none;
    }
  }
  &--completed {
    color: $primary;
    cursor: pointer;
    .progress-step__line {
      border-color: $accent;
    }
    .progress-step__icon {
      border-color: $accent;
      color: $primaryWhite;
      background: $accent;
    }

    &:hover {
      .progress-step__icon {
        border-color: $accentLight;
        background: $accentLight;
      }
    }
    &:active {
      color: $secondary;
      .progress-step__icon {
        border-color: $secondary;
        background: $secondary;
      }
    }
    + .progress-step {
      cursor: pointer;
    }
  }
  &#{&}--current {
    color: $accent;
    cursor: default;
    font-weight: 500;
    .progress-step__line {
      border-color: $elements;
    }
    .progress-step__icon {
      border-color: $accent;
      background: $primaryWhite;
      color: inherit;
    }
  }
  &--current#{&}--completed {
    cursor: default;
    .progress-step__line {
      border-color: $accent;
    }
    .progress-step__icon {
      border-color: $accent;
      background: $primaryWhite;
      color: inherit;
    }
  }
  &__title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    align-self: center;
    max-width: 93px;
    max-height: 60px;
    padding: 0 2px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-overflow: ellipsis;
  }
}
</style>
