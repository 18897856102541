<template>
  <div class="donut-chart__container">
    <div
      v-show="loading"
      class="donut-chart-skeleton"
    >
      <v-skeleton-loader
        type="image"
        class="donut-chart-skeleton__pie"
      />
      <div class="skeleton-legend">
        <v-skeleton-loader
          v-for="index in 4"
          :key="index"
          type="button"
          class="skeleton-legend__item"
          width="200"
          height="16"
        />
      </div>
    </div>

    <highcharts
      v-show="!loading"
      :callback="onChartReady"
      :options="donutChartOptions"
    />
  </div>
</template>

<script setup lang="ts">
import Vue from 'vue';
import { computed } from 'vue';
import HighchartsVue from 'highcharts-vue';
import Theme from '../service/themes.js';

Vue.use(HighchartsVue);

const props = defineProps<{
  ofText: string;
  loading?: boolean;
  options: object;
}>();

const emit = defineEmits<{
  (e: 'chartReady'): void;
}>();

const theme = new Theme();

const donutChartOptions = computed(() => {
  const config = {
    ...donutChartDefaultOptions,
    ...props.options,
  };

  config.chart.animation = false;
  config.plotOptions.series.animation = false;
  return config;
});

const donutChartDefaultOptions = {
  chart: {
    type: 'pie',
    height: 140,
    width: 265,
    backgroundColor: 'transparent',
    animation: false, // Disable animation at the chart level
    marginRight: 0,
    marginLeft: -110,
    marginBottom: 0,
    marginTop: 0,
    paddingTop: 0,
    spacingTop: 0,
    spacingRight: 0,
    spacingBottom: 0,
    spacingLeft: 0,
    plotBorderWidth: 0,
    margin: [0, 0, 0, 0],
  },
  yAxis: {
    minPadding: 0,
    maxPadding: 0,
  },
  legend: {
    align: 'right',
    verticalAlign: 'middle',
    layout: 'vertical',
    itemMarginTop: 2,
    itemMarginBottom: 2,
    itemStyle: {
      fontSize: '12px',
      fontWeight: '400',
    },
    itemWidth: 120, // this is the best value for item width to fit the text
    symbolHeight: 0,
    symbolWidth: 0,
    labelFormatter: function () {
      return `<span style="color:${this.color}"> ${this.y} </span> <span class="donut-chart__label">${this.name}</span>`;
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      shadow: false,
      center: ['50%', '50%'],
      dataLabels: {
        enabled: false,
      },
      borderRadius: 0,
      borderWidth: 1,
      showInLegend: true,
      size: '100%',
    },
    series: {
      animation: false, // Ensure animation is disabled for series
      size: '100%',
      innerSize: '60%',
      states: {
        hover: {
          halo: false,
        },
      },
      point: {
        events: {
          legendItemClick: function () {
            return false;
          },
        },
      },
    },
  },
  exporting: {
    enabled: false,
  },
  title: {
    text: '',
  },
  tooltip: {
    useHTML: true,
    valueDecimals: 2,
    padding: 8,
    split: true,
    shared: true,
    borderRadius: 8,
    borderWidth: 1,
    backgroundColor: theme.defaultColors.primaryWhite,
    borderColor: theme.defaultColors.elements,
    shadow: false,
    style: {
      fontSize: '12px',
      color: theme.defaultColors.secondary,
      fontfamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '12px',
    },
    formatter: function () {
      return `${this.key} ${this.y}`;
    },
  },
};

function onChartReady() {
  emit('chartReady');
}
</script>

<style lang="scss">
@import '../assets/styles/main';

.donut-chart {
  &__container {
    display: flex;
  }
  &__skeleton {
    height: 100%;
    width: 100%;
  }
  &__label {
    @include body-3;
  }
}
.inner-info {
  @include subtitle-1;
  color: $primary;
  &__title {
    font-size: 10px;
    font-weight: 700;
  }
  &__text {
    display: flex;
    font-size: 8px;
    font-weight: 400;
  }
}

.donut-chart-skeleton {
  display: flex;
  gap: 10px;
  padding: 10px;
  &__pie {
    height: 90px;
    width: 90px;
    border-radius: 92px;
  }
}

.skeleton-legend {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 6px;
  &__item {
    height: 16px;
    width: 109px;
  }
}
</style>
