<template>
  <v-navigation-drawer
    v-model="openDrawer"
    v-click-outside="{
      handler: clickOutside,
      closeConditional: onCloseConditional,
    }"
    :temporary="pushMainContent ? false : temporary"
    fixed
    flat
    class="sidebar__drawer"
    right
    :hide-overlay="true"
    :height="height"
    :width="tabSize"
    :permanent="permanent"
    :app="pushMainContent"
    :clipped="pushMainContent"
  >
    <v-card
      rounded="0"
      flat
      min-height="100%"
    >
      <v-card-title
        v-if="!(tabNames && tabNames.length > 2)"
        class="sidebar__title"
      >
        <slot name="titleRegion" />
        <div class="subtitle-1">
          <tooltipped-text :text="title" />
        </div>
        <button-common
          v-if="!hideCloseButton"
          type="clear"
          class="sidebar__button--close ml-auto"
          data-testid="sidebar-close-icon-btn"
          icon
          :icon-size="24"
          append-icon="$icon_close"
          @click.stop="emit('closeSidebar')"
        />
      </v-card-title>
      <v-card-subtitle
        v-if="subtitle"
        class="subtitle-2"
      >
        {{ subtitle }}
      </v-card-subtitle>
      <v-card-text
        v-if="tabNames"
        class="sidebar-scroller"
      >
        <v-tabs
          v-show="tabNames.length > 1"
          v-model="selectedTab"
          hide-slider
          class="sidebar__background"
        >
          <v-tab
            v-for="(tab, index) in tabNames"
            :key="index"
            class="sidebar__tab"
            :data-testid="`sidebar-tab-${index}-${tab}`"
            :ripple="false"
            active-class="sidebar__tab--active"
          >
            <span class="subtitle-2"> {{ tab }}</span>
          </v-tab>
          <span
            v-if="tabNames && tabNames.length > 2"
            class="sidebar__buttons--header"
          >
            <slot name="buttonsForBigSideBar" />
          </span>
        </v-tabs>
        <v-divider />
        <v-tabs-items v-model="selectedTab">
          <slot name="tabContent" />
        </v-tabs-items>
      </v-card-text>

      <v-card-text
        v-else
        class="sidebar-scroller"
      >
        <slot name="tabContent" />
      </v-card-text>

      <v-card-actions
        v-if="actions"
        class="sidebar__actions"
      >
        <button-common
          type="outlined"
          data-testid="sidebar-actions-cancel-btn"
          @click="$emit('closeSidebar')"
        >
          {{ cancelButton }}
        </button-common>
        <div class="pl-3 bottom--right">
          <slot name="buttonright" />
        </div>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { computed, ref, watch, inject } from 'vue';
import ButtonCommon from '../../buttons/ButtonCommon.vue';
import TooltippedText from '../../tooltip/TooltippedText/TooltippedText.vue';
import vuetify from '../../../service/useVuetify';

const props = withDefaults(
  defineProps<{
    tabNames?: Array<string>;
    title?: string;
    subtitle?: string;
    cancelButton?: string;
    drawerOpen?: boolean;
    actions?: boolean;
    temporary?: boolean;
    width?: 'small' | 'large';
    permanent?: boolean;
    activeTab?: number;
    mini?: boolean;
    pushMainContent?: boolean;
    hideCloseButton?: boolean;
    fullHeight?: boolean;
    clickOutsideIgnoreClass?: string;
  }>(),
  {
    title: '',
    twoTabs: true,
    cancelButton: 'Cancel',
    actions: true,
    temporary: true,
    permanent: false,
    width: 'large',
    mini: false,
    hideCloseButton: false,
    fullHeight: false,
  }
);

const emit = defineEmits<{
  (e: 'closeSidebar'): void;
  (e: 'clickOutside'): void;
  (e: 'changeActiveTab', value: number): void;
}>();

watch(
  () => props.activeTab,
  () => {
    selectedTab.value = props.activeTab;
  }
);

const selectedTab = computed({
  get: () => props.activeTab,
  set: (newValue) => {
    if (newValue !== props.activeTab) {
      emit('changeActiveTab', newValue);
    }
  },
});

const clickOutside = () => {
  emit('clickOutside');
};

const onCloseConditional = (e: Event) => {
  return openDrawer.value && !ignoreElement(e.target as HTMLInputElement);
};

const ignoreElement = (element: HTMLElement): boolean => {
  let currentElement: HTMLElement | null = element;

  while (currentElement) {
    const isButton = currentElement.tagName === 'BUTTON';
    const classList = [...currentElement.classList];
    const hasIgnoreClass =
      props.clickOutsideIgnoreClass && classList && classList.includes(props.clickOutsideIgnoreClass);
    if (isButton || hasIgnoreClass) {
      return true;
    }
    currentElement = currentElement.parentElement;
  }

  return false;
};

/**
 * If full screen variable provided by application sidebar will have full height
 */
const appFullScreen = inject('isAppFullScreen', ref(false));
const height = computed(() => {
  if (props.fullHeight || appFullScreen.value) return '100vh';

  switch (vuetify.value && vuetify.value.breakpoint.name) {
    case 'sm':
      return 'calc(100vh - 44px) !important';
    case 'md':
    case 'lg':
      return 'calc(100vh - 56px) !important';
    case 'xl':
      return 'calc(100vh - 64px) !important';
    default:
      return 'calc(100vh - 64px) !important';
  }
});

const tabSize = computed(() => {
  if (!vuetify.value || !props.tabNames || props.tabNames.length < 3) {
    return props.width === 'large' ? '420px' : '257px';
  } else if (!props.mini) {
    switch (vuetify.value.breakpoint.name) {
      case 'sm':
        return 'calc(100% - 48px) !important';
      case 'md':
        return 'calc(100% - 56px) !important';
      case 'lg':
        return 'calc(100% - 212px) !important';
      case 'xl':
        return 'calc(100% - 252px) !important';
      default:
        return '420px';
    }
  } else {
    return 'calc(100% - 56px) !important';
  }
});

const openDrawer = computed({
  get: () => {
    return props.drawerOpen;
  },
  set: (value) => {
    if (!value) {
      emit('closeSidebar');
    }
  },
});

defineExpose({ tabSize });
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/main';
.sidebar__drawer {
  border-top: 1px solid $elements;
  top: unset !important;
  bottom: 0;
  box-shadow: -2px 4px 12px rgba(42, 52, 64, 0.08);
  // this cannot be higher than 8 otherwise it will overlaps with selectbox dropdown z-index
  z-index: 8;

  // this fix the issue dropdown menu is not visible when it is inside sidebar
  :deep .v-window {
    overflow: visible !important;
  }

  .v-card {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__text {
      flex-grow: 1;
      flex-basis: 0;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  display: grid;

  .sidebar__title {
    color: $primary;
    height: 64px;
    border-bottom: 1px solid $elements;

    .tooltip-container {
      max-width: 340px;
    }

    div {
      font-weight: 500;
    }
  }

  .v-card__text {
    padding: 0 !important;
  }

  .bottom--right {
    width: 100%;
  }
}

.sidebar__background {
  .v-tab {
    text-transform: unset;
    color: $accent;
    letter-spacing: 0;
    padding: 0;
  }
}

.sidebar__tab {
  min-width: unset;

  &:hover::before {
    opacity: 0 !important;
  }

  span {
    padding: 4px 16px !important;

    &:hover {
      background: $accentClear;
      border-radius: 4px;
    }
  }

  &.sidebar__tab--active {
    & span {
      background: $accentClear !important;
      border-radius: 4px;
    }

    &:focus::before {
      opacity: 0;
    }
  }
}

.sidebar__button--close {
  right: 19px;
}

.sidebar__actions {
  border-top: 1px solid $elements;
  padding: 20px;
  width: 100%;
  background: white;
  z-index: 2;
}

.sidebar__divider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.sidebar__buttons--header {
  position: fixed;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style lang="scss">
.sidebar__drawer {
  .v-navigation-drawer__content {
    overflow: hidden !important;
  }

  .v-navigation-drawer__border {
    display: none !important;
  }

  .v-tabs-bar {
    height: 60px;
    padding: 0 20px;
  }

  .v-card__text {
    padding: 24px 24px 24px 20px !important;
  }
}
</style>
