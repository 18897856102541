<template>
  <v-card
    v-if="message?.length"
    class="error-popover"
  >
    <div class="input-box-container">
      <v-icon
        color="negative"
        size="20"
      >
        {{ icon }}
      </v-icon>
      <span>{{ displayMessage }}</span>
      <div class="arrow-down" />
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type Props = {
  message?: string | string[];
  icon?: string;
};

const props = withDefaults(defineProps<Props>(), {
  icon: '$icon_close',
});

const displayMessage = computed(() => {
  return Array.isArray(props.message) ? props.message[0] : props.message;
});
</script>

<style lang="scss">
@import '../../assets/styles/main';

.error-popover {
  @include body-3;
  position: relative;
  display: flex;
  align-items: left;
  max-width: 300px;
  border: 1px solid $elements;
  border-bottom: 0px;
  border-radius: 4px;
  padding: 8px 12px 8px 8px;
  background-color: $primaryWhite;

  .v-icon__component {
    fill: $negative;
  }
}

.error-popover::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 60px;
  transform: translateX(-1px);
  border-width: 6px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  z-index: 1;
}
</style>
