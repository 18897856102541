<template>
  <div
    v-click-outside="hideMultiSelectItems"
    class="multiselect-box"
  >
    <button
      class="multiselect-box__select"
      :disabled="disabled"
      :class="{
        'multiselect-box__select--disabled': disabled,
        'multiselect-box__select--active': selectActive && !errorMessage,
        'multiselect-box__select--error': errorMessage,
        'multiselect-box__select--none-label': selectedItemsLabel === labelStrings.noneLabel,
        'multiselect-box__select--clear': clear,
      }"
      :style="{
        'max-width': maxWidth ? `${maxWidth}px` : 'auto',
      }"
      type="button"
      @click="selectActive = !selectActive"
    >
      <tooltipped-text :text="selectedItemsLabel" />
      <v-chip
        v-if="showCountChip && selectedItems.length > 1 && !isSelectedAll"
        small
      >
        {{ selectedItems.length }}
      </v-chip>
      <v-icon
        v-show="!errorMessage"
        :size="24"
        class="ml-auto"
        :color="disabled ? 'secondaryLight' : 'secondaryMedium'"
      >
        $icon_arrow_down
      </v-icon>
      <v-icon
        v-show="errorMessage"
        :size="24"
        color="negative"
        class="ml-auto"
      >
        $icon_attention
      </v-icon>
      <v-progress-linear
        v-if="loading"
        absolute
        bottom
        indeterminate
        rounded
        height="2"
        :color="loaderColor"
      />
    </button>
    <div
      v-show="selectActive"
      class="multiselect-box__content"
    >
      <div
        v-if="originalItems.length || showSearchBoxOnNoItems"
        class="mb-2"
      >
        <SearchBar
          v-model="currentSearch"
          :collections="[]"
          :show-collection="false"
          :placeholder="labelStrings.searchPlaceholder ? labelStrings.searchPlaceholder : ''"
        />
      </div>
      <div class="multiselect-box__items">
        <div
          v-if="originalItems.length"
          class="multiselect-box__item--normal"
        >
          <CheckBox
            v-model="isSelectedAll"
            :label="labelStrings.allLabel"
            :indeterminate="isIndeterminate"
            class="multiselect-box__item__checkbox"
          />
        </div>
        <div>
          <div
            v-for="item in selectedItems"
            v-show="selectedItems"
            :key="`${item.text}${item.value}`"
            class="multiselect-box__item--normal"
          >
            <CheckBox
              :label="item.text"
              :value="true"
              class="multiselect-box__item__checkbox"
              :label-max-width="labelMaxWidth"
              @change="clickItem(item)"
            />
          </div>
          <v-divider
            v-show="isIndeterminate"
            class="my-1 mr-1"
          />
          <div v-if="searchResultItems">
            <div
              v-for="item in searchResultItems"
              :key="`${item.text}${item.value}`"
              class="multiselect-box__item--searched"
            >
              <CheckBox
                :label="item.text"
                :value="false"
                class="multiselect-box__item__checkbox"
                :label-max-width="labelMaxWidth"
                @change="clickItem(item)"
              />
            </div>
          </div>
        </div>
        <div
          v-if="currentSearch && searchResultItems.length === 0"
          class="multiselect-box__no-results"
        >
          <p>{{ labelStrings.noResultsLabel }}</p>
        </div>
        <div
          v-if="!currentSearch && originalItems.length === 0"
          class="multiselect-box__no-results"
        >
          <p>{{ labelStrings.noItemsLabel }}</p>
        </div>
        <div v-if="selectedItems.length !== originalItems.length">
          <div
            v-for="collection of availableCollections"
            :key="collection.name"
          >
            <p
              v-if="collection.items.length > 0 && collections.length > 1"
              class="multiselect-box__category"
            >
              {{ collection.name }}
            </p>
            <div
              v-for="item in collection.items"
              :key="`${item.text}${item.value}`"
              class="multiselect-box__item--normal"
              @click="clickItem(item)"
            >
              <CheckBox
                :label="item.text"
                :value="selectedItems.some((selectedItem) => selectedItem.value === item.value)"
                class="multiselect-box__item__checkbox"
                :label-max-width="labelMaxWidth"
                @change="clickItem(item)"
              />
            </div>
          </div>
        </div>
        <div
          v-if="paginated"
          data-testid="eewc-multi-select-search-pagination"
          :class="['pagination-wrapper', { 'pagination-wrapper--no-height': paginationRef.completed }]"
        >
          <div
            v-intersect="handleIntersection"
            data-testid="eewc-multi-select-search-intersection"
          />
          <loading-spinner
            v-if="!paginationRef.completed || paginationRef.loaded"
            is-loading
            :absolute="false"
            :class="['infinite-loader', { 'infinite-loader--margin': !selectedItems.length }]"
          />
        </div>
      </div>
    </div>
    <div
      v-show="errorMessage"
      class="multiselect-box__error-message"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import { IntersectionPaginationOption, IntersectionStateChanger } from '@/utils/types';
import LoadingSpinner from '../../../components/loaders/LoadingSpinner.vue';
import TooltippedText from '../../tooltip/TooltippedText/TooltippedText.vue';
import SearchBar from '../../text-inputs/searchBar/SearchBar.vue';
import CheckBox from '../../selection-controls/CheckBox.vue';

import { Collection, SelectBoxItem, LabelStrings } from '../types';
import { clickItemHandler, selectedItemsLabelHandler } from '../multiSelectUtils';

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    value?: SelectBoxItem[];
    maxWidth?: number;
    collections: Collection[];
    labelStrings: LabelStrings;
    errorMessage?: string;
    labelMaxWidth?: string | number;
    loading?: boolean;
    paginated?: boolean;
    clear?: boolean;
    showCountChip?: boolean;
    showSearchBoxOnNoItems?: boolean;
  }>(),
  {
    value: () => [],
    maxWidth: undefined,
    labelMaxWidth: '100%',
    errorMessage: '',
    loading: false,
    paginated: false,
    clear: false,
    showCountChip: false,
    showSearchBoxOnNoItems: false,
  }
);

const emit = defineEmits<{
  (e: 'input', value?: SelectBoxItem[]): void;
  (e: 'click-outside'): void;
  (
    e: 'search',
    value: {
      searchText: string | undefined | null;
      state: IntersectionStateChanger;
    }
  ): void;
  (e: 'infinite', value: IntersectionStateChanger): void;
}>();

const paginationRef = ref<IntersectionPaginationOption>({
  loaded: false,
  completed: false,
  bounceTimer: undefined,
});

const loaderColor = computed(() => (props.errorMessage ? 'negative' : 'secondaryMedium'));

// CONVERTED PROPS
const originalItems = computed(() => props.collections.flatMap((collection: Collection) => collection.items));
const inRange = (num: number, a: number, b = 0) => Math.min(a, b) <= num && num < Math.max(a, b);
const isIndeterminate = computed(
  () => !!selectedItems?.value.length && inRange(selectedItems?.value?.length, 1, originalItems.value.length)
);

// SELECTED ITEMS
const selectedItems = computed({
  get: () => (props.value ? props.value : []),
  set: (newValue) => {
    emit('input', newValue);
  },
});

// AVAILABLE ITEMS
const availableCollections = computed(() => {
  return props.value || currentSearch.value
    ? props.collections.map((collection: Collection) => {
        const items = [...selectedItems.value, ...searchResultItems.value];

        return {
          name: collection.name,
          items: collection.items.filter((item) => !items.some((value) => value.value === item.value)),
        };
      })
    : props.collections;
});

const availableItems = computed(() => {
  const availableItemsList = props.collections.flatMap((collection) => collection.items);

  const remainingAvailableItems = availableItemsList.filter(
    (item) => !selectedItems.value.find((selectedItem) => selectedItem.value === item.value)
  );

  return remainingAvailableItems;
});

// SEARCH
const currentSearchLower = computed(() => currentSearch.value.toLowerCase());

const searchResultItems = computed(() => {
  return availableItems.value.filter((item) => {
    const itemName = item.text.toLowerCase();

    return currentSearchLower.value ? itemName.indexOf(currentSearchLower.value) > -1 : false;
  });
});

const isSelectedAll = computed({
  get: () => originalItems.value.length && props?.value?.length === originalItems.value.length,
  set: (newValue) => {
    selectedItems.value = newValue ? originalItems.value : [];
  },
});

// SELECT ALL
const selectActive = ref<boolean>(false);

const currentSearch = ref<string>('');
// LABEL
const selectedItemsLabel = computed(() =>
  selectedItemsLabelHandler(isSelectedAll.value, selectedItems.value, props.labelStrings, props.showCountChip)
);

// EVENT HANDLERS
const hideMultiSelectItems = () => {
  selectActive.value = false;
  emit('click-outside');
};

const handleIntersection = (
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver,
  isIntersecting: boolean
) => {
  if (isIntersecting) {
    if (paginationRef.value.completed) return;
    paginationRef.value.loaded = false;
    emit('infinite', state);
  }
};

// Function responsible for removing/adding items allFilteredItems
const clickItem = (item: SelectBoxItem) => {
  selectedItems.value = clickItemHandler(item, selectedItems.value);
};

watch(selectActive, (newValue, oldValue) => {
  if (!newValue && newValue !== oldValue) {
    currentSearch.value = '';
  }
});

watch(
  () => currentSearchLower.value,
  (newVal) => {
    // debounce for the search
    clearTimeout(paginationRef.value.bounceTimer);
    paginationRef.value.bounceTimer = setTimeout(() => {
      emit('search', { searchText: newVal, state });
    }, 500);
  }
);

const state: IntersectionStateChanger = {
  loaded: () => {
    paginationRef.value.loaded = true;
  },
  completed: () => {
    paginationRef.value.completed = true;
  },
  reset: () => {
    paginationRef.value.loaded = false;
    paginationRef.value.completed = false;
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/main';
@import '../multiSelectStyles';
.multiselect-box {
  &__select {
    position: relative;

    &--clear {
      border: none;
      background: none;
    }
  }

  &__item {
    height: 28px;
    &__checkbox {
      padding-top: 2px !important;
    }
    &--normal {
      &:hover {
        background: $accentClear;
        border-radius: 4px;
        margin-right: 4px;
      }
    }
    &--searched {
      background: $accentClear;
      border-radius: 4px;
      margin-right: 4px;
    }
  }
  &__searched-items {
    background: $accentClear;
    box-shadow: none;
    border-radius: 4px;
  }

  &__category {
    @include body-3;
    color: $secondaryMedium;
    margin: 4px 0 !important;
  }

  &__no-results {
    display: flex;
    align-items: center;
    height: 44px;
    p {
      @include body-2;
      margin: 0 !important;
    }
  }
}

.pagination-wrapper {
  height: 60px;
  display: grid;
  place-items: center;

  &--no-height {
    height: auto;
  }

  .infinite-loader {
    text-align: center;

    &--margin {
      margin-top: -30px;
    }
  }
}

.v-chip {
  color: $primaryWhite !important;
  background-color: $primaryLight !important;
  @include subtitle-3;
  margin-left: 8px;
  max-width: 37px;
  min-width: 37px;
  display: flex;
  justify-content: center;
}
</style>
