<template>
  <v-app-bar
    app
    color="white"
    tile
    :height="height"
    class="eewc-top-bar"
    clipped-right
  >
    <div
      v-if="topBarBreadcrumbs !== undefined"
      class="eewc-top-bar__title-container d-flex align-center"
    >
      <tooltip :text="topBarBreadcrumbs.from">
        <template #content="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            @click="navBlocked && emit('blocked-nav-clicked')"
          >
            <button-common
              v-show="showArrowIcon"
              :disabled="navBlocked"
              append-icon="$icon_arrow_left"
              icon-color="primary"
              type="clear"
              icon
              @click="backClick"
            />
          </div>
        </template>
      </tooltip>
      <span>
        <tooltip :text="topBarBreadcrumbs.iconTooltip">
          <template #content="{ on, attrs }">
            <v-icon
              size="24"
              class="mr-1"
              v-bind="attrs"
              v-on="on"
            >
              {{ topBarBreadcrumbs.icon }}
            </v-icon>
          </template>
        </tooltip>
      </span>
      <span class="back__title">
        {{ topBarBreadcrumbs.title }}
      </span>
    </div>
    <template v-else>
      <slot name="searchBar" />
    </template>
    <v-spacer />
    <slot />
    <tooltip
      v-if="clockData"
      :text="clockData.timeZone"
    >
      <template #content="{ on, attrs }">
        <div
          v-bind="attrs"
          :class="{
            'compressed-text': compressedFont,
            'clock-data': !compressedFont,
          }"
          data-testid="top-bar-clock-data"
          v-on="on"
        >
          {{ clockData.value }}
        </div>
      </template>
    </tooltip>
    <tooltip :text="fullscreenTooltip">
      <template #content="{ on, attrs }">
        <div
          v-bind="attrs"
          class="d-flex align-center justify-center"
          v-on="on"
        >
          <button-common
            data-testid="top-bar-btn-full-screen"
            append-icon="$icon_full_size"
            icon-color="primary"
            type="clear"
            icon
            :icon-size="32"
            class="mx-5"
            @click="$emit('emit-fullscreen')"
          />
        </div>
      </template>
    </tooltip>
    <slot name="appSwitcher" />
    <!-- This should appear at the end of the top bar -->
    <slot name="profileMenu" />
  </v-app-bar>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import Tooltip from '../tooltip/Tooltip.vue';
import vuetify from '../../service/useVuetify';
import ButtonCommon from '../buttons/ButtonCommon.vue';

type ClockInformation = {
  timeZone: string;
  value: string;
};

const props = withDefaults(
  defineProps<{
    headerTitle?: string;
    fullscreenTooltip?: string;
    clockData?: ClockInformation;
    returnArrow?: boolean;
    newNotification?: boolean;
    showArrowIcon?: boolean;
    navBlocked?: boolean;
    topBarBreadcrumbs?: {
      backEvent: (event: MouseEvent) => void;
      from: string;
      title: string;
      icon?: string;
      iconTooltip?: string;
    };
  }>(),
  {
    fullscreenTooltip: '',
    clockValue: '',
    showArrowIcon: true,
  }
);

const emit = defineEmits<{
  (e: 'blocked-nav-clicked'): void;
}>();

const height = computed(() => {
  switch (vuetify.value.breakpoint.name) {
    case 'sm':
      return 44;
    case 'md':
    case 'lg':
      return 56;
    case 'xl':
      return 64;
    default:
      return 64;
  }
});

const compressedFont = computed(() => {
  return vuetify.value.breakpoint.name === 'sm' || vuetify.value.breakpoint.name === 'md';
});

function backClick(event: MouseEvent) {
  if (props.topBarBreadcrumbs) {
    props.topBarBreadcrumbs.backEvent(event);
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/main';

.eewc-top-bar {
  box-shadow: 0px 2px 12px rgba(42, 52, 64, 0.08) !important;
  color: $primary !important;

  &__title-container {
    min-width: 0; // Allow flex items to shrink below content width
  }

  .back {
    &__from,
    &__title {
      @include headline-6;
      margin-left: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.v-app-bar.v-app-bar--fixed.v-sheet {
    z-index: 9;
  }

  .compressed-text {
    @include subtitle-2;
  }

  .clock-data {
    @include subtitle-1;
  }
}
</style>
