<template>
  <div :class="showErrorOnPopover ? 'text-field-relative' : ''">
    <v-text-field
      ref="inputElementRef"
      v-model="selectedItem"
      :type="type"
      dense
      height="36px"
      class="text-field"
      :rules="[...rules]"
      :disabled="disabled"
      :class="[
        { 'eewc-text-field__disabled': disabled },
        { 'eewc-text-field--error': isError },
        { 'eewc-text-field-error-box': validationError },
      ]"
      single-line
      :append-icon="showError ? '$icon_attention' : rightIcon"
      :prepend-inner-icon="leftIcon"
      :error-messages="errorMessages"
      :label="label"
      outlined
      :clearable="clearable"
      clear-icon="$icon_close"
      :readonly="readonly"
      v-bind="$attrs"
      :hide-details="isDetailsHidden"
      :maxlength="maxlength"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @click:append="$emit('clickAppend')"
      @click:clear="$emit('clickClear')"
      @keydown="onKeydown"
      @mousewheel="onMouseEvents"
      @mouseup="onMouseEvents"
      @paste="onPaste"
    >
      <template #append>
        <slot name="append" />
      </template>
    </v-text-field>

    <div
      v-if="showErrorOnPopover && errorMessages?.length > 0"
      class="popover-on-field"
    >
      <PopOver :message="errorMessages" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import PopOver from './Popover.vue';

type ValueType = string | number;

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    value?: ValueType;
    label?: string;
    rules?: Array<string | boolean | ((value: any) => boolean | string)>;
    leftIcon?: string;
    clearable?: boolean;
    rightIcon?: string;
    readonly?: boolean;
    type?: string;
    errorMessages?: string | string[];
    hideDetails?: boolean;
    maxlength?: number;
    validationError?: boolean;
    isConfirm?: boolean;
    showErrorIcon?: boolean;
    showErrorOnPopover?: boolean;
  }>(),
  {
    label: 'Name',
    type: 'text',
    hideDetails: false,
    rules: () => [],
    hasError: false,
    isConfirm: false,
    showErrorIcon: true,
    value: undefined,
    leftIcon: undefined,
    rightIcon: undefined,
    maxlength: undefined,
    errorMessages: undefined,
  }
);

const inputElementRef = ref();

const isError = computed(() => inputElementRef?.value?.shouldValidate && !!inputElementRef?.value?.hasError);

const emit = defineEmits<{
  (e: 'input', value?: ValueType): void;
  (e: 'input-ref', value: HTMLElement | null): void;
  (e: 'blur'): void;
  (e: 'keydown', event: KeyboardEvent): void;
  (e: 'paste', event: ClipboardEvent): void;
}>();

const selectedItem = computed({
  get: () => props.value,
  set: (newValue) => {
    emit('input', newValue);
  },
});

const showError = computed(() => (isError.value || props.errorMessages?.length) && props.showErrorIcon);

const isDetailsHidden = computed(() => {
  return props.hideDetails || props.showErrorOnPopover;
});

const onKeydown = (event: KeyboardEvent) => {
  emit('keydown', event);
};

const onMouseEvents = (event: MouseEvent) => {
  (event?.target as HTMLInputElement).blur();
};

const onPaste = (event: ClipboardEvent) => {
  emit('paste', event);
};

onMounted(() => {
  if (inputElementRef.value) {
    emit('input-ref', inputElementRef.value);
  }
});
</script>

<style lang="scss">
@import '../../assets/styles/main';

.text-field .v-input__append-inner {
  margin-top: unset !important;
  align-self: center;
}

.text-field {
  label {
    animation: none !important;
  }

  position: relative;

  .v-text-field__details {
    position: absolute;
    bottom: -28px;
    left: -12px;

    .v-messages__message {
      @include body-2;
    }
  }

  .v-input__prepend-inner {
    z-index: 1;
    margin-top: 6px !important;
    color: $elements !important;
  }

  //default icon size and color
  .v-icon {
    .v-icon__component,
    .v-icon__svg {
      height: 24px;
      width: 24px;
      color: $secondaryMedium;
    }
  }

  //icon size and color for clear icon
  .v-input__icon--clear {
    .v-icon__component,
    .v-icon__svg {
      height: 24px;
      width: 24px;
      color: $secondaryMedium !important;
    }
  }

  &.error--text {
    fieldset {
      border: 1px solid $negative !important;
    }
  }

  .v-input__slot {
    min-height: 36px !important;
    margin-bottom: 0 !important;
    padding: 0 8px 0 12px !important;

    & fieldset {
      color: $elements !important;
      border: 1px solid;
      background: $backgrounds;
    }

    input {
      @include body-2;
      font-size: 14px !important;

      /* Remove Arrows/Spinners */
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & label {
      top: unset !important;
      color: $secondaryMedium;
      @include body-2;
    }

    &:hover {
      box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
      border-radius: 4px;

      & fieldset {
        color: $elements !important;
        border: 1px solid;
      }
    }

    &:focus-within {
      & fieldset {
        color: $accentFixed !important;
      }
    }

    z-index: 2;
  }
}

.eewc-text-field__disabled .v-input__slot {
  & fieldset {
    background: $elements;
  }
}

.eewc-text-field--error .v-input__slot {
  & fieldset {
    color: $negative;
  }
  .v-icon {
    .v-icon__component {
      color: $negative !important;
    }
  }
}

.eewc-text-field-error-box .v-input__slot {
  & fieldSet {
    border: 1px solid $negative;
  }

  &:hover {
    & fieldSet {
      border: 1px solid $negative;
    }
  }

  .v-icon {
    .v-icon__component {
      color: $negative !important;
    }
  }
}

.text-field-relative {
  position: relative;

  .popover-on-field {
    position: absolute;
    top: -45px;
    left: -55px;
    display: flex;
    width: max-content;
    max-width: 300px;
    z-index: 2;
  }
}
</style>
